
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetomahabaleshwertempotraveller() {



  const cardData =
  {
    keyword: 'Pune to Mahabaleshwar Tempo Traveller',
    heading: 'Pune to Mahabaleshwar Tempo Traveller: Shraddha Travels',
    headingDescription: 'Looking for a comfortable and hassle-free journey from Pune to Mahabaleshwar? Shraddha Travels offers premium Tempo Traveller services that ensure a smooth and enjoyable ride for groups, families, or corporate outings. Our well-maintained vehicles come equipped with modern amenities, offering spacious seating, air conditioning, and ample storage space for luggage.',
    topPlaces: [
        {
          title: "1. Venna Lake",
          description: "One of Mahabaleshwar's most popular attractions, Venna Lake offers serene boat rides amidst picturesque surroundings. Enjoy horse riding along the lake or take a stroll through the nearby market."
        },
        {
          title: "2. Arthur’s Seat",
          description: "Known as the 'Queen of all Points,' Arthur’s Seat provides breathtaking views of deep valleys and the Savitri River, making it perfect for photography and enjoying stunning panoramas."
        },
        {
          title: "3. Pratapgad Fort",
          description: "A historical gem built by Chhatrapati Shivaji Maharaj, Pratapgad Fort is located about 24 km from Mahabaleshwar and offers awe-inspiring views of the Sahyadri range."
        },
        {
          title: "4. Mapro Garden",
          description: "Famous for its strawberry farms and products, Mapro Garden is a must-visit for food lovers, featuring fresh strawberry shakes, ice creams, and delicious jams, along with the annual Strawberry Festival."
        },
        {
          title: "5. Elephant’s Head Point",
          description: "This viewpoint is known for a unique rock formation resembling an elephant’s head, offering panoramic views and breathtaking sunsets."
        },
        {
          title: "6. Wilson Point",
          description: "Also known as Sunrise Point, Wilson Point is the highest point in Mahabaleshwar, perfect for witnessing a beautiful sunrise over the hills."
        },
        {
          title: "7. Lingmala Waterfall",
          description: "Lingmala Waterfall is especially stunning during the monsoon season, with a trek to the viewpoint offering an up-close view of the cascading water from the cliff."
        },
        {
          title: "8. Mahabaleshwar Temple",
          description: "Dedicated to Lord Shiva, the Mahabaleshwar Temple features ancient architecture and spiritual significance, attracting many pilgrims and tourists."
        },
        {
          title: "9. Panchgani",
          description: "Located near Mahabaleshwar, Panchgani is another hill station known for its beauty, strawberry farms, and scenic viewpoints like Sydney Point and Parsi Point."
        },
        {
          title: "10. Table Land",
          description: "The second-largest mountain plateau in Asia, Table Land offers panoramic views of surrounding hills and valleys, with flat terrain perfect for activities like horse riding and paragliding."
        }
      ],  
      "services": [
    {
      "name": "Tempo Traveller from Pune to Mahabaleshwar",
      "description": "Enjoy a comfortable ride with ample space and modern amenities in our Tempo Travellers, perfect for a scenic trip to Mahabaleshwar. Our vehicles are well-equipped to ensure a smooth journey, allowing you to relax and take in the beautiful landscapes."
    },
    {
      "name": "Pune to Mahabaleshwar Tempo Traveller Hire",
      "description": "Experience easy and flexible rental options tailored to your needs with Shraddha Travels. Whether it's a family trip or a group outing, we have the perfect Tempo Traveller to fit your travel requirements."
    },
    {
      "name": "Pune to Panchgani Tempo Traveller",
      "description": "Explore nearby destinations like Panchgani with the same comfort and reliability. Our Tempo Travellers provide spacious seating and a smooth ride, making your journey enjoyable."
    },
    {
      "name": "Tempo Traveller for Rent in Pune",
      "description": "Choose from a variety of vehicles for any type of group travel. Our fleet includes different seating capacities and features, ensuring you find the right option for your trip."
    },
    {
      "name": "17-Seater Tempo Traveller for Mahabaleshwar Trip",
      "description": "Ideal for medium to large groups, our 17-seater Tempo Traveller comes with spacious seating and air conditioning, ensuring comfort throughout your journey to Mahabaleshwar."
    },
    {
      "name": "17-Seater Tempo Traveller for Mahabaleshwar Tour Package",
      "description": "Enjoy a well-planned trip with our comfortable and feature-rich Tempo Travellers. This package is designed to provide a seamless travel experience while exploring Mahabaleshwar."
    },
    {
      "name": "Pune Airport to Mahabaleshwar Tempo Traveller on Rent",
      "description": "Start your trip with ease directly from Pune Airport. Our rental service ensures a smooth transfer to Mahabaleshwar, allowing you to begin your adventure without hassle."
    },
    {
      "name": "Pune to Tapola Tempo Traveller on Rent",
      "description": "Discover beautiful Tapola with our reliable rental service. Our Tempo Travellers provide a comfortable ride, perfect for exploring this scenic destination."
    },
    {
      "name": "14-Seater Tempo Traveller for Maharashtra Trip",
      "description": "Perfect for smaller groups exploring various parts of Maharashtra, our 14-seater Tempo Traveller ensures a comfortable and enjoyable travel experience."
    },
    {
      "name": "Tempo Traveller on Rent Mahabaleshwar Outings",
      "description": "Rent a vehicle for local sightseeing and outings in Mahabaleshwar. Our drivers are familiar with the area, ensuring you visit all the must-see spots."
    },
    {
      "name": "Mini Bus on Rent for Mahabaleshwar Trip",
      "description": "Ideal for larger groups needing extra space, our mini buses are designed for comfort and convenience, making group travel enjoyable."
    },
    {
      "name": "Tempo Traveller on Rent in Pune Rate Per Km",
      "description": "Enjoy transparent pricing based on the distance traveled. Our rates are competitive and tailored to fit your budget."
    },
    {
      "name": "Pune to Mahabaleshwar Tempo Traveller Price",
      "description": "Get competitive rates for your journey from Pune to Mahabaleshwar. We offer value for money without compromising on comfort."
    },
    {
      "name": "Pune to Mahabaleshwar Tempo Traveller Contact Number",
      "description": "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Tempo Traveller services. Our team ensures a smooth and enjoyable ride for all our customers."
    }
  ],  
      tableData: [
        ['- Tempo Traveller from Pune to Mahabaleshwar', '- 17-Seater Tempo Traveller for Mahabaleshwar Trip'],
        ['- Pune to Mahabaleshwar Tempo Traveller Hire', '- 17-Seater Tempo Traveller for Mahabaleshwar Tour Package'],
        ['- Pune to Panchgani Tempo Traveller', '- Tempo Traveller for Rent in Pune'],
        ['- Pune Airport to Mahabaleshwar Tempo Traveller on Rent', '- Pune to Tapola Tempo Traveller on Rent'],
        ['- 14-Seater Tempo Traveller for Maharashtra Trip', '- Tempo Traveller on Rent Mahabaleshwar Outings'],
        ['- Mini Bus on Rent for Mahabaleshwar Trip', '- Tempo Traveller on Rent in Pune Rate Per Km'],
        ['- Pune to Mahabaleshwar Tempo Traveller Price', '']
      ],  
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels for Your Pune to Mahabaleshwar Journey?",
          WhyChoosedescription: "When it comes to traveling from Pune to Mahabaleshwar, Shraddha Travels is your trusted partner for a comfortable and reliable journey. Whether you're planning a family trip, a romantic getaway, or a corporate outing, our service ensures that you travel in style and comfort. Here's why Shraddha Travels is the best choice for your trip:"
        },
        {
          WhyChooseheading: "Comfortable Travel Experience:",
          WhyChoosedescription: "Our tempo travelers are spacious and equipped with modern amenities, such as reclining seats, air conditioning, and entertainment systems, ensuring a smooth and enjoyable ride for you and your group. You can relax and enjoy the scenic views without any discomfort."
        },
        {
          WhyChooseheading: "Experienced Drivers:",
          WhyChoosedescription: "Our professional drivers are well-trained and familiar with the Pune to Mahabaleshwar route. They prioritize your safety and comfort, navigating through the best paths while ensuring timely arrival at your destination."
        },
        {
          WhyChooseheading: "Flexible Itineraries:",
          WhyChoosedescription: "We offer customizable packages tailored to your travel needs. Whether it's a day trip filled with sightseeing or a longer getaway to explore the beautiful landscapes, we provide the flexibility to plan your journey at your own pace."
        },
        {
          WhyChooseheading: "Affordable Pricing:",
          WhyChoosedescription: "At Shraddha Travels, we provide competitive rates without compromising on quality. Our transparent pricing ensures that you get the best value for your money, making your travel experience budget-friendly."
        },
        {
          WhyChooseheading: "Customer Satisfaction:",
          WhyChoosedescription: "With numerous satisfied customers, we pride ourselves on our commitment to excellent service. Our aim is to create memorable travel experiences that exceed your expectations, ensuring you leave with lasting memories."
        },
        {
          WhyChooseheading: "24/7 Support:",
          WhyChoosedescription: "Our dedicated support team is available around the clock to assist you with any queries or concerns. Whether you need help with bookings or travel advice, we're here to ensure peace of mind throughout your journey."
        },
        {
          WhyChooseheading: "Safe and Reliable:",
          WhyChoosedescription: "Safety is our top priority. Our vehicles undergo regular maintenance checks, and we adhere to all safety protocols. You can trust that you're in good hands with our reliable transportation."
        },
        {
          WhyChooseheading: "Hygiene and Cleanliness:",
          WhyChoosedescription: "We prioritize hygiene and safety, ensuring our vehicles are regularly cleaned and sanitized. Your well-being is important to us, so you can travel with confidence."
        },
        {
          WhyChooseheading: "Easy Booking Process:",
          WhyChoosedescription: "Our user-friendly booking process allows you to reserve your Tempo Traveller easily online or through our support team. We ensure a hassle-free experience from start to finish."
        },
        {
          WhyChooseheading: "Local Expertise:",
          WhyChoosedescription: "Our drivers possess local knowledge about Mahabaleshwar, offering you valuable insights and recommendations on must-visit spots, dining options, and hidden gems along the way."
        }
      ]
  
  }

  const faqData = [
    {
      question: "How can I book a Tempo Traveller from Pune to Mahabaleshwar?",
      answer: "You can book a Tempo Traveller by calling us at +91 8087847191 or through our website. Our team will assist you with the booking process."
    },
    {
      question: "What types of Tempo Travellers are available for the Pune to Mahabaleshwar journey?",
      answer: "We offer a range of Tempo Travellers, including 17-seaters and mini buses, to accommodate different group sizes and preferences."
    },
    {
      question: "Are there any additional charges for the Tempo Traveller service?",
      answer: "Our pricing is transparent, with no hidden fees. Any additional charges for extra stops or extended mileage will be communicated in advance."
    },
    {
      question: "How do I know if the Tempo Traveller is well-maintained?",
      answer: "All our vehicles undergo regular inspections and maintenance to ensure they are in excellent condition for your safety and comfort."
    },
    {
      question: "Can I choose the driver for my trip?",
      answer: "While we assign experienced drivers to ensure a high-quality experience, you can let us know of any specific preferences when booking."
    },
    {
      question: "What if I need to change my booking?",
      answer: "Contact our customer support team to discuss any changes. We will accommodate your requests based on availability."
    },
    {
      question: "Is there a minimum rental period for the Tempo Traveller service?",
      answer: "We offer flexible rental periods to fit your needs, whether for a day trip or a longer journey."
    },
    {
      question: "What amenities are included in the Tempo Traveller service?",
      answer: "Our vehicles come with modern amenities such as air conditioning, comfortable seating, and entertainment options."
    },
    {
      question: "What is the cancellation policy for the Tempo Traveller service?",
      answer: "Cancellation policies may vary. Please contact us for details on our cancellation terms and any applicable fees."
    },
    {
      question: "How can I contact Shraddha Travels for support during my journey?",
      answer: "Reach our 24/7 customer support team at +91 8087847191 for any assistance during your trip."
    },
    {
      question: "What safety measures do you take for Tempo Traveller trips?",
      answer: "Safety is our top priority. All our vehicles are regularly serviced, sanitized, and equipped with first aid kits. Our drivers are experienced and follow all road safety guidelines."
    },
    {
      question: "Do you offer Tempo Traveller services for corporate events or weddings?",
      answer: "Yes, Shraddha Travels provides Tempo Travellers for a variety of events, including corporate trips, weddings, family outings, and more."
    },
    {
      question: "How far in advance should I book a Tempo Traveller for my trip?",
      answer: "We recommend booking at least 1-2 weeks in advance to ensure availability, especially during peak travel seasons or for large group bookings."
    }
];

const testimonials = [
    {
      name: "Miss Suriya Sharma",
      text: "I recently rented a Tempo Traveller from Shraddha Travels for our trip to Mahabaleshwar, and it was a fantastic experience. The vehicle was clean, spacious, and well-maintained. The driver was professional and made our journey enjoyable. Highly recommend Shraddha Travels for group trips!"
    },
    {
      name: "Mr. Anil Patel",
      text: "Our corporate team used Shraddha Travels for a Mahabaleshwar outing, and we were thoroughly impressed. The Tempo Traveller was luxurious and comfortable, perfect for our needs. The booking process was smooth, and the driver was excellent. We’ll definitely choose Shraddha Travels again for future trips."
    }
];



  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Pune to Mahabaleshwar Tempo Traveller Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune to Mahabaleshwar Tempo Traveller | Affordable 17 Seater Rentals</title>
    <meta name="description" content="Rent a tempo traveller from Pune to Mahabaleshwar, including 17-seater options for comfortable travel. Enjoy competitive rates and exceptional service for your trip!" />
    <meta name="keywords" content="Tempo traveller from Pune to Mahabaleshwar, Pune to Mahabaleshwar tempo traveller hire, Pune to Panchgani tempo traveller, Tempo traveller for rent in Pune, Tempo traveller 17 Seater for Mahabaleshwar trip, 17 seater tempo traveller for Mahabaleshwar tour package, Pune Airport to Mahabaleshwar tempo traveller on rent, Pune to Tapola tempo traveller on rent, 14 seater tempo traveller for Maharashtra trip, Tempo traveller on rent Mahabaleshwar outings, Mini bus on rent for Mahabaleshwar trip, Tempo Traveller on rent in Pune rate per km, Pune to Mahabaleshwar tempo traveller price" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to Mahabaleshwar Tempo Traveller | Affordable 17 Seater Rentals" />
    <meta property="og:description" content="Explore the best tempo traveller rental services from Pune to Mahabaleshwar. Enjoy a comfortable journey with our 17-seater options at competitive rates!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-to-mahabaleshwar-tempo-traveller" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/mahabaleshwar-tempo-traveller.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pune to Mahabaleshwar Tempo Traveller Rental",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-to-mahabaleshwar-tempo-traveller",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller",
                    "description": "Comfortable Tempo Traveller on Rent from Pune to Mahabaleshwar for group travel."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>


      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-02.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs About Pune to Mahabaleshwar Tempo Traveller for Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetomahabaleshwertempotraveller;