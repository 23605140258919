
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetooutstationtempo() {



  const cardData =
  {
    keyword: 'Pune to Outstation Tempo Traveller',
    heading: 'Shraddha Travels: Pune to Outstation Tempo Traveller',
    headingDescription: 'When it comes to exploring destinations beyond Pune, Shraddha Travels offers exceptional outstation Tempo Traveller services designed for comfort, reliability, and affordability. Whether you are heading to a serene hill station, a vibrant city, or a sacred pilgrimage site, our Pune Outstation Tempo Traveller ensure a seamless travel experience. Here’s everything you need to know about our services and some top outstation destinations from Pune.',
    top: 'Top Outstation Destinations from Pune with Shraddha Travels: -',

     topPlaces : [
        {
            title: "1. Mumbai",
            description: "The bustling metropolis of Mumbai, just a few hours from Pune, is a vibrant hub of commerce, culture, and entertainment. Explore iconic landmarks like the Gateway of India, a symbol of the city's rich history, and take a leisurely stroll along Marine Drive, known for its stunning views of the Arabian Sea. Dive into the bustling markets of Colaba and Bandra, where you can shop for everything from handicrafts to high-end fashion. Don’t miss the chance to savor Mumbai's street food, including the famous vada pav and pav bhaji."
        },
        {
            title: "2. Lonavala and Khandala",
            description: "Perfect for a weekend getaway, the hill stations of Lonavala and Khandala are known for their breathtaking landscapes and pleasant weather. Experience the thrill of trekking at Bhushi Dam, visit the ancient Karla Caves with their stunning rock-cut architecture, and enjoy panoramic views from Tiger’s Leap. These hill stations are also famous for their chikki (a local sweet), making them a delicious stop for visitors."
        },
        {
            title: "3. Mahabaleshwar",
            description: "Known for its lush green hills and panoramic views, Mahabaleshwar is a popular hill station and a true nature lover's paradise. Enjoy attractions like Venna Lake, where you can go boating or horse riding, and Wilson Point, the highest point in the region, offering breathtaking sunrises. Don’t miss the ancient Mahabaleshwar Temple, which holds significant religious importance, and sample the famous strawberries and mulberries from local farms."
        },
        {
            title: "4. Panchgani",
            description: "Located close to Mahabaleshwar, Panchgani is renowned for its beautiful viewpoints, including Table Land and Sydney Point, both of which provide stunning vistas of the surrounding hills and valleys. The charming atmosphere, cool climate, and lush greenery make it a great retreat for relaxation and outdoor activities. Explore the local markets for fresh produce and unique souvenirs while enjoying the serene environment."
        },
        {
            title: "5. Shirdi",
            description: "A revered pilgrimage site, Shirdi is famous for the Sai Baba Temple, which attracts millions of devotees each year. Experience the spiritual ambiance of this town, where you can participate in prayers and enjoy the peaceful surroundings. Shraddha Travels offers comfortable rides to this spiritual destination, ensuring a peaceful and stress-free journey. Nearby attractions include the Samadhi Mandir and the Dwarkamai Masjid, adding to the spiritual experience."
        },
        {
            title: "6. Goa",
            description: "For a longer escape, head to Goa, known for its beautiful beaches, vibrant nightlife, and rich Portuguese heritage. Relax on the sandy shores of Baga Beach or Anjuna Beach, enjoy water sports, and explore historic sites like the Basilica of Bom Jesus and Fort Aguada. Sample Goan cuisine, famous for its seafood and spicy curries, while soaking in the lively atmosphere of beach shacks and local markets."
        },
        {
            title: "7. Aurangabad",
            description: "Visit Aurangabad to explore the historical wonders of Ajanta and Ellora Caves, both UNESCO World Heritage Sites known for their stunning rock-cut architecture and intricate sculptures. Additionally, explore the Bibi Ka Mebarak, a beautiful tomb, and the Daulatabad Fort, offering panoramic views of the city. Aurangabad is also a great place to delve into local crafts and cuisine, including the famous Puran Poli."
        },
        {
            title: "8. Nashik",
            description: "Famous for its vineyards and religious sites, Nashik is home to Sula Vineyard, where you can enjoy wine tasting tours. Visit Panchavati, a sacred area with temples like Kalaram Temple, and participate in the Kumbh Mela, one of the largest religious gatherings in the world. Nashik's beautiful countryside and vibrant local culture make it an ideal destination for both wine enthusiasts and spiritual seekers."
        },
        {
            title: "9. Alibaug",
            description: "For a coastal escape, Alibaug offers sandy beaches, historic forts, and a relaxing atmosphere. Enjoy the serene beaches like Alibaug Beach and Nagaon Beach, perfect for sunbathing and water sports. Visit the Kolaba Fort, which is accessible by foot during low tide, and explore local seafood delicacies at beachside shacks. The charming coastal town is a great place for a leisurely weekend getaway."
        },
        {
            title: "10. Kolkata",
            description: "Although a bit farther away, Kolkata is a cultural capital known for its colonial architecture, bustling markets, and vibrant street food scene. Explore landmarks like the Victoria Memorial, Howrah Bridge, and the Indian Museum, home to a vast collection of artifacts. Enjoy the rich culinary diversity, from traditional Bengali sweets to iconic street snacks like puchka and kathi rolls, immersing yourself in the city's rich heritage and artistic vibe."
        }
    ],    
     services : [
        {
            name: "Tempo Traveller on Rent in Pune",
            description: "Looking for an easy and convenient solution for group travel? Shraddha Travels offers a wide range of Tempo Travellers on rent in Pune for all kinds of trips, whether it's a short local journey or a long-distance outstation trip. Our vehicles are designed to provide ample space, comfort, and reliability, making them ideal for family vacations, corporate outings, or weekend getaways with friends. With features like air conditioning, comfortable seating, and entertainment options, you can enjoy a hassle-free travel experience with all the amenities you need."
        },
        {
          name: "Pune to Outstation Tempo Traveller",
          description: "Shraddha Travels offers reliable and comfortable Tempo Traveller services for outstation trips from Pune. Whether you're planning a long-distance journey to popular destinations like Goa, Shirdi, Mahabaleshwar, or other locations, we provide spacious and well-maintained vehicles equipped with modern amenities. Our experienced drivers ensure a safe and smooth journey, allowing you to enjoy the scenic views along the way. With ample seating, air conditioning, and additional comforts, our outstation Tempo Travellers make your travel experience enjoyable and stress-free. Book now for an unforgettable journey!"
      },      
        {
            name: "Tempo Traveller for Outstation Travel",
            description: "For long-distance journeys, Shraddha Travels offers Tempo Travellers on rent from Pune to popular outstation destinations like Shirdi, Mahabaleshwar, Goa, and more. Our well-maintained vehicles ensure a hassle-free and comfortable journey, complete with necessary amenities. Enjoy spacious seating, plenty of legroom, and a smooth ride as you relax and take in the scenic views along the way."
        },
        {
            name: "Pune to Goa Tempo Traveller",
            description: "Planning a trip to Goa? Our Pune to Goa Tempo Traveller service provides a comfortable ride for your group, allowing you to enjoy the scenic views along the route. Equipped with modern amenities, our vehicles ensure a pleasant journey. Arrive at your destination refreshed and ready to explore the vibrant beaches and nightlife of Goa."
        },
        {
            name: "Pune to Shirdi Tempo Traveller Rates",
            description: "Planning a pilgrimage to Shirdi? Shraddha Travels offers competitive Pune to Shirdi Tempo Traveller rates starting from ₹18 per kilometer. With our experienced drivers and well-maintained vehicles, you can expect a safe and comfortable journey while you focus on your spiritual experience. We ensure that your needs are met throughout the trip."
        },
        {
            name: "Tempo Traveller Hire in Pune",
            description: "Shraddha Travels provides Tempo Traveller hire in Pune for various occasions, including family outings, corporate trips, and group tours. With a variety of seating capacities, we can accommodate groups of any size, ensuring everyone travels comfortably. Our flexible booking options and dedicated service make planning your trip straightforward and enjoyable."
        },
        {
            name: "Pune to Kolhapur Tempo Traveller Rent",
            description: "Visiting Kolhapur? Rent a Tempo Traveller from Pune for a convenient and enjoyable travel experience. Our drivers know the best routes, ensuring a smooth and efficient journey. Experience the local culture, cuisine, and historical sites without any travel hassles."
        },
        {
            name: "Luxury Tempo Traveller on Rent in Pune",
            description: "For a premium travel experience, choose our Luxury Tempo Traveller on Rent in Pune. Featuring plush interiors, spacious seating, and top-notch amenities, these vehicles are perfect for special occasions, weddings, or corporate events. Enjoy a stylish journey while being pampered with added comforts."
        },
        {
            name: "Tempo Traveller for Weddings in Pune",
            description: "Make your wedding special with our Tempo Traveller on Rent for Weddings in Pune. We can accommodate your guests comfortably, ensuring they arrive at your venue in style. With reliable service and attention to detail, we help make your special day memorable."
        },
        {
            name: "Outstation Tempo Traveller for Picnics",
            description: "Planning a picnic? Rent our Outstation Tempo Traveller for a fun day out with family and friends. With ample space for passengers and picnic gear, you can enjoy a comfortable ride to your chosen destination, making the trip as enjoyable as the destination."
        },
        {
            name: "Tempo Traveller on Rent for Corporate Events",
            description: "Whether it’s a team-building trip, a corporate retreat, or a conference, Shraddha Travels offers Tempo Travellers on rent tailored to meet your business travel needs. Enjoy spacious seating and professional drivers dedicated to providing a smooth and efficient travel experience."
        },
        {
            name: "Pune to Nashik Tempo Traveller Rent",
            description: "Explore Nashik's beautiful vineyards and temples with our Pune to Nashik Tempo Traveller Rent services. Enjoy a spacious and comfortable ride as you discover the attractions of this vibrant city, including Sula Vineyard and historical temples. Make your trip memorable with convenient travel arrangements."
        },
        {
            name: "Pune Mumbai Airport Drop Tempo Traveller on Rent",
            description: "Need a ride to the airport? Our Pune Mumbai Airport Drop Tempo Traveller on Rent service provides a reliable and comfortable option for your travel needs. Avoid the hassle of parking and enjoy a smooth transfer to the airport, allowing you to focus on your journey."
        },
        {
            name: "Tempo Traveller Contact Number for Bookings",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Tempo Traveller rentals in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
    ],    
     tableData : [
        ['- Tempo Traveller in Pune', '- Pune Tempo Traveller Hire for Outstation'],
        ['- Pune to Outstation Tempo Traveller', '- Pune to Goa Tempo Traveller'],
        ['- Pune to Kolhapur Tempo Traveller Rent', '- Pune to Nashik Tempo Traveller Rent'],
        ['- Pune to Mumbai Tempo Traveller Rent', '- Pune to Pandharpur Tempo Traveller Fare'],
        ['- Pune Mumbai Airport Drop Tempo Traveller on Rent', '- Tempo Traveller On Rent for Wedding in Pune'],
        ['- 17 Seater Tempo Traveller for Outstation in Pune', '- Luxury Tempo Traveller on Rent in Pune'],
        ['- Outstation Tempo Traveller for Picnic on Rent in Pune', '- Pune to Outstation Tempo Traveller Rate in Pune']
    ],    
    whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels for Pune to Outstation Tempo Traveller?",
          WhyChoosedescription: "When planning an outstation trip from Pune, comfort, convenience, and reliability are paramount. At Shraddha Travels, we pride ourselves on offering the best Tempo Traveller services for outstation trips, ensuring a memorable journey from start to finish. Here's why you should choose us for your next travel adventure:"
        },
        {
          WhyChooseheading: "Spacious and Comfortable Vehicles:",
          WhyChoosedescription: "Our Tempo Travellers are designed with comfort in mind. With spacious seating, ample legroom, and cushioned seats, we ensure a relaxed and enjoyable journey for all passengers, whether it’s a short drive or a long-distance outstation trip."
        },
        {
          WhyChooseheading: "Modern Amenities:",
          WhyChoosedescription: "All our Tempo Travellers come equipped with modern amenities such as air conditioning, charging ports, and entertainment systems to make your journey more enjoyable. We strive to offer a premium travel experience."
        },
        {
          WhyChooseheading: "Experienced and Professional Drivers:",
          WhyChoosedescription: "Our drivers are not just experienced but also well-trained in handling long-distance routes. They are courteous, punctual, and familiar with popular outstation destinations, ensuring a safe and timely journey."
        },
        {
          WhyChooseheading: "Flexible Travel Plans:",
          WhyChoosedescription: "Whether you're planning a weekend getaway or a long vacation, Shraddha Travels offers flexible schedules and customizable itineraries for your Pune to outstation Tempo Traveller trips. We cater to your needs, whether it's a multi-city tour or a specific destination."
        },
        {
          WhyChooseheading: "Affordable Pricing:",
          WhyChoosedescription: "We provide high-quality services at competitive rates, ensuring value for money. Our transparent pricing with no hidden charges guarantees that you receive the best deal for your outstation trip."
        },
        {
          WhyChooseheading: "Wide Range of Destinations:",
          WhyChoosedescription: "From scenic hill stations like Mahabaleshwar and Lonavala to spiritual trips to Shirdi and Pandharpur, we cover all popular outstation destinations from Pune. No matter your travel goals, Shraddha Travels has you covered."
        },
        {
          WhyChooseheading: "Safe and Sanitized Travel:",
          WhyChoosedescription: "Safety is our top priority. We adhere to strict hygiene and sanitation standards, ensuring that all vehicles are thoroughly cleaned and sanitized before every trip. Additionally, we follow all safety protocols for a worry-free journey."
        }
      ]
      
  }

  const faqData = [
    {
      question: "What is the seating capacity of Shraddha Travels’ Tempo Travellers?",
      answer: "We offer Tempo Travellers with seating capacities ranging from 9-seaters to 16-seaters. You can choose the size that best fits your group's requirements."
    },
    {
      question: "How do I book a Tempo Traveller for an outstation trip?",
      answer: "You can easily book a Tempo Traveller by contacting us via phone or through our website. Our customer service team will assist you with the booking process, itinerary planning, and any other queries."
    },
    {
      question: "What amenities are available in your Tempo Travellers?",
      answer: "All our Tempo Travellers come with air conditioning, comfortable seating, ample legroom, and luggage space. Some vehicles may also have entertainment systems, charging ports, and adjustable seats for a more luxurious experience."
    },
    {
      question: "Do you provide one-way trips or only round trips?",
      answer: "Yes, we offer both one-way and round-trip options for outstation travel. Whether you need a drop-off at your destination or a round-trip service, we cater to your travel needs."
    },
    {
      question: "Is there a minimum distance or day requirement for booking?",
      answer: "While we recommend a minimum booking for at least one day, there is no specific distance requirement. You can book our Tempo Traveller for both short trips to nearby destinations and long-distance outstation trips."
    },
    {
      question: "Are tolls and parking fees included in the pricing?",
      answer: "Our pricing typically covers the rental cost of the Tempo Traveller, fuel, and driver charges. However, tolls, parking fees, and any applicable taxes will be charged separately unless otherwise specified in your booking."
    },
    {
      question: "Can I customize the itinerary for my outstation trip?",
      answer: "Yes, we offer fully customizable itineraries. Whether you have specific sightseeing spots or a particular schedule in mind, our team will work with you to design the perfect travel plan."
    },
    {
      question: "Do you provide Tempo Traveller services for corporate or event transportation?",
      answer: "Absolutely! We cater to all types of groups, including corporate outings, wedding events, family vacations, and school/college trips. Our fleet is perfect for large group travels for any occasion."
    },
    {
      question: "How safe are your Tempo Travellers for long-distance travel?",
      answer: "Safety is our top priority. All our vehicles undergo regular maintenance and safety checks. Our drivers are highly trained in safe driving practices, ensuring a secure and reliable journey for you and your group."
    },
    {
      question: "What are your cancellation or refund policies?",
      answer: "If you need to cancel your booking, kindly inform us in advance. Refunds are processed based on our cancellation policy, which is flexible and considers the timing of the cancellation request."
    }
  ];
  


  const testimonials = [
    {
      name: "Ms. Pranoti Mohite",
      text: "I recently booked a Tempo Traveller from Shraddha Travels for a family trip to Mahabaleshwar, and it was an amazing experience! The vehicle was spacious and comfortable, and our driver was very friendly and knowledgeable. I would highly recommend them for outstation trips!"
    },
    {
      name: "Mr. Amit Jadhav",
      text: "Our group hired a Tempo Traveller from Pune to Lonavala for a weekend getaway. The service provided by Shraddha Travels was excellent! The booking process was smooth, and the driver made our journey enjoyable. We will definitely use their services again for our future trips!"
    }
  ];

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Pune to Outstation Tempo Traveller Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune to Outstation Tempo Traveller | Affordable Rentals</title>
    <meta name="description" content="Rent a tempo traveller for outstation trips from Pune. Enjoy comfortable journeys to popular destinations like Goa, Kolhapur, Nashik, and more!" />
    <meta name="keywords" content="Tempo traveller Pune rent, Pune tempo traveller hire for outstation, Pune to outstation tempo traveller, Pune to Goa tempo traveller, Pune to Kolhapur tempo traveller rent, Pune to Nashik tempo traveller rent, Pune to Mumbai tempo traveller rent, Pune to Pandharpur tempo traveller fare, Pune Mumbai Airport drop tempo traveller on rent, Tempo traveller on rent for wedding in Pune, 17 seater tempo traveller for outstation in Pune, Luxury tempo traveller on rent in Pune, Outstation tempo traveller for picnic on rent in Pune, Pune to outstation tempo traveller rate in Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to Outstation Tempo Traveller | Affordable Rentals" />
    <meta property="og:description" content="Explore our tempo traveller rental services for outstation trips from Pune. Enjoy luxury and comfort at competitive rates!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-to-outstation-tempo-traveller" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/outstation-tempo-traveller.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pune to Outstation Tempo Traveller Rental",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-to-outstation-tempo-traveller",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller",
                    "description": "Comfortable Tempo Traveller on Rent for outstation trips from Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>


      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-03.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs: Pune to Outstation Tempo Traveller by Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetooutstationtempo;