
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Touristbusservice() {



  const cardData =
  {
    keyword: 'Tourist Bus Rental Services',
    heading: 'Shraddha Travels: Tourist Bus Rental Services',
    headingDescription: 'Shraddha Travels is your trusted partner for premium tourist bus rental services, providing top-tier transportation solutions for groups, families, corporate teams, schools, and more. Whether you’re planning a one-day trip, an extended vacation, or a special event, Shraddha Travels ensures a smooth, safe, and comfortable journey. Our well-maintained fleet of buses, coupled with our unwavering commitment to customer satisfaction, makes us the preferred choice for bus rental services across India.',
    top: 'Top Tourist Bus Rental Services by Shraddha Travels',

    topPlaces: [
        {
            title: "1. Pune to Rajasthan Tourist Bus Rental",
            description: "Discover the grandeur of Rajasthan with our exceptional bus rental services. Whether you're exploring the palaces of Jaipur, the lakes of Udaipur, or the forts of Jodhpur, our buses ensure long-distance comfort. Our itineraries are crafted to include must-see attractions, local dining experiences, and cultural immersions, making your journey through the royal state unforgettable."
        },
        {
            title: "2. Pune to Goa Tourist Bus Rental",
            description: "Get ready for an exciting beach vacation in Goa! Shraddha Travels offers luxurious buses equipped with modern amenities for a comfortable and enjoyable ride from Pune to Goa. Our packages include scenic stops along the way, ensuring a delightful travel experience perfect for group vacations, family holidays, or corporate retreats, complete with recommendations for the best beaches and local cuisine."
        },
        {
            title: "3. Pune to Mahabaleshwar Tourist Bus Rental",
            description: "Escape to the serene hills of Mahabaleshwar with our reliable bus rental services. Whether it’s a family picnic, a corporate outing, or a school trip, Shraddha Travels ensures a relaxing and enjoyable journey to this picturesque hill station. Experience the lush greenery, breathtaking viewpoints, and delicious local strawberries, all while traveling in comfort and style."
        },
        {
            title: "4. Pune to Lonavala and Khandala Tourist Bus Rental",
            description: "Planning a weekend getaway to the nearby hill stations of Lonavala and Khandala? Our buses provide a convenient and comfortable mode of transportation, making your trip to these scenic destinations effortless and relaxing. Enjoy the stunning landscapes, visit popular attractions like Bhushi Dam, and savor the local chikki on your journey."
        },
        {
            title: "5. Pune to Shirdi Tourist Bus Rental",
            description: "For devotees traveling to the sacred town of Shirdi, Shraddha Travels offers secure and comfortable bus rental services. Enjoy a peaceful and convenient journey to this holy destination with our well-equipped buses. Our itineraries can include visits to nearby temples and other spiritual sites, ensuring a fulfilling pilgrimage experience."
        },
        {
            title: "6. Corporate Event Bus Rentals",
            description: "Organizing a corporate event or team outing? Shraddha Travels provides professional and timely bus rental services for corporate events, conferences, and team-building retreats. We cater to your needs, ensuring your team travels in comfort and style. Our services can include flexible pick-up and drop-off options, allowing for a seamless experience."
        },
        {
            title: "7. School and College Excursion Bus Rentals",
            description: "Planning a school or college field trip? Shraddha Travels offers safe and reliable bus rental services for educational excursions. Our experienced drivers and well-maintained buses ensure a safe and enjoyable trip for students and faculty alike. We also provide assistance in planning educational itineraries that align with curriculum goals."
        },
        {
            title: "8. Wedding and Event Bus Rental Services",
            description: "Make your wedding or special event stress-free with Shraddha Travels' dedicated bus rental services. We provide seamless transportation for guests, ensuring everyone travels comfortably to and from your event venues. Our team can coordinate logistics to handle large groups, making your event planning smoother and more efficient."
        },
        {
            title: "9. Pune to Konkan Tourist Bus Rental",
            description: "Explore the beautiful Konkan coastline with our tourist bus services. From stunning beaches to lush landscapes, Shraddha Travels makes your journey along the Konkan coast a breeze with our comfortable and well-equipped buses. We offer guided tours to popular attractions, local seafood delicacies, and stops at hidden gems along the way."
        },
        {
            title: "10. Outstation Tourist Bus Rentals Across India",
            description: "Shraddha Travels extends its top-notch bus rental services to outstation destinations across India, including popular tourist spots like Kerala, Himachal Pradesh, and Madhya Pradesh. Whether it’s a long-distance tour or a weekend getaway, our buses are designed for comfort and convenience, ensuring you enjoy every moment of your journey. Our knowledgeable staff can help you design the perfect itinerary tailored to your interests."
        }
    ],    
    services: [
        {
            name: "Pune to Rajasthan Tourist Bus Rental",
            description: "Travel to the royal land of Rajasthan with our premium bus services, ideal for long-distance trips. Explore the vibrant streets of Jaipur, the breathtaking lakes of Udaipur, and the majestic forts of Jodhpur with ease and comfort. Our buses are equipped with reclining seats, air conditioning, and entertainment options, ensuring a relaxing journey as you immerse yourself in Rajasthan's rich cultural heritage. Experience guided tours of historical sites, local cuisine, and traditional markets along the way."
        },
        {
          name: "Tourist Bus Rental Services",
          description: "Looking for reliable and comfortable Tourist Bus Rental Services? Shraddha Travels offers premium bus rental options for group travel across India. Whether you're planning a pilgrimage to Shirdi, a corporate event, a family trip to Mahabaleshwar, or a weekend getaway to Lonavala, our fleet of modern buses ensures a seamless and enjoyable travel experience. We specialize in offering tailored solutions for school excursions, weddings, and long-distance trips to destinations like Goa, Rajasthan, and Konkan. With experienced drivers, spacious seating, and onboard amenities like air conditioning and entertainment options, our services guarantee comfort and convenience. Contact Shraddha Travels today for the best Tourist Bus Rental Services and experience hassle-free travel!"
        },      
        {
            name: "Pune to Goa Tourist Bus Rental",
            description: "Plan your next trip to the sun-kissed beaches of Goa with our luxurious buses. Perfect for group vacations or corporate retreats, we provide top-notch service from Pune to Goa. Enjoy comfortable seating, onboard amenities, and scenic routes that showcase the natural beauty of Western India. Our packages can include stops at popular attractions, local markets, and recommended restaurants, ensuring you have an enjoyable and memorable beach getaway."
        },
        {
            name: "Pune to Mahabaleshwar Tourist Bus Rental",
            description: "Visit the serene hill station of Mahabaleshwar with our reliable bus rental services. Perfect for family outings, school trips, and corporate tours, our buses ensure a smooth ride through lush green landscapes and breathtaking viewpoints. Experience the fresh mountain air as you explore popular spots like Arthur's Seat and Venna Lake. Our knowledgeable drivers can suggest the best local eateries and sightseeing opportunities, making your trip both enjoyable and relaxing."
        },
        {
            name: "Pune to Lonavala and Khandala Tourist Bus Rental",
            description: "Enjoy a comfortable journey to the beautiful hill stations of Lonavala and Khandala with our buses. Ideal for weekend getaways and group travel, our services include comfortable seating and spacious interiors, allowing you to relax and take in the stunning scenery. We also provide information on popular attractions, such as Bhushi Dam and Karla Caves, ensuring that you have a fulfilling experience amidst the tranquil hills."
        },
        {
            name: "Pune to Shirdi Tourist Bus Rental",
            description: "For pilgrims heading to the spiritual town of Shirdi, our safe and reliable bus services make the journey smooth and comfortable. Our experienced drivers ensure timely arrivals and departures, allowing you to focus on your spiritual experience. With options for group bookings, we cater to your travel needs, providing a serene environment as you travel to the holy shrine of Sai Baba."
        },
        {
            name: "Corporate Event Bus Rentals",
            description: "For business events, conferences, or team outings, Shraddha Travels offers reliable bus rentals, ensuring timely service and a comfortable ride for corporate groups. Our fleet is well-maintained, featuring modern amenities to create a professional travel experience tailored to your company's needs. We can accommodate large groups and provide flexible scheduling, making your corporate travel seamless and efficient."
        },
        {
            name: "School and College Excursion Bus Rentals",
            description: "Planning a school or college trip? Our buses are perfect for educational excursions, ensuring a safe and enjoyable experience for students and teachers alike. With experienced drivers and a focus on safety, we make sure that your students are well taken care of throughout the journey. Our team can assist with itinerary planning, including stops at educational sites, cultural landmarks, and recreational activities, enriching the learning experience."
        },
        {
            name: "Wedding and Event Bus Rentals",
            description: "Make wedding or event transportation stress-free by booking our bus services. We provide seamless transportation for guests, ensuring they arrive in comfort and style. Our team can coordinate logistics for large groups, managing pick-up and drop-off points to keep everything running smoothly on your special day. Choose us for a reliable and elegant solution to event transportation."
        },
        {
            name: "Pune to Konkan Tourist Bus Rental",
            description: "Explore the scenic Konkan coastline with our dedicated tourist bus services. Whether it's a group vacation or a family trip, our buses offer comfort and convenience as you travel through picturesque beaches, ancient temples, and lush forests. Enjoy the breathtaking views of the Arabian Sea while we handle the logistics, allowing you to focus on creating unforgettable memories along the stunning coast."
        },
        {
            name: "Outstation Tourist Bus Rentals Across India",
            description: "Shraddha Travels offers nationwide outstation bus rental services. Whether you're planning a long-distance trip to Kerala, Himachal Pradesh, or Madhya Pradesh, our buses are equipped for comfortable travel across India. With amenities like spacious seating and air conditioning, we ensure a smooth and enjoyable journey wherever you go. Our experienced drivers are familiar with various routes, providing a safe and efficient travel experience."
        },
        {
            name: "Tourist Bus Rental Services Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Tourist Bus Rental Services. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today, and let us help you plan your next adventure with ease!"
        }
    ],    
    tableData: [
        ['- Tour Bus Rental in Pune', '- Tour Bus Rental Cost in Pune'],
        ['- Tourist Bus Rental Services in Bibwewadi', '- Tourist Bus Rental Services in Hadapsar'],
        ['- Tourist Bus Rental Services in Hinjewadi', '- Tour Bus Rental Near Me Pune'],
        ['- Tour Bus Hire in Pune', '- Private Tour Bus Rental in Shivaji Nagar'],
        ['- Tour Bus Rental Price', '- Mini Tour Bus Rental'],
        ['- Hire a Bus for Tour in Pune', '- Luxury Tour Bus Companies'],
        ['- Luxury Tour Bus Rentals Service', '- Sleeper Tour Bus Hire Pune'],
        ['- Shraddha Travels Pune On Rent', '']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels' Tourist Bus Rental Services?",
            WhyChoosedescription: "At Shraddha Travels, we strive to make your group travel experience smooth, comfortable, and enjoyable. Our expert team is committed to understanding your unique travel needs, ensuring that every aspect of your journey is tailored to your expectations. Whether it's a family vacation, corporate event, school excursion, or pilgrimage, our tourist bus rental services are designed to provide you with exceptional value and convenience."
        },
        {
            WhyChooseheading: "Extensive Fleet of Buses:",
            WhyChoosedcription: "We offer a wide range of buses, including luxury coaches, sleeper buses, mini buses, and Volvo buses, catering to various group sizes and travel requirements. Each vehicle in our fleet is modern, well-maintained, and equipped with the latest amenities, such as comfortable seating, climate control, and entertainment systems, ensuring a pleasurable and relaxing journey for all passengers."
        },
        {
            WhyChooseheading: "Comfortable and Safe Travel:",
            WhyChoosedescription: "Safety and comfort are our top priorities. All our buses come equipped with air conditioning, spacious seating arrangements, and entertainment systems to keep you engaged during your travels. Additionally, we implement strict safety protocols, including the use of seat belts, GPS tracking for real-time location monitoring, and regular vehicle maintenance checks, ensuring a hassle-free ride for you and your group."
        },
        {
            WhyChooseheading: "Professional Drivers:",
            WhyChoosedescription: "Our drivers are highly experienced and well-trained, familiar with long-distance routes and local traffic conditions. They prioritize your safety and comfort, maintaining a courteous and punctual demeanor throughout the journey. With their extensive knowledge of the best routes and travel tips, you can relax knowing you're in capable hands."
        },
        {
            WhyChooseheading: "Affordable Pricing:",
            WhyChoosedescription: "We believe that quality travel shouldn't break the bank. At Shraddha Travels, we offer competitive pricing without compromising on service quality. Whether you’re booking a bus for a short day trip or a long outstation journey, our cost-effective solutions are tailored to fit your budget, ensuring you get the best value for your money."
        },
        {
            WhyChooseheading: "Customizable Packages:",
            WhyChoosedescription: "Every trip is unique, which is why we offer customizable packages to suit your specific needs. Whether it’s for a one-day excursion, a multi-day tour, or a special event, we can tailor our bus rental services to match your itinerary and preferences. From meal arrangements to sightseeing tours, we can assist in planning every detail to ensure a memorable experience."
        },
        {
            WhyChooseheading: "Timely and Reliable Service:",
            WhyChoosedescription: "We understand the importance of punctuality in travel. Our buses are meticulously scheduled to ensure that you stick to your travel itinerary without delays. Our commitment to timely service and professionalism has earned us a reputation as a trusted provider of tourist bus rental services in Pune, making your travel experience worry-free."
        },
        {
            WhyChooseheading: "Customer-Centric Approach:",
            WhyChoosedescription: "At Shraddha Travels, customer satisfaction is at the heart of everything we do. Our dedicated team is always available to assist you, from the initial booking process to post-trip support. We pride ourselves on our responsive communication and personalized service, ensuring that your entire journey with us is smooth and enjoyable."
        },
        {
            WhyChooseheading: "Nationwide Coverage:",
            WhyChoosedescription: "Whether you're traveling within Pune or exploring outstation destinations across India, our bus services cover all major cities and tourist spots. From the lush hills of Mahabaleshwar to the serene beaches of Goa, we’ve got you covered. Our extensive network allows us to facilitate trips to even the most remote locations, ensuring a hassle-free travel experience wherever you go."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What types of buses do you offer for rental?",
        answer: "We offer a wide range of buses including luxury coaches, sleeper buses, mini buses, and Volvo buses. Our buses cater to various group sizes and are perfect for both short trips and long-distance travel."
    },
    {
        question: "How do I book a bus with Shraddha Travels?",
        answer: "You can book a bus by contacting our team directly via phone or email. Alternatively, you can visit our website to fill out a booking inquiry form, and our team will reach out to you for further details."
    },
    {
        question: "What is the pricing structure for bus rentals?",
        answer: "The cost of renting a bus varies based on factors like the type of bus, distance traveled, duration of the trip, and any additional services required. For a customized quote, please contact us with your specific travel details."
    },
    {
        question: "Can I rent a bus for outstation trips?",
        answer: "Yes, we provide bus rental services for outstation trips across India. Whether you’re traveling to Rajasthan, Goa, Konkan, or any other destination, our buses are ready for long-distance travel."
    },
    {
        question: "Do your buses come with a driver?",
        answer: "Yes, all our bus rentals include an experienced and licensed driver to ensure safe and comfortable travel for your group."
    },
    {
        question: "Are your buses equipped with modern amenities?",
        answer: "Absolutely! Our buses feature modern amenities such as air conditioning, entertainment systems, reclining seats, and ample luggage space, ensuring a comfortable journey for all passengers."
    },
    {
        question: "How far in advance should I book a bus?",
        answer: "We recommend booking your bus rental at least 1-2 weeks in advance, especially during peak seasons, to ensure availability. However, we also accommodate last-minute bookings based on bus availability."
    },
    {
        question: "Can I customize my travel itinerary?",
        answer: "Yes, we offer customizable travel packages based on your itinerary. Whether you need multiple stops or specific pick-up and drop-off points, we can tailor our services to meet your travel requirements."
    },
    {
        question: "Is there a cancellation policy?",
        answer: "Yes, we have a cancellation policy. If you need to cancel your booking, please notify us as soon as possible. Cancellation charges may apply depending on the notice period."
    },
    {
        question: "Do you provide buses for weddings or special events?",
        answer: "Yes, we offer bus rental services for weddings, family functions, corporate events, and other special occasions. Our buses are perfect for shuttling guests between venues or organizing group transportation for events."
    }
];


const testimonials = [
    {
        name: "Mr. Rohit Deshmukh",
        text: "We recently booked Shraddha Travels for our company's annual outing, and the experience was fantastic! The bus was spacious, clean, and well-maintained, with all the modern amenities to make our journey comfortable. The driver was professional and punctual, and the entire trip went smoothly without any hassles. Shraddha Travels exceeded our expectations with their exceptional service. We will definitely use their tourist bus rental services again for future trips!"
    },
    {
        name: "Miss. Sneha Joshi",
        text: "I booked a tourist bus with Shraddha Travels for a family trip to Mahabaleshwar, and it was an amazing experience. The bus was comfortable, the staff was friendly, and the journey was seamless. From the moment we booked to the time we returned; everything was taken care of perfectly. I highly recommend Shraddha Travels for anyone looking for reliable and comfortable tourist bus rental services. They made our trip truly memorable!"
    }
];

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Tourist Bus Rental Services Contact Number: 	",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Tourist Bus Rental Services | Contact: +91 8087847191 | Shraddha Travels |  Affordable & Reliable Transport</title>
    <meta name="description" content="Explore our Tourist Bus Rental Services in Pune. Offering private tours, luxury buses, and competitive pricing for all your travel needs." />
    <meta name="keywords" content="Tourist Bus Rental, Tour Bus Rental in Pune, Private Tour Bus Hire, Luxury Tour Bus Rentals, Mini Tour Bus Rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tourist Bus Rental Services | Affordable & Reliable Transport" />
    <meta property="og:description" content="Book our Tourist Bus Rental Services in Pune for an unforgettable travel experience. Luxury options and competitive prices available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/tourist-bus-rental" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tourist-bus-rental.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tourist Bus Rental Services",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tourist-bus-rental",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tourist Bus Rental Services in Pune",
                    "description": "Offering a range of Tourist Bus Rental Services in Pune, including private tours and luxury buses for all occasions."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 3-06.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs: Tourist Bus Rental Services by Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Touristbusservice;