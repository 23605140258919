
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetorajashtan() {



  const cardData =
  {
    keyword: ' Pune to Rajasthan Darshan Bus Package',
    heading: 'Shraddha Travels: Pune to Rajasthan Darshan Bus Package',
    headingDescription: 'Experience the royal grandeur of Rajasthan with Shraddha Travels Pune to Rajasthan Darshan Bus Package. Embark on a journey through India"s majestic desert state, famous for its opulent palaces, ancient forts, vibrant culture, and historical significance. This bus package is the perfect way to explore the stunning landscapes and cultural treasures of Rajasthan, all in the comfort of our luxury buses.',
    top: 'Top Places to Visit in Rajasthan with Shraddha Travels',

    topPlaces: [
        {
            title: "1. Jaipur – The Pink City",
            description: "Jaipur, the capital of Rajasthan, is renowned for its royal palaces and historic architecture. The Amber Fort, with its stunning hilltop views and intricate mirror work, is a highlight. Explore the Hawa Mahal (Palace of Winds), designed for royal ladies to observe street festivities. The City Palace, a blend of Mughal and Rajasthani architecture, houses museums showcasing artifacts from Rajasthan’s royal history. Don’t forget to shop in the lively bazaars, where you can find traditional Rajasthani textiles, jewelry, and handicrafts."
        },
        {
            title: "2. Udaipur – The City of Lakes",
            description: "Known as one of the most romantic cities in India, Udaipur is famous for its serene lakes and grand palaces. The City Palace, with its stunning views of Lake Pichola, is a must-see, and a boat ride on the lake at sunset offers a magical experience. Other highlights include the Jag Mandir, an exquisite island palace, and Saheliyon Ki Bari, a beautifully landscaped garden with fountains and marble elephants. Udaipur's vibrant markets also offer handicrafts and traditional artwork."
        },
        {
            title: "3. Jaisalmer – The Golden City",
            description: "Located in the heart of the Thar Desert, Jaisalmer is known for its golden sandstone architecture that glows at sunset. The Jaisalmer Fort, a UNESCO World Heritage site, is a living fort with shops and residences. Explore ancient havelis (mansions) like the Patwon Ki Haveli, adorned with intricate carvings. Jaisalmer is also the starting point for exciting camel safaris in the desert, where you can experience the beauty of the dunes and enjoy traditional Rajasthani music under the stars."
        },
        {
            title: "4. Jodhpur – The Blue City",
            description: "Famous for its striking blue-painted houses, Jodhpur is home to the majestic Mehrangarh Fort, one of the largest and most well-preserved forts in India. The fort offers panoramic views of the city and houses a museum showcasing royal artifacts, including weapons and costumes. Visit the Umaid Bhawan Palace, a luxury hotel and museum, and the serene Jaswant Thada cenotaph, built in memory of Maharaja Jaswant Singh II. The local markets are perfect for finding traditional handicrafts and textiles."
        },
        {
            title: "5. Pushkar – The Holy City",
            description: "Pushkar is a serene town known for its sacred Pushkar Lake, surrounded by 52 bathing ghats. The rare Brahma Temple, one of the few dedicated to Lord Brahma, is a significant pilgrimage site. The town is also famous for the Pushkar Camel Fair, which attracts tourists from around the world for its vibrant culture and festivities. The holy atmosphere, colorful bazaars, and local cuisine make Pushkar a must-visit destination for spiritual seekers and travelers alike."
        },
        {
            title: "6. Ranthambore National Park",
            description: "For wildlife lovers, Ranthambore National Park offers an incredible chance to spot Bengal tigers in their natural habitat. The park is also home to leopards, deer, and various bird species, making it a paradise for nature enthusiasts. While here, don’t miss visiting the ancient Ranthambore Fort, a UNESCO World Heritage site with panoramic views of the park. The park’s unique landscape of dry deciduous forests and open grasslands provides an exceptional safari experience."
        },
        {
            title: "7. Bikaner – The Desert Town",
            description: "Bikaner is known for its rich heritage and well-preserved architecture. The Junagarh Fort, built in the 16th century, stands as a testament to the city’s history, showcasing beautiful courtyards and ornate interiors. Bikaner is also famous for the Karni Mata Temple, where thousands of sacred rats are worshipped, symbolizing good luck. The city is renowned for its delicious sweets and snacks, especially the famous Bikaneri bhujia. Explore local bazaars to discover handicrafts and traditional art."
        },
        {
            title: "8. Mount Abu – The Hill Station",
            description: "Mount Abu is Rajasthan’s only hill station, offering a cool escape from the desert heat. The Dilwara Temples, renowned for their intricate marble carvings, are a must-see for architecture enthusiasts. Visitors also enjoy boating at Nakki Lake, surrounded by scenic hills, and hiking to Guru Shikhar, the highest peak in the Aravalli range. The town’s vibrant markets offer local handicrafts, and the pleasant climate makes it an ideal retreat for relaxation."
        },
        {
            title: "9. Chittorgarh – The Fort of Legends",
            description: "Chittorgarh Fort, the largest fort in India, is a symbol of Rajput valor and pride. The fort is an architectural marvel with towering gates, palaces, temples, and the iconic Vijay Stambh (Victory Tower). Each structure within the fort tells a tale of bravery and sacrifice. A visit here offers a deep dive into Rajasthan’s storied past, and the beautiful surroundings make it a picturesque site for history enthusiasts and photographers alike."
        },
        {
            title: "10. Ajmer – The City of Pilgrimage",
            description: "Ajmer is a significant religious destination, home to the Ajmer Sharif Dargah, one of India’s most important Muslim pilgrimage sites, attracting devotees from all faiths. The city is also known for its beautiful Ana Sagar Lake, perfect for a peaceful stroll, and the ancient Adhai Din Ka Jhonpra, an architectural wonder. Ajmer’s bustling markets offer a variety of local handicrafts, and its rich cultural heritage is reflected in its cuisine, making it a captivating place to visit."
        }
    ],    
    services: [
        {
            name: "Pune to Jodhpur Bus on Rent",
            description: "Our Pune to Jodhpur bus on rent service ensures a smooth and enjoyable journey. Known as the Blue City, Jodhpur is famous for its grand Mehrangarh Fort and vibrant markets. With Shraddha Travels, you can relax in comfortable seating while our experienced drivers navigate the route. Enjoy local cuisine, shop for handicrafts, and explore Jodhpur’s rich history, all without the stress of planning your transport."
        },
        {
          name: "Pune to Rajasthan Darshan Bus Package",
          description: "Embark on an unforgettable pilgrimage with our Pune to Rajasthan Darshan Bus Package. Shraddha Travels offers a well-curated itinerary that covers the most significant religious and cultural destinations in Rajasthan. Explore the royal cities, temples, and historic sites, including Jaipur, Udaipur, and Jaisalmer, while traveling in the comfort of our modern buses. With experienced drivers and a focus on safety and convenience, this package ensures a smooth and enriching travel experience. Whether you're seeking spiritual solace or exploring Rajasthan’s rich heritage, our bus package provides the perfect way to experience it all."
      },      
        {
            name: "Pune to Mount Abu Bus on Rent",
            description: "Take a break from the heat and plan a trip to the beautiful hill station of Mount Abu. Our Pune to Mount Abu bus on rent service offers comfortable, air-conditioned buses that make the journey pleasant. Whether you're seeking relaxation by Nakki Lake, visiting the Dilwara Temples, or hiking to Guru Shikhar, our buses ensure a hassle-free travel experience for your entire group."
        },
        {
            name: "Pune to Jaisalmer Bus Packages",
            description: "Explore the Golden City of Rajasthan with our Pune to Jaisalmer bus packages. Famous for its golden sand dunes and the historic Jaisalmer Fort, this desert city is a must-visit. Shraddha Travels provides flexible packages that include guided tours to prominent attractions, allowing you to immerse yourself in the local culture while enjoying the comforts of our well-maintained buses."
        },
        {
            name: "Pune to Rajasthan Darshan Bus Packages",
            description: "Shraddha Travels presents the best Pune to Rajasthan Darshan bus packages for travelers looking to explore multiple cities in Rajasthan. Our itineraries cover popular destinations like Jaipur, Udaipur, and Jaisalmer, ensuring you experience the rich heritage and stunning landscapes of the region. Travel comfortably and conveniently, with everything arranged for you."
        },
        {
            name: "32 Seater Bus for Pune to Rajasthan",
            description: "Our 32-seater bus for Pune to Rajasthan is perfect for family reunions, corporate outings, or school trips. With spacious interiors and comfortable seating, this bus ensures a smooth and enjoyable ride through Rajasthan's beautiful landscapes. Our professional drivers prioritize your safety, and we provide amenities like air conditioning and entertainment options to enhance your travel experience."
        },
        {
            name: "Pune to Rajasthan Darshan Bus Hire",
            description: "Our Pune to Rajasthan Darshan bus hire service is designed for groups who want to create their own itinerary. Rent a bus and explore the royal cities of Rajasthan at your own pace. Equipped with modern amenities and experienced drivers, our buses provide a comfortable journey, allowing you to make spontaneous stops and enjoy the journey just as much as the destination."
        },
        {
            name: "Pune to Rajasthan Road Trip Bus Booking",
            description: "Want to take a road trip from Pune to Rajasthan? Book a bus with Shraddha Travels for a memorable journey. Our Pune to Rajasthan road trip bus booking services offer flexibility, allowing you to stop and explore various cities and attractions on your way. Enjoy the scenic beauty of the countryside, visit local markets, and indulge in regional delicacies as you travel."
        },
        {
            name: "Pune to Rajasthan Udaipur Bus on Rent for Picnic",
            description: "Rent a bus from Shraddha Travels for a relaxing journey. Our Pune to Rajasthan Udaipur bus on rent for picnic service is perfect for large groups, offering comfort and convenience as you travel to one of India’s most scenic destinations. Enjoy breathtaking views of Lake Pichola, explore the City Palace, and unwind in the serene surroundings of Udaipur."
        },
        {
            name: "Mini Bus on Rent Pune for Rajasthan Trip",
            description: "If you're looking for a smaller, more intimate group trip, our mini bus on rent Pune for Rajasthan trip is the ideal option. Suitable for family outings or small corporate teams, this bus offers a cozy and affordable travel experience. Enjoy personalized service and flexibility in your travel plans, making the journey as enjoyable as the destination."
        },
        {
            name: "Pune to Rajasthan Bus Trip Packages",
            description: "Shraddha Travels offers customizable Pune to Rajasthan bus trip packages that cater to all types of travelers. Whether you're interested in exploring forts, palaces, or desert landscapes, we can create a package that meets your travel needs. Our knowledgeable staff assists you in planning the perfect itinerary, ensuring you experience the best of Rajasthan in comfort and style."
        },
        {
            name: "Pune to Rajasthan Volvo Bus Service",
            description: "Our Pune to Rajasthan Volvo bus service is the perfect choice for those who prefer a premium experience. Volvo buses are equipped with top-notch amenities, including reclining seats, entertainment systems, and restrooms. Enjoy a smooth and comfortable ride for long-distance travel, allowing you to arrive at your destination refreshed and ready to explore."
        },
        {
            name: "Pune to Rajasthan Sleeper Bus Hire",
            description: "For overnight journeys, our Pune to Rajasthan sleeper bus hire service provides comfortable sleeper seats, ensuring you arrive at your destination well-rested. Ideal for long road trips, these buses offer spacious seating and modern conveniences, making your travel experience enjoyable and relaxing, even on extended trips."
        },
        {
            name: "Rent a Bus for a Trip",
            description: "Shraddha Travels offers reliable and affordable bus rental services from Pune to various destinations across Rajasthan. Whether it’s for a family outing, corporate event, or school excursion, we have a wide range of buses to suit your needs. Our dedicated customer service team ensures a seamless booking process, making it easy for you to plan your trip."
        },
        {
            name: "Shraddha Travels Pune on Rent",
            description: "Shraddha Travels provides top-quality bus rental services in Pune. Whether you need a bus for a short trip or a long-distance journey, Shraddha Travels Pune on rent has a variety of options to choose from. Our well-maintained buses ensure a safe and comfortable journey for all types of trips, backed by our commitment to customer satisfaction."
        },
        {
            name: "Mini Bus On Rent in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Mini Bus On Rent in Pune. Our team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
    ],    
    tableData: [
        ['- Pune to Jaipur Bus On Rent', '- Pune to Jodhpur Bus On Rent'],
        ['- Pune to Mount Abu Bus on Rent', '- Pune to Jaisalmer Bus Packages'],
        ['- Pune to Rajasthan Darshan Bus Packages', '- 32 Seater Bus for Pune to Rajasthan'],
        ['- Pune to Rajasthan Darshan Bus Hire', '- Pune to Rajasthan Road Trip Bus Booking'],
        ['- Pune to Rajasthan Udaipur Bus on Rent for Picnic', '- Mini Bus on Rent Pune for Rajasthan Trip'],
        ['- Pune to Rajasthan Bus Trip Packages', '- Pune to Rajasthan Volvo Bus Service'],
        ['- Pune to Rajasthan Sleeper Bus Hire', '- Rent a Bus for a Trip'],
        ['- Shraddha Travels Pune On Rent', '']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Us for Pune to Rajasthan Darshan Bus Package?",
            WhyChoosedescription: "Shraddha Travels is dedicated to providing an exceptional travel experience. Our Pune to Rajasthan Darshan Bus Package is designed for comfort and reliability, ensuring a hassle-free journey as you explore the rich culture and heritage of Rajasthan."
        },
        {
            WhyChooseheading: "Experienced and Reliable Service:",
            WhyChoosedescription: "With years of expertise, we offer well-planned itineraries and seamless coordination, ensuring your trip is enjoyable from start to finish."
        },
        {
            WhyChooseheading: "Comfortable and Well-Maintained Buses:",
            WhyChoosedescription: "Our modern buses come equipped with comfortable seating, air conditioning, and clean interiors, ensuring you travel in style whether you choose a luxury Volvo, 32-seater, or sleeper bus."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedescription: "Our professional drivers are well-versed in the routes and terrain of Rajasthan, providing a safe and smooth journey for all passengers."
        },
        {
            WhyChooseheading: "Customizable Packages:",
            WhyChoosedescription: "We offer flexible Rajasthan Darshan Bus Packages that can be tailored to your preferences, whether you want to explore Jaipur, Jaisalmer, Udaipur, or a mix of cities."
        },
        {
            WhyChooseheading: "Affordable Pricing:",
            WhyChoosedescription: "Our competitive and transparent pricing ensures you receive excellent value for your money, making it ideal for families, corporate groups, and schools."
        },
        {
            WhyChooseheading: "Expert Tour Planning:",
            WhyChoosedescription: "Our team of travel experts will help plan your trip, selecting the best sightseeing spots and creating a smooth travel schedule so you can focus on enjoying your vacation."
        }
    ]
      }


      const faqData = [
        {
            question: "What does the Pune to Rajasthan Darshan Bus Package include?",
            answer: "The package includes round-trip transportation from Pune to Rajasthan, comfortable seating, an experienced driver, and a customizable itinerary. Some packages may also include sightseeing tours and accommodation, depending on the package chosen."
        },
        {
            question: "Can we customize the Rajasthan Darshan Bus Package?",
            answer: "Yes, Shraddha Travels offers customizable bus packages. You can choose the cities you wish to visit, such as Jaipur, Jodhpur, Udaipur, and Jaisalmer, and we’ll tailor the itinerary to fit your preferences."
        },
        {
            question: "What type of buses are available for the Rajasthan Darshan Bus Package?",
            answer: "We offer a range of buses, including 32-seater buses, mini buses, Volvo buses, and sleeper buses. All our buses are well-maintained, air-conditioned, and equipped with comfortable seating."
        },
        {
            question: "How long is the journey from Pune to Rajasthan?",
            answer: "The distance between Pune and Rajasthan varies depending on your destination. On average, it takes around 15-18 hours to reach Jaipur or Udaipur by road. Our buses are designed to provide maximum comfort for long journeys."
        },
        {
            question: "What safety measures are in place during the trip?",
            answer: "At Shraddha Travels, we prioritize safety. Our buses are regularly inspected, and our drivers are experienced and trained for long-distance travel. Additionally, we follow all government regulations related to hygiene and safety."
        },
        {
            question: "Do you provide on-board amenities?",
            answer: "Yes, our buses are equipped with amenities such as air conditioning, comfortable seats, and in some cases, sleeper berths. Depending on the bus, we may also provide Wi-Fi, charging points, and onboard entertainment."
        },
        {
            question: "Are meals included in the package?",
            answer: "Meals are not usually included in the basic package, but we do offer packages where meals can be arranged upon request. You will also have scheduled stops at reliable restaurants along the way."
        },
        {
            question: "Can we book a bus for a private group?",
            answer: "Yes, we offer private bus hire for groups, whether it’s for family vacations, corporate tours, or school excursions. You can book a bus exclusively for your group and customize the itinerary according to your needs."
        },
        {
            question: "How do I book the Pune to Rajasthan Darshan Bus Package?",
            answer: "You can book your bus package by contacting Shraddha Travels directly through our website or by calling our customer service team. We offer easy booking options and flexible payment methods."
        },
        {
            question: "Is there a cancellation policy?",
            answer: "Yes, we have a flexible cancellation policy. If you need to cancel or reschedule your trip, please contact us as soon as possible, and we will assist you with the process."
        }
    ];
    

    const testimonials = [
        {
            name: "Miss. Priya Deshmukh, Pune",
            text: "My journey with Shraddha Travels on the Pune to Rajasthan Darshan bus package was nothing short of spectacular. From the very beginning, the experience was seamless, with a well-organized itinerary that allowed us to explore the rich cultural heritage of Rajasthan comfortably. The bus was clean and equipped with all the amenities we needed for a long journey. Our guide was incredibly knowledgeable and made the historical sites come alive with engaging stories. Highlights for me included the majestic forts of Jaipur and the stunning palaces of Udaipur. The whole trip was a perfect blend of adventure, relaxation, and cultural immersion. I highly recommend Shraddha Travels for anyone looking to experience the essence of Rajasthan with ease and comfort."
        },
        {
            name: "Mr. Rajesh Patel, Pune",
            text: "I recently took the Pune to Rajasthan Darshan bus package offered by Shraddha Travels, and it exceeded all my expectations. The planning and execution were flawless, with a detailed itinerary that ensured we saw the best of Rajasthan's heritage. The bus was comfortable, with plenty of legroom and amenities that made the long distances enjoyable. The local guides were friendly and knowledgeable, offering insights into the history and culture of each destination. The highlight of the trip was visiting the magnificent palaces and forts, especially the City Palace in Udaipur. Shraddha Travels has truly delivered an exceptional travel experience, and I would gladly recommend them to anyone looking to explore Rajasthan in style."
        }
    ];
    

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "  Pune to Rajasthan Darshan Bus Package Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Pune to Rajasthan Darshan Bus Package | Contact: +91 8087847191 | Shraddha Travels | Affordable Travel</title>
    <meta name="description" content="Explore our Pune to Rajasthan Darshan Bus Package. Comfortable bus rentals to popular destinations like Jaipur, Jodhpur, and Mount Abu." />
    <meta name="keywords" content="Pune to Rajasthan Bus Package, Jaipur Bus on Rent, Jodhpur Bus on Rent, Mount Abu Bus Hire, Rajasthan Road Trip" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to Rajasthan Darshan Bus Package | Affordable Travel" />
    <meta property="og:description" content="Join our Pune to Rajasthan Darshan Bus Package for a memorable trip. Visit Jaipur, Jaisalmer, and more with comfortable travel options." />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-rajasthan-bus-package" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/rajasthan-bus-package.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pune to Rajasthan Darshan Bus Package",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-rajasthan-bus-package",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Pune to Rajasthan Darshan Bus Package",
                    "description": "Join us for a Pune to Rajasthan Darshan Bus Package. Visit Jaipur, Jodhpur, Mount Abu, and more in comfort and style."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 3-11.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs Pune to Rajasthan Darshan Bus Package for Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetorajashtan;