
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetobhimashnakarjyotilinga() {



  const cardData =
  {
    keyword: 'Pune to Bhimashankar Jyotirlinga Mini Bus Hire in Pune ',
    heading: 'Shraddha Travels: Pune to Bhimashankar Jyotirlinga Mini Bus Hire in Pune ',
    headingDescription: 'When planning a pilgrimage to Bhimashankar Jyotirlinga from Pune, choosing the right mode of transportation can make all the difference. At Shraddha Travels, we offer reliable and comfortable mini bus hire services for your journey to this revered destination. Here’s why our mini bus hire service is ideal for your trip:',
    top: 'Top Places to Visit in Bhimashankar with Shraddha Travels',

    topPlaces: [
        {
            title: "1. Bhimashankar Temple",
            description: "The main attraction, Bhimashankar Temple, is one of the twelve Jyotirlingas dedicated to Lord Shiva. This ancient temple is renowned for its intricate architecture and rich history, attracting pilgrims and tourists alike. The spiritual atmosphere, coupled with beautiful surroundings, makes it a must-visit for anyone exploring the region."
        },
        {
            title: "2. Nagphani Peak (Horn Peak)",
            description: "Nagphani Peak, also known as Horn Peak, offers breathtaking panoramic views of the Sahyadri mountain range. A popular trekking destination, it presents a challenging yet rewarding hike. Photographers will appreciate the stunning vistas, especially during sunrise and sunset when the landscape is bathed in golden light."
        },
        {
            title: "3. Bhimashankar Wildlife Sanctuary",
            description: "This rich natural reserve is home to diverse flora and fauna, including endangered species like the Indian Giant Squirrel. The sanctuary is perfect for nature enthusiasts and wildlife watchers, offering guided treks that allow visitors to explore its lush forests, waterfalls, and unique ecosystems."
        },
        {
            title: "4. Hanuman Lake",
            description: "Located near the Bhimashankar Temple, Hanuman Lake is a tranquil spot surrounded by dense forests and hills. It's ideal for relaxation and meditation, providing a serene atmosphere. Visitors can enjoy a peaceful day by the water, or explore the scenic trails that lead to the lake."
        },
        {
            title: "5. Pune to Bhimashankar Hill Station",
            description: "This area not only boasts scenic beauty but also offers a refreshing escape from the city's hustle and bustle. The cool weather, lush greenery, and peaceful ambiance make it perfect for a weekend getaway or a relaxing retreat. It’s a great spot for family picnics or romantic getaways."
        },
        {
            title: "6. Gupt Bhimashankar",
            description: "An ancient cave temple dedicated to Lord Shiva, Gupt Bhimashankar is shrouded in mystery and tranquility. With its historical significance and serene environment, it serves as a perfect pilgrimage site for devotees seeking solitude and peace away from the crowds."
        },
        {
            title: "7. Shidi Ghat",
            description: "Shidi Ghat is a picturesque location known for its scenic beauty and rich biodiversity. The area offers stunning views of the surrounding hills and valleys, making it a favorite spot for nature lovers. It’s also an excellent location for photography and picnicking amidst nature."
        },
        {
            title: "8. Tamhini Ghat",
            description: "Famous for its lush landscapes, waterfalls, and winding roads, Tamhini Ghat is a paradise for nature lovers. The monsoon season transforms this region into a breathtaking spectacle of greenery, making it an ideal destination for a scenic drive or a day of exploration."
        },
        {
            title: "9. Ghatghar Dam",
            description: "A popular site for picnics and leisure activities, Ghatghar Dam offers stunning views of the water and the surrounding hills. Visitors can enjoy boating, fishing, or simply relaxing by the water's edge. It's a great spot for families and friends to unwind and enjoy nature."
        },
        {
            title: "10. Khandas Village",
            description: "Khandas Village is known for its picturesque beauty and welcoming community. It serves as a great starting point for treks to nearby scenic spots and waterfalls. The village offers a glimpse into rural life in Maharashtra, making it a perfect spot for travelers seeking adventure and authenticity."
        }
    ],    
    "services": [
      {
          "name": "Pune to Bhimashankar Jyotirlinga Mini Bus Hire in Pune",
          "description": "Embark on a spiritually enriching journey to Bhimashankar, one of the 12 Jyotirlingas, with Shraddha Travels' premium mini bus hire service from Pune. Our well-maintained mini buses offer a comfortable and hassle-free travel experience, perfect for family and group trips. With spacious seating, air conditioning, and ample luggage space, our mini buses provide an ideal way to travel in comfort. Whether it's a short visit or an extended pilgrimage, we ensure that your journey to Bhimashankar is smooth and enjoyable."
      },
      {
          "name": "Spacious and Comfortable Mini Bus Hire for Bhimashankar",
          "description": "Travel in style and comfort with our spacious mini buses for hire from Pune to Bhimashankar Jyotirlinga. Designed to cater to groups of all sizes, our mini buses feature ample seating, air conditioning, and modern amenities that make your pilgrimage enjoyable. Whether you're traveling with family, friends, or a larger group, we offer a perfect blend of comfort and convenience."
      },
      {
          "name": "Reliable Mini Bus Hire Service for Bhimashankar Jyotirlinga",
          "description": "Our Pune to Bhimashankar Jyotirlinga mini bus hire service is known for its reliability. With experienced and professional drivers, we ensure a safe and punctual journey, so you can focus on your spiritual experience. Our fleet is regularly maintained to provide the best possible service, making us a trusted choice for your pilgrimage."
      },
      {
          "name": "Mini Bus on Rent for Bhimashankar Pilgrimage",
          "description": "Opt for a mini bus rental for your Bhimashankar pilgrimage from Pune. Our fleet of comfortable mini buses is perfect for groups, offering a safe and enjoyable ride to one of the most sacred Jyotirlingas in India. With comfortable interiors, ample space, and air-conditioning, your journey to Bhimashankar will be both pleasant and stress-free."
      },
      {
          "name": "Affordable Pune to Bhimashankar Mini Bus Hire",
          "description": "We offer affordable mini bus hire services from Pune to Bhimashankar, ensuring a budget-friendly yet comfortable travel experience. Our mini buses are equipped with everything you need for a comfortable ride, from cozy seating arrangements to air conditioning. Enjoy a memorable journey to Bhimashankar without breaking the bank."
      },
      {
          "name": "Pune to Bhimashankar Mini Bus Hire for Group Travel",
          "description": "Our Pune to Bhimashankar mini bus hire service is perfect for group travel. Whether you're organizing a family trip or a pilgrimage with friends, our mini buses provide ample space and comfort for all passengers. With a variety of sizes available, you can choose the perfect bus to suit your group size and needs."
      },
      {
          "name": "Professional Drivers for Pune to Bhimashankar Mini Bus Hire",
          "description": "All of our mini buses for hire come with experienced and professional drivers who know the best routes to Bhimashankar Jyotirlinga. Our drivers ensure a smooth and safe journey, giving you peace of mind throughout your pilgrimage. Trust Shraddha Travels to provide reliable transportation with drivers who prioritize your safety and comfort."
      },
      {
          "name": "Mini Bus Hire for Bhimashankar with Modern Amenities",
          "description": "Our Pune to Bhimashankar mini buses come equipped with modern amenities to make your journey as comfortable as possible. Enjoy features like reclining seats, air conditioning, ample luggage space, and entertainment options, ensuring a relaxing and enjoyable travel experience for your entire group."
      },
      {
          "name": "Hassle-Free Mini Bus Rental for Bhimashankar",
          "description": "Make your pilgrimage to Bhimashankar easy and stress-free with our hassle-free mini bus rental service from Pune. We handle all aspects of the journey, from booking to arrival, allowing you to focus on your spiritual experience. Enjoy a comfortable, safe, and reliable trip to Bhimashankar with Shraddha Travels."
      },
      {
          "name": "Customized Mini Bus Packages for Bhimashankar",
          "description": "At Shraddha Travels, we offer customized mini bus packages for your trip to Bhimashankar Jyotirlinga. Choose from a range of options that suit your group's needs, including one-way trips, round trips, or multi-day packages. Our flexible service ensures a personalized travel experience, tailored to your preferences."
      },
      {
          "name": "Pune to Bhimashankar Jyotirlinga Mini Bus Hire Contact Number",
          "description": "For booking a mini bus from Pune to Bhimashankar Jyotirlinga, contact Shraddha Travels at +91 8087847191. Our dedicated team is ready to assist you with all your booking inquiries and provide information on availability, pricing, and the services we offer. Get in touch today to ensure a comfortable and reliable journey for your pilgrimage."
      }
  ],
    
    tableData: [
        ['- Pune to Bhimashankar Jyotirlinga Tempo Traveller', '- Pune to Bhimashankar 17 Seater Mini Bus on Rent'],
        ['- Pune to Bhimashankar Mini Bus on Rent', '- Pune to Jyotirlinga Bhimashankar Mini Bus on Rent'],
        ['- 14 Seater AC Tempo Traveller On Rent in Pune', '- Pune to Bhimashankar Round Trip Bus on Rent'],
        ['- Pune to 5 Jyotirlinga Darshan Mini Bus On Rent', '- Pune to Bhimashankar AC Mini Bus On Rent'],
        ['- Best Bus Hire Pune to Bhimashankar', '- Pune to Bhimashankar Bus Tour Package']
    ],
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Pune to Bhimashankar Jyotirlinga Mini Bus Hire?",
            WhyChoosedescription: "When planning a journey to the sacred Bhimashankar Jyotirlinga, choosing the right transportation is crucial. Shraddha Travels offers not just a ride but an experience tailored to your spiritual needs, ensuring comfort and peace throughout your pilgrimage."
        },
        {
            WhyChooseheading: "Comfortable and Well-Maintained Fleet:",
            WhyChoosedescription: "Our mini buses are equipped with comfortable seating, ample legroom, and air-conditioning, ensuring a pleasant journey, especially during long trips. Each vehicle undergoes regular maintenance checks to keep it in optimal condition, providing you with a reliable mode of transport for your sacred journey."
        },
        {
            WhyChooseheading: "Experienced and Professional Drivers:",
            WhyChoosedescription: "Our drivers are highly trained and experienced, familiar with the route to Bhimashankar. They are dedicated to providing a safe and smooth journey, allowing you to focus on your spiritual experience without worrying about road conditions or navigation."
        },
        {
            WhyChooseheading: "Competitive Pricing:",
            WhyChoosedescription: "We offer transparent and competitive pricing with no hidden fees. Our goal is to provide you with the best value for your money, ensuring that your pilgrimage is not only meaningful but also cost-effective."
        },
        {
            WhyChooseheading: "Customizable Travel Plans:",
            WhyChoosedescription: "Understanding that every trip is unique, our services can be tailored to fit your specific requirements. Whether it’s a round trip, additional stops for sightseeing, or special requests for meals, we work with you to create the perfect travel itinerary."
        },
        {
            WhyChooseheading: "Reliable and Punctual Service:",
            WhyChoosedescription: "We pride ourselves on our punctuality and reliability. Our team ensures that your mini bus arrives on time, allowing you to maintain your planned itinerary without delays, so you can fully immerse yourself in the spiritual experience."
        },
        {
            WhyChooseheading: "Hassle-Free Booking Process:",
            WhyChoosedescription: "Booking with us is simple and straightforward. You can easily make reservations through our user-friendly website, by phone, or via email. Our customer support team is always available to assist with any inquiries, ensuring a smooth and easy booking experience."
        },
        {
            WhyChooseheading: "Comprehensive Support:",
            WhyChoosedescription: "From the moment you book until the end of your journey, our customer support team is dedicated to assisting you. We are committed to providing exceptional service and promptly addressing any concerns you may have, ensuring a stress-free experience."
        },
        {
            WhyChooseheading: "Clean and Hygienic Vehicles:",
            WhyChoosedescription: "We prioritize cleanliness and hygiene in all our vehicles. Regular cleaning and sanitation processes ensure a safe and pleasant environment for all passengers, making your journey comfortable and worry-free."
        },
        {
            WhyChooseheading: "Flexibility and Convenience:",
            WhyChoosedescription: "Our mini bus services are designed to offer maximum flexibility. Whether you’re traveling with a small group or a larger family, we can accommodate your needs with the right vehicle and services, making your journey convenient and enjoyable."
        },
        {
            WhyChooseheading: "Positive Customer Feedback:",
            WhyChoosedescription: "We take pride in our track record of satisfied customers who have praised our reliable service and comfortable travel experiences. Their positive testimonials reflect our commitment to excellence and dedication to making your journey memorable."
        }
    ]
    
  }



  const faqData = [
    {
        question: "What is included in the mini bus hire for a trip to Bhimashankar?",
        answer: "Our mini bus hire includes comfortable seating, air-conditioning, and professional driver services. Additional amenities such as Wi-Fi or audio systems may be available upon request."
    },
    {
        question: "How do I book a mini bus for a trip to Bhimashankar?",
        answer: "You can book a mini bus by contacting us via phone, email, or through our online booking form. Provide details such as travel dates, group size, and any special requirements to facilitate your booking."
    },
    {
        question: "Are there any extra charges for mini bus hire?",
        answer: "Our pricing is transparent, with no hidden fees. However, extra charges may apply for extended travel times, additional services, or special requests. We will provide a detailed quote before finalizing your booking."
    },
    {
        question: "Can I request a specific type of mini bus?",
        answer: "Yes, you can request specific features or types of mini buses based on availability. We offer various options to meet different needs, including luxury and standard models."
    },
    {
        question: "What is the cancellation policy for mini bus hire?",
        answer: "Our cancellation policy depends on the timing of the cancellation. Generally, cancellations made well in advance incur lower fees, while last-minute cancellations may involve higher charges. Contact our customer service for specific details."
    },
    {
        question: "Are the drivers trained and licensed?",
        answer: "Yes, all our drivers are professionally trained, experienced, and hold valid licenses. They are familiar with the route to Bhimashankar and are dedicated to ensuring a safe and comfortable journey."
    },
    {
        question: "Can I make changes to my booking after it’s confirmed?",
        answer: "Yes, changes can be made subject to availability and applicable fees. Contact our customer service team as soon as possible to discuss any modifications to your booking."
    },
    {
        question: "What should I do if I need assistance during the journey?",
        answer: "If you need assistance during your trip, please contact the driver or our customer service team immediately. We are committed to addressing any concerns or issues promptly."
    },
    {
        question: "How far in advance should I book a mini bus for Bhimashankar?",
        answer: "To secure your preferred vehicle and ensure availability, we recommend booking as early as possible. For peak seasons or special events, booking well in advance is advisable."
    },
    {
        question: "Are there any restrictions on luggage?",
        answer: "Standard luggage allowances apply, but we can accommodate additional baggage or special items upon request. Inform us of any specific needs when making your booking to ensure proper arrangements."
    }
];



const testimonials = [
    {
        name: "Ms. Neeta Desai",
        text: "We hired a mini bus from Shraddha Travels for our family trip to Bhimashankar, and it was a fantastic experience! The bus was spacious and comfortable, and our driver was very professional. Highly recommend their services!"
    },
    {
        name: "Mr. Akshay Yadav",
        text: "Shraddha Travels made our pilgrimage to Bhimashankar so much easier. The mini bus was perfect for our family, and we felt safe and relaxed throughout the journey. Thank you for the wonderful service!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Pune to Bhimashankar Jyotirlinga Mini Bus Hire in Pun Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune to Bhimashankar Jyotirlinga Mini Bus Hire in Pune | Affordable Pilgrimage Travel</title>
    <meta name="description" content="Rent a mini bus from Pune to Bhimashankar Jyotirlinga for a comfortable and affordable pilgrimage. Explore the temple with group travel options at competitive rates." />
    <meta name="keywords" content="Pune to Bhimashankar Jyotirlinga Mini Bus Hire, Bhimashankar Jyotirlinga Mini Bus, Pune to Bhimashankar Bus Hire, Mini Bus Rental Pune, Affordable Mini Bus for Bhimashankar, Bus Rentals for Bhimashankar Jyotirlinga" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to Bhimashankar Jyotirlinga Mini Bus Hire in Pune | Affordable Pilgrimage Travel" />
    <meta property="og:description" content="Book a mini bus from Pune to Bhimashankar Jyotirlinga for a peaceful and comfortable pilgrimage. Affordable and group-friendly travel options available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-to-bhimashankar-mini-bus-hire" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/bhimashankar-mini-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pune to Bhimashankar Jyotirlinga Mini Bus Hire",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-to-bhimashankar-mini-bus-hire",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Mini Bus Rental for Pune to Bhimashankar",
                    "description": "Rent a mini bus for a comfortable pilgrimage from Pune to Bhimashankar Jyotirlinga, ideal for group travel."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `} 
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-25.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>Frequently Asked Questions (FAQs) About Pune to Bhimashankar Jyotirlinga Mini Bus Hire</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetobhimashnakarjyotilinga;