
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Busrentalfortour() {



  const cardData =
  {
    keyword: ' Bus Rental Options for Tour Packages in Pune',
    heading: 'Shraddha Travels: Bus Rental Options for Tour Packages in Pune',
    headingDescription: 'Are you gearing up for an exciting tour in and around Pune? Look no further than Shraddha Travels, your trusted partner for exceptional bus rental services tailored to various tour packages. With our commitment to comfort and quality, we ensure an enjoyable travel experience for every journey. Here’s a guide to the top bus rental options available with Shraddha Travels for your tour packages in Pune:',
    top: 'Top Reasons to Bus Rental for Tour Packages in Pune with Shraddha Travels:',

    topPlaces: [
        {
            title: "1. Luxury Coaches for Exclusive Tours",
            description: "Shraddha Travels offers luxury coaches designed for those who seek the best in comfort and style. Equipped with plush seating, air conditioning, and advanced entertainment systems, these coaches are perfect for high-end tours and special events, ensuring that you and your guests travel in utmost luxury. Whether it’s a wedding, corporate retreat, or a special occasion, our luxury coaches provide a sophisticated atmosphere with amenities like onboard refreshments and personalized service."
        },
        {
            title: "2. Comfortable Mini Buses for Small Groups",
            description: "Our comfortable mini buses, accommodating 14 to 25 passengers, are perfect for intimate tours. With a focus on coziness and convenience, these mini buses provide a snug travel experience, making them ideal for family outings or close-knit group adventures. Each mini bus is designed with comfortable seating and ample legroom, ensuring that everyone can relax and enjoy the ride, whether you’re heading to a local attraction or a nearby destination."
        },
        {
            title: "3. Spacious 32-Seater Buses for Family Tours",
            description: "Planning a family vacation? Our 32-seater buses offer ample space for larger families or groups. Enjoy a roomy interior with comfortable seating and plenty of storage for luggage, ensuring a relaxed travel experience for everyone. With features like air conditioning and entertainment options, these buses make family trips enjoyable, allowing you to create lasting memories on the road."
        },
        {
            title: "4. Versatile 35-Seater Buses for Medium-Sized Groups",
            description: "For corporate outings or medium-sized excursions, our versatile 35-seater buses strike the perfect balance between space and comfort. These buses cater to diverse group sizes and are well-suited for various activities, from day trips to multi-day tours. Equipped with modern amenities, including comfortable seating and onboard entertainment, our 35-seater buses ensure a pleasant journey while accommodating all your group’s needs."
        },
        {
            title: "5. Large 40-Seater Buses for Extended Tours",
            description: "When traveling with larger groups, Shraddha Travels’ 40-seater buses provide generous seating capacity, ideal for extended tours or corporate events. With comfortable seating and a focus on passenger comfort, everyone can enjoy the journey together. Our buses are designed to make long trips enjoyable, featuring onboard entertainment systems and spacious interiors that keep everyone entertained and comfortable throughout the ride."
        },
        {
            title: "6. 50-Seater Buses for Grand Events",
            description: "Need to transport a big group? Our 50-seater buses are designed for grand events, large family gatherings, and other occasions that require substantial seating capacity. Enjoy spacious interiors and reliable transportation for your entire group. These buses are equipped with all the necessary amenities to ensure a smooth journey, allowing you to focus on your event without worrying about logistics."
        },
        {
            title: "7. Customizable Tour Packages with Private Buses",
            description: "We understand that every group has unique needs. Shraddha Travels offers customizable tour packages where you can select the type of bus that best fits your itinerary, whether it’s for local sightseeing tours or outstation trips. Our team works closely with you to create a tailored experience, ensuring that every aspect of your journey aligns with your preferences, from pick-up times to routes."
        },
        {
            title: "8. Eco-Friendly Bus Options",
            description: "In line with our commitment to sustainability, Shraddha Travels provides eco-friendly bus options that reduce environmental impact without compromising comfort. Enjoy your travels while contributing to a greener planet. Our eco-friendly fleet is designed to minimize emissions and includes buses equipped with the latest green technologies, ensuring a sustainable travel experience for eco-conscious travelers."
        },
        {
            title: "9. On-Demand Luxury and Executive Bus Services",
            description: "For those desiring a more premium travel experience, our on-demand luxury and executive bus services feature high-end amenities, including reclining seats, Wi-Fi, and refreshments, ensuring a first-class journey. Ideal for business executives or special occasions, our luxury buses provide a refined travel environment where you can work or relax as needed."
        },
        {
            title: "10. Reliable and Safe Bus Rentals for Family Tours",
            description: "Safety and reliability are our top priorities. Our well-maintained fleet ensures a secure travel experience for families, with experienced drivers and regular maintenance checks for peace of mind during your journeys. Each bus is equipped with safety features and is cleaned and sanitized before every trip, ensuring that families can travel comfortably and confidently to their destinations."
        }
    ],    
    services: [
        {
            name: "Bus Rental for Tour Package: Pune to Lonavala",
            description: "When planning your getaway to Lonavala from Pune, Shraddha Travels offers exceptional bus rental services tailored to your tour package. Our comfortable and spacious buses ensure a smooth ride as you soak in the beautiful scenery of the Western Ghats. With plush seating and modern amenities, you can relax and enjoy the journey with family or friends. Whether you're heading for a day trip to explore the lush landscapes or an extended stay to enjoy the local attractions, our fleet is designed to meet your needs. Book your bus today and embark on a memorable journey filled with picturesque views and delightful experiences!"
        },
        {
            name: "Bus Rental for Tour Packages: Pune to Lavasa City",
            description: "Lavasa City, known for its stunning architecture and serene landscapes, is a perfect destination for a tour. Shraddha Travels provides reliable bus rental services from Pune to Lavasa City, ensuring a comfortable travel experience. Our modern buses are equipped with all the amenities you need for a pleasant journey, including air conditioning and entertainment systems. Enjoy a hassle-free ride while you admire the beautiful lakes and hills that Lavasa offers. Explore Lavasa’s beauty with us, whether it’s for a family outing, a corporate retreat, or a leisurely day trip!"
        },
        {
            name: "Bus Rental for Tour Package: Pune to Mumbai",
            description: "Traveling from Pune to Mumbai has never been easier. Shraddha Travels offers efficient bus rental services that cater to both small and large groups. Our well-maintained buses provide a comfortable and relaxing environment, allowing you to unwind before arriving in the bustling city. Enjoy a hassle-free journey with our professional drivers who ensure your safety and comfort throughout the trip. Whether you’re visiting for business, sightseeing, or special events, you can focus on your plans while we handle the transportation. Experience the convenience of travel with Shraddha Travels and make the most of your Mumbai visit!"
        },
        {
            name: "Bus Rental for Tour Package: Pune to Nashik",
            description: "Nashik, a city rich in culture and heritage, awaits your exploration. Shraddha Travels provides comfortable bus rental services for tours from Pune to Nashik. Our spacious buses ensure that you and your group can travel together while enjoying the journey, with amenities designed for your comfort. Discover Nashik’s famous vineyards, temples, and historical sites without the stress of transportation logistics. Whether it’s a day trip or a longer stay, book your Nashik tour with us today and experience the vibrant culture and stunning landscapes of this beautiful city!"
        },
        {
            name: "Bus on Rent: Pune to Tuljapur",
            description: "For those planning a pilgrimage to Tuljapur, Shraddha Travels offers reliable bus rental services. Our comfortable buses accommodate groups of all sizes, ensuring a smooth journey to this important pilgrimage destination. With a focus on passenger comfort, our buses come equipped with all necessary amenities for your convenience. Experience the spiritual ambiance of Tuljapur with peace of mind, knowing that your transportation is taken care of. Join us for a meaningful journey that connects you to your faith and provides a seamless travel experience."
        },
        {
            name: "Bus on Rent: Pune to Pandharpur",
            description: "For those planning a spiritual visit to Pandharpur, Shraddha Travels offers reliable bus rental services. Our comfortable buses accommodate groups of all sizes, ensuring a smooth journey to this important pilgrimage destination. With experienced drivers who know the best routes, we guarantee a safe and pleasant trip. Enjoy the scenic views along the way while you prepare for your spiritual experience. Travel with us to Pandharpur and focus on your pilgrimage without worrying about the logistics of your journey."
        },
        {
            name: "Shraddha Travels Pune",
            description: "Shraddha Travels, a name synonymous with reliability and comfort, offers excellent bus rental services in Pune. We prioritize customer satisfaction, ensuring that your travel experience is nothing short of exceptional. Our diverse fleet can accommodate various needs, whether it’s for leisure trips, corporate events, or family outings. With a commitment to safety, punctuality, and top-notch service, we make sure every journey is enjoyable. Choose Shraddha Travels for your next trip and experience the difference in quality and service."
        },
        {
            name: "AC/Non-AC Bus for Outstation",
            description: "Whether you prefer an AC or non-AC bus for your outstation trips, Shraddha Travels has you covered. Our well-equipped buses cater to all your travel preferences, ensuring a comfortable journey every time. Each vehicle is regularly maintained for optimal performance, providing you with a safe and enjoyable experience. Whether it’s a weekend getaway or an extended holiday, you can trust us to deliver quality service that meets your expectations. Enjoy your travels with Shraddha Travels and make lasting memories on the road!"
        },
        {
            name: "Bus Rental for Corporate Events in Pune",
            description: "For corporate events in Pune, Shraddha Travels provides specialized bus rental services to ensure smooth transportation for your business needs. Our reliable and punctual services make us the preferred choice for companies looking to facilitate employee travel. Whether it's a conference, team-building event, or client meetings, we offer tailored solutions to accommodate your group size and schedule. With comfortable seating and amenities to keep everyone relaxed and focused, we ensure your corporate events run smoothly from start to finish."
        },
        {
            name: "Bus Rental for Ashtavinayak Tour from Pune",
            description: "Join us for the Ashtavinayak pilgrimage with our dedicated bus rental services. Shraddha Travels provides tailored transportation for this sacred tour, ensuring you visit each site comfortably and conveniently. Our experienced drivers are well-versed in the routes, allowing you to relax and immerse yourself in the spiritual journey. With spacious seating and necessary amenities on board, you can enjoy the sacred experience without worrying about travel logistics. Book your Ashtavinayak tour with us for an enriching spiritual adventure!"
        },
        {
            name: "Bus Hire for Jyotirlinga Darshan from Pune",
            description: "For an enlightening journey to the Jyotirlingas, Shraddha Travels offers specialized bus hire services. Our comfortable buses are ideal for groups seeking to explore these holy sites with ease and comfort. With knowledgeable drivers who understand the significance of each location, we provide a meaningful travel experience. Our buses come equipped with modern amenities to ensure your comfort during the journey, making it perfect for spiritual seekers and devotees alike."
        },
        {
            name: "Mini Bus on Rent in Pune",
            description: "Shraddha Travels provides mini bus rentals in Pune for small groups. Our cozy and comfortable mini buses are perfect for local tours or shorter trips, ensuring a delightful travel experience. With flexible seating arrangements and essential amenities, our mini buses are great for family outings, corporate meetings, or day trips with friends. Enjoy the convenience of traveling together and make every moment special as you explore Pune and its surroundings."
        },
        {
            name: "AC 17-Seater Bus on Rent in Pune",
            description: "Looking for a comfortable option for family outings or small group gatherings? Our AC 17-seater buses are perfect for your travel needs, providing ample space and comfort. Ideal for trips to tourist attractions, weddings, or family reunions, these buses ensure a pleasant journey with modern amenities. Enjoy the flexibility of traveling together while experiencing a cozy atmosphere that enhances your travel experience."
        },
        {
            name: "Best Bus Rental Services in Pune for Tour Packages",
            description: "Shraddha Travels is known for providing the best bus rental services in Pune. Our commitment to quality and customer satisfaction makes us the ideal choice for your tour packages. We offer a wide range of buses equipped with modern amenities to suit various group sizes and travel requirements. With a focus on safety, comfort, and reliability, we ensure every journey is enjoyable and memorable. Trust us to be your partner in exploring new destinations!"
        },
        {
            name: "Tourist Luxury Bus on Rent in Pune",
            description: "For those seeking luxury on the road, Shraddha Travels offers tourist luxury bus rentals in Pune. Experience the ultimate comfort and style during your travels with our premium bus options. Our luxury buses come with plush seating, advanced entertainment systems, and top-notch amenities to ensure a first-class travel experience. Perfect for special occasions, corporate events, or extravagant outings, we make every journey feel extraordinary."
        },
        {
            name: "Tourist Bus Hire in Pimpri Chinchwad",
            description: "Shraddha Travels provides excellent tourist bus hire services in Pimpri Chinchwad, ensuring your group travels comfortably and conveniently to various destinations. Our diverse fleet is well-equipped for all types of travel, whether for school trips, family outings, or corporate events. With our professional drivers and commitment to punctuality, you can count on us for a seamless travel experience."
        },
        {
            name: "Best Tourist Bus Hire in Pimpri Chinchwad for Family",
            description: "Our tourist bus hire services in Pimpri Chinchwad are tailored for families, offering comfortable and spacious buses ideal for family outings and gatherings. We understand the importance of creating cherished memories, and our services ensure a smooth journey that allows everyone to enjoy the experience together. From day trips to longer excursions, we prioritize family comfort and safety."
        },
        {
            name: "Outstation Tourist Bus Hire in Pune",
            description: "For your outstation adventures, Shraddha Travels offers reliable tourist bus hire services. Our buses are equipped for long-distance travel, ensuring a comfortable experience no matter where you're headed. Whether it’s a trip to hill stations, historical sites, or popular destinations, we provide the necessary amenities for an enjoyable journey. Rely on us for punctuality and safety as you explore new places."
        },
        {
            name: "Outstation Tourist Bus Hire in Pune Rates",
            description: "At Shraddha Travels, we provide transparent pricing for our outstation tourist bus hire services in Pune. Enjoy competitive rates without compromising on quality and comfort. Our pricing structure is straightforward, with no hidden costs, ensuring you get the best value for your money. Experience great service and comfortable travel while keeping your budget in check."
        },
        {
            name: "Pune to 12 Jyotirlinga Bus Tour Package",
            description: "Join our exclusive bus tour package from Pune to the 12 Jyotirlingas. Shraddha Travels offers a comfortable and organized travel experience, ensuring you can focus on your spiritual exploration without any hassle. Our well-planned itinerary includes visits to all significant sites, and our attentive staff will ensure all your needs are met throughout the journey. Book with us for a transformative spiritual journey that connects you with your faith."
        },
        {
            name: "Bus Rental for Tour Packages in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Bus Rental for Tour Packages in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Whether you have questions about our services, need assistance with planning your trip, or want to make a reservation, we are here to help. Experience the convenience and reliability of our service today!"
        }
    ],    
    tableData: [
        ['- Bus Rental for Tour Package: Pune to Lonavala', '- Bus Rental for Tour Package: Pune to Lavasa City'],
        ['- Bus Rental for Tour Package: Pune to Mumbai', '- Bus Rental for Tour Package: Pune to Nashik'],
        ['- Bus on Rent: Pune to Tuljapur', '- Bus on Rent: Pune to Pandharpur'],
        ['- AC/Non-AC Bus for Outstation', '- Bus Rental for Corporate Events in Pune'],
        ['- Bus Rental for Ashtavinayak Tour from Pune', '- Bus Hire for Jyotirlinga Darshan from Pune'],
        ['- Mini Bus on Rent in Pune', '- AC 17-Seater Bus on Rent in Pune'],
        ['- Best Bus Rental Services in Pune for Tour Packages', '- Tourist Luxury Bus on Rent in Pune'],
        ['- Tourist Bus Hire in Pimpri Chinchwad', '- Best Tourist Bus Hire in Pimpri Chinchwad'],
        ['- Best Tourist Bus Hire in Pimpri Chinchwad for Family', '- Outstation Tourist Bus Hire in Pune'],
        ['- Outstation Tourist Bus Hire in Pune Rates', '- Pune to 12 Jyotirlinga Bus Tour Package']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Us for Bus Rental for Tour Packages in Pune?",
            WhyChoosedescription: "At Shraddha Travels, we are committed to providing exceptional bus rental services tailored to your needs. With years of experience in the travel industry, our focus on comfort, safety, and customer satisfaction ensures that your journey is enjoyable and stress-free. We understand that traveling can be a significant investment, and we strive to make every trip memorable for our clients."
        },
        {
            WhyChooseheading: "Diverse Fleet:",
            WhyChoosedescription: "We offer a wide range of buses suitable for various group sizes and preferences. Whether you need a luxury coach for a high-end tour or a cozy mini bus for family outings, our diverse fleet has something for everyone. Each vehicle is selected based on comfort, reliability, and the latest safety features, giving you peace of mind as you travel."
        },
        {
            WhyChooseheading: "Comfort and Convenience:",
            WhyChoosedescription: "Our buses come equipped with modern amenities such as plush seating, air conditioning, and entertainment systems to ensure a pleasant travel experience for all passengers. With spacious interiors and plenty of legroom, you can relax and enjoy the scenic views as you journey to your destination. We believe that comfort is key to a great travel experience."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedescription: "Safety is our top priority. Our professional drivers are not only well-trained but also have extensive knowledge of the best routes and local traffic conditions. This ensures a safe and smooth journey for all passengers, allowing you to focus on enjoying your trip rather than worrying about the road."
        },
        {
            WhyChooseheading: "Customizable Packages:",
            WhyChoosedescription: "We understand that every group has unique needs, which is why we offer customizable tour packages. You can tailor your itinerary to fit your interests and schedule, whether it’s for sightseeing, corporate outings, or family gatherings. Our team is here to help you design the perfect trip that meets your specific requirements."
        },
        {
            WhyChooseheading: "Competitive Pricing:",
            WhyChoosedescription: "Our rates are affordable, ensuring that you receive quality service without any hidden fees. We believe in transparency in pricing, which allows you to budget effectively for your travel. With us, you can enjoy exceptional service and comfort without breaking the bank."
        },
        {
            WhyChooseheading: "Excellent Customer Service:",
            WhyChoosedescription: "Our dedicated customer service team is available to assist you at every step, from booking to the completion of your tour. We are committed to ensuring that all queries are addressed promptly and thoroughly, providing you with the support you need for a smooth travel experience. Our goal is to exceed your expectations in every way."
        },
        {
            WhyChooseheading: "Timely Services:",
            WhyChoosedescription: "Punctuality is essential for a successful trip. We guarantee timely departures and arrivals, helping you make the most of your tour. Our commitment to on-time service means you can relax and enjoy your journey without the stress of delays or last-minute changes."
        },
        {
            WhyChooseheading: "Positive Reviews:",
            WhyChoosedescription: "We take pride in our satisfied customers. Our commitment to excellence has earned us numerous positive reviews and testimonials. Check out what fellow travelers have to say about their experiences with Shraddha Travels, and discover why we are the preferred choice for bus rentals in Pune."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What types of buses are available for rental?",
        answer: "We offer a diverse fleet, including luxury coaches, mini buses, 32-seater, 35-seater, 40-seater, and 50-seater buses, to accommodate different group sizes and preferences."
    },
    {
        question: "Can I customize my tour package?",
        answer: "Absolutely! We provide customizable tour packages that allow you to tailor your itinerary based on your interests and travel plans."
    },
    {
        question: "Are your buses well-maintained?",
        answer: "Yes, all our buses undergo regular maintenance checks to ensure they are in excellent condition for safe and comfortable travel."
    },
    {
        question: "How do I book a bus rental?",
        answer: "You can easily book a bus rental by contacting our customer service team via phone or through our website. We'll guide you through the process and assist you with your requirements."
    },
    {
        question: "Do you provide drivers for the buses?",
        answer: "Yes, all our rentals include experienced drivers who are knowledgeable about the routes and ensure a safe journey."
    },
    {
        question: "What are your payment options?",
        answer: "We offer various payment options, including online payments and cash, for your convenience. Detailed payment information will be provided during the booking process."
    },
    {
        question: "Is there a minimum rental period?",
        answer: "Yes, there may be a minimum rental period depending on the type of tour and distance. Please contact us for specific details."
    },
    {
        question: "Can I make changes to my booking after it’s confirmed?",
        answer: "We understand that plans can change. Please contact us as soon as possible if you need to make changes to your booking, and we will do our best to accommodate your request."
    },
    {
        question: "What is your cancellation policy?",
        answer: "Our cancellation policy varies depending on the booking type. Please refer to our terms and conditions or contact our customer service team for detailed information."
    },
    {
        question: "Are there any additional charges I should be aware of?",
        answer: "We strive for transparency in our pricing. Any additional charges, if applicable, will be communicated clearly at the time of booking."
    }
];



const testimonials = [
    {
        name: "Mr. Rahul K.",
        text: "We had an amazing experience with Shraddha Travels for our recent family trip from Pune to Lonavala. The bus was clean and comfortable, and our driver was not only professional but also friendly and accommodating. He ensured our journey was smooth and enjoyable. The booking process was simple, and the team was very helpful in customizing our tour package. I highly recommend Shraddha Travels for anyone looking for reliable bus rental services in Pune!"
    },
    {
        name: "Miss Anita Panday",
        text: "I booked a bus rental for a corporate outing to Lavasa with Shraddha Travels, and I couldn't be happier with the service. The bus was spacious and well-maintained, perfect for our team of 40. Our driver was punctual and knowledgeable about the route, making the trip stress-free. The customer service was outstanding, answering all our queries promptly. If you're planning a tour in Pune, Shraddha Travels is the way to go!"
    }
];



  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Bus Rentals For School Trips in Pune Contact Number: ",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus Rental for Tour Packages in Pune | Affordable Travel Solutions</title>
    <meta name="description" content="Explore Pune and nearby attractions with our reliable bus rental services for various tour packages." />
    <meta name="keywords" content="Bus Rental for Tour Packages in Pune, Lonavala Bus Hire, Nashik Tour Bus, Luxury Tourist Bus, Outstation Bus Rentals" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus Rental for Tour Packages in Pune | Affordable Travel Solutions" />
    <meta property="og:description" content="Reliable bus rental services for various tour packages from Pune to Lonavala, Nashik, and more." />
    <meta property="og:url" content="https://www.amuratatravels.com/bus-rental-for-tour-packages-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/tour-package-bus-rental.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Rental for Tour Packages",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/bus-rental-for-tour-packages-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental for Tour Packages in Pune",
                    "description": "Explore Pune and nearby attractions with our reliable bus rental services for various tour packages."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-12.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>Bus Rentals For School Trips in Pune Contact Number: </h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Busrentalfortour;