
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Bushirenearme() {



  const cardData =
  {
    keyword: ' Bus Hire Near me',
    heading: 'Shraddha Travels: Bus Hire Near me',
    headingDescription: 'Shraddha Travels is your premier choice for local bus rentals in Pune, offering a variety of options to suit all your transportation needs. Whether you’re organizing a family outing, a corporate event, or a school trip, our local buses provide comfort, reliability, and convenience. Here are the top local bus rental options from Shraddha Travels that ensure a seamless travel experience across Pune.',

    topPlaces: [
        {
          title: "1. Standard 20-Seater Bus",
          description: "Perfect for small to medium-sized groups traveling within Pune, our standard 20-seater bus is designed for comfort and convenience. It features comfortable seating, ample luggage space, and essential amenities to ensure a pleasant and efficient journey for family gatherings, team outings, or local tours. With easy access and a friendly atmosphere, this bus is ideal for short trips around the city."
        },
        {
          title: "2. 30-Seater Bus",
          description: "Ideal for larger groups, our 30-seater bus offers ample space and comfort for extended local trips in Pune. It includes comfortable seating arrangements, modern entertainment options, and sufficient storage for your belongings. Whether you’re planning a corporate event, school excursion, or a family reunion, this bus provides a reliable and enjoyable travel solution."
        },
        {
          title: "3. 32-Seater Bus",
          description: "For groups needing a bit more room, our 32-seater bus is an excellent choice. This bus is well-suited for local travel in Pune, offering extra seating and comfort for group events, family reunions, or community activities. With a spacious interior and modern facilities, it ensures a relaxed journey for everyone on board."
        },
        {
          title: "4. 35-Seater Bus",
          description: "The 35-seater bus from Shraddha Travels is designed for bigger groups who require spacious seating and comfort. Equipped with modern amenities and a reliable sound system, it is perfect for group travel across Pune, ensuring a smooth and enjoyable journey for various local events, functions, or outings."
        },
        {
          title: "5. 40-Seater Bus",
          description: "Our 40-seater bus is ideal for large groups traveling around Pune. With ample seating and modern amenities, it provides a comfortable and efficient solution for corporate events, school trips, and large family outings. The bus is designed for easy access and offers a safe, enjoyable ride for all passengers."
        },
        {
          title: "6. 50-Seater Bus",
          description: "For the largest groups, our 50-seater bus provides exceptional space and comfort. Whether you’re organizing a major corporate event or a large community trip within Pune, this bus accommodates all your needs with ease and reliability. It features plush seating, air conditioning, and ample luggage capacity, making it perfect for long journeys."
        },
        {
          title: "7. Mini Bus (15-Seater)",
          description: "The 15-seater mini bus is perfect for smaller groups or more intimate gatherings. Ideal for local travel within Pune, this mini bus offers a cozy and convenient option for short trips, small corporate events, or family outings. Its compact size allows for easier navigation through city traffic while providing comfort for passengers."
        },
        {
          title: "8. Luxury Bus",
          description: "For those seeking premium comfort, our luxury bus rental option delivers an exceptional travel experience. Featuring high-end amenities such as plush seating, advanced entertainment systems, and a mini-fridge, this bus is perfect for special events, executive travel, or luxury group outings in Pune. Experience the best in comfort and style while traveling."
        },
        {
          title: "9. AC Bus",
          description: "Travel in comfort with our AC bus rental service. Designed for local trips in Pune, our air-conditioned buses provide a cool and comfortable environment, making your journey more enjoyable, especially during the warmer months. With spacious seating and climate control, passengers can relax and enjoy the ride."
        },
        {
          title: "10. Non-AC Bus",
          description: "For a budget-friendly option, our non-AC bus rental provides a practical solution for local travel in Pune. Ideal for groups looking to save on costs while still enjoying a reliable and comfortable ride, our non-AC buses are well-suited for a variety of local events. Despite being a more economical choice, they offer sufficient comfort and space for all passengers."
        }
      ],
      
      services: [
        {
          name: "Bus Rental in Pune",
          description: "Shraddha Travels is your premier provider for bus rental in Pune, dedicated to delivering exceptional service. Our extensive fleet features a variety of well-maintained buses suitable for numerous purposes, including corporate events, family outings, and school trips. Each bus is equipped with modern amenities such as comfortable seating, air conditioning, and entertainment options to ensure a pleasant journey. Our professional drivers prioritize your safety and comfort, making us the top choice for all your travel needs in Pune."
        },
        {
          name: "Bus Hire in Pune",
          description: "Looking for efficient and reliable bus hire in Pune? Shraddha Travels offers a diverse range of bus hire options tailored to meet your specific needs. Whether you're organizing a small gathering or a large event, our buses are perfect for both local and outstation travel. With a focus on comfort and dependability, we provide you with a seamless booking experience and a smooth journey, allowing you to focus on enjoying your event without worrying about logistics."
        },
        {
          name: "14, 17, 20, 25, 32 Seater Buses",
          description: "We provide a versatile range of bus sizes, including 14, 17, 20, 25, and 32-seater options. These buses are perfect for various group sizes and events, from intimate family outings to larger corporate gatherings. Each bus is designed with comfort in mind, ensuring ample space for passengers and their luggage. With Shraddha Travels, you can be confident that we have the right bus size to fit your needs, guaranteeing a comfortable and hassle-free experience from start to finish."
        },
        {
          name: "Pune Darshan Tempo Traveller on Rent",
          description: "Experience Pune’s top attractions with our tempo traveller rentals for Pune Darshan. Shraddha Travels offers comfortable and reliable tempo travellers, equipped with modern amenities, making them perfect for sightseeing tours and local explorations. Enjoy guided tours of the city’s historical landmarks, cultural sites, and popular destinations, all while traveling in comfort and style. Our knowledgeable drivers will ensure you don’t miss any of Pune’s hidden gems during your journey."
        },
        {
          name: "Pune to Sinhagad Fort Mini Bus on Rent",
          description: "Planning a trip to Sinhagad Fort? Shraddha Travels provides mini bus rentals that offer a convenient and comfortable journey from Pune to this historic site. Our mini buses are designed for group travel, providing ample space for passengers to relax during the trip. Experience the stunning views and rich history of Sinhagad Fort while enjoying a hassle-free ride. Our professional drivers are familiar with the best routes, ensuring you reach your destination safely and on time."
        },
        {
          name: "Pune to Jejuri Bus Hire",
          description: "Traveling from Pune to Jejuri? Shraddha Travels offers dedicated bus hire services for this route, providing a reliable and comfortable option for your pilgrimage or visit. Our buses are equipped with the necessary amenities to ensure a smooth journey, accommodating all your travel needs. With experienced drivers and a focus on customer satisfaction, we make your journey to Jejuri not just a trip, but an experience worth remembering."
        },
        {
          name: "Pune to Ranjangaon Ganpati Bus Hire",
          description: "For trips to Ranjangaon Ganpati, Shraddha Travels provides dedicated bus hire services. Our buses are specifically designed to handle group travel to this important religious site, ensuring a comfortable and convenient experience for all passengers. Whether you're going for a festival or a spiritual visit, our professional drivers will ensure you arrive safely and on schedule, making your pilgrimage as stress-free as possible."
        },
        {
          name: "Pune to Ketkawale Balaji Temple Bus on Rent",
          description: "Visit Ketkawale Balaji Temple effortlessly with our bus rental services. Shraddha Travels provides comfortable buses for this route, ensuring a smooth and enjoyable journey to one of Pune’s revered temples. Our drivers are familiar with the area, and our buses are equipped with amenities for your comfort. Experience the spiritual ambiance of the temple while enjoying a reliable and safe travel option."
        },
        {
          name: "Pune to Narayanpur Tempo Traveller on Rent",
          description: "If you’re heading to Narayanpur, consider renting a tempo traveller from Shraddha Travels. Our tempo travellers are ideal for group travel, offering comfort and reliability for your trip from Pune to Narayanpur. Equipped with spacious interiors and modern amenities, our tempo travellers ensure that your journey is as enjoyable as the destination, allowing you to create lasting memories with your group."
        },
        {
          name: "Pune to Morachi Chincholi Bus on Rent",
          description: "Shraddha Travels offers bus rentals for trips from Pune to Morachi Chincholi, a popular destination known for its peacock sanctuary. Our buses provide a comfortable and efficient travel option for exploring this unique attraction. Enjoy a delightful journey filled with scenic views, and let our experienced drivers take care of the route while you focus on enjoying your visit to this beautiful sanctuary."
        },
        {
          name: "Tempo Traveller on Rent for Local Trip",
          description: "For local trips within Pune, Shraddha Travels provides tempo travellers that offer a cozy and convenient travel solution. Ideal for medium-sized groups, our tempo travellers are perfect for city tours, family outings, and other local excursions. With comfortable seating and essential amenities, your group can travel together while enjoying the vibrant sights of Pune."
        },
        {
          name: "Mini Bus on Rent for Local Trip in Pune",
          description: "Choose our mini buses for local trips around Pune. Shraddha Travels offers mini buses equipped with comfortable seating and modern amenities, making them ideal for small group travels, local events, and day trips. Experience the flexibility and convenience of local travel with our well-maintained vehicles, designed to ensure a smooth ride for you and your companions."
        },
        {
          name: "Mini Bus on Rent in Pune",
          description: "Shraddha Travels provides mini bus rentals in Pune for various needs. Our mini buses are perfect for group travel within the city, offering a comfortable and efficient way to transport groups to their destinations. Whether you're planning a day trip, corporate event, or family gathering, our mini buses are equipped to provide a delightful travel experience."
        },
        {
          name: "Pune Darshan Tour Packages (1 Day)",
          description: "Explore Pune with our 1-day Pune Darshan tour packages. Shraddha Travels offers comprehensive tour packages that cover major attractions in Pune, ensuring a complete and enjoyable sightseeing experience within a single day. Our itineraries are designed to maximize your time in the city, allowing you to visit key landmarks while enjoying the convenience of organized travel."
        },
        {
          name: "Pune Darshan Package Bus Package",
          description: "Our Pune Darshan package includes bus rentals for a thorough city tour. With Shraddha Travels, you can explore Pune’s key attractions in comfort and style, benefiting from our organized and efficient bus tour packages. Experience the rich culture, history, and sights of Pune without the stress of navigation or parking."
        },
        {
          name: "Pune Local Bus on Rent Party Package",
          description: "For special events and parties in Pune, Shraddha Travels offers local bus rentals designed for celebration and convenience. Our party packages include well-equipped buses that ensure your event is memorable and enjoyable. With amenities for comfort and entertainment, your group can celebrate together while traveling in style."
        },
        {
          name: "Pune Tourist Local Bus Packages",
          description: "Shraddha Travels provides local bus packages for tourists visiting Pune. Our packages are designed to offer comprehensive and comfortable transportation options for tourists, making it easy to explore the city’s attractions with ease. With knowledgeable drivers and tailored itineraries, we ensure a delightful experience for every visitor."
        },
        {
          name: "Pune Local Bus on Rent Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Mini Bus On Rent in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today, and let us handle your transportation needs!"
        }
      ],      
      tableData: [
        ['- Bus Rental in Pune', '- Bus Hire in Pune'],
        ['- 14, 17, 20, 25, 32 Seater', '- Shraddha Travels Pune'],
        ['- Pune Darshan Tempo Traveller on Rent', '- Pune to Sinhagad Fort Mini Bus on Rent'],
        ['- Pune to Jejuri Bus Hire', '- Pune to Ranjangaon Ganpati Bus Hire'],
        ['- Bus Hire in Pune', '- Bus Rental in Pune'],
        ['- Pune to Ketkawale Balaji Temple Bus on Rent', '- Pune to Narayanpur Tempo Traveller on Rent'],
        ['- Pune to Morachi Chincholi Bus on Rent', '- Tempo Traveller on Rent for Local Trip'],
        ['- Mini Bus on Rent for Local Trip in Pune', '- Mini Bus on Rent in Pune'],
        ['- Pune Darshan Tour Packages (1 Day)', '- Pune Darshan Package Bus Package'],
        ['- Pune Local Bus on Rent Party Package', '- Pune Tourist Local Bus Packages']
      ],
      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Us for Pune Local Bus on Rent at Shraddha Travels?",
          WhyChoosedescription: "When it comes to renting a bus for local travel in Pune, Shraddha Travels stands out as your trusted provider. We ensure a comfortable, reliable, and enjoyable journey for every customer. Our commitment to quality and service excellence makes us the go-to choice for all your travel needs."
        },
        {
          WhyChooseheading: "Wide Range of Buses:",
          WhyChoosedescription: "We offer a comprehensive selection of buses, including luxury, semi-luxury, and standard options. This diversity ensures that every customer finds a bus that meets their specific requirements, whether it’s for a corporate event, family outing, or a school trip. Our vehicles are equipped with modern amenities to enhance your travel experience."
        },
        {
          WhyChooseheading: "Affordable Pricing:",
          WhyChoosedescription: "At Shraddha Travels, we believe that quality service shouldn’t come at a high price. Our competitive rates make it easy for you to rent a bus for local travel in Pune without breaking the bank. We offer transparent pricing with no hidden fees, so you know exactly what you’re paying for."
        },
        {
          WhyChooseheading: "Experienced Drivers:",
          WhyChoosedescription: "Safety and comfort are paramount during travel. Our team of professional drivers is not only well-versed with Pune's roads but also trained in safety protocols and customer service. They ensure a safe, timely, and pleasant journey, allowing you to relax and enjoy your trip."
        },
        {
          WhyChooseheading: "24/7 Availability:",
          WhyChoosedescription: "We understand that travel needs can arise at any time. That’s why we offer round-the-clock bus rental services to accommodate your travel requirements, whether it’s a day trip, special event, or last-minute city tour. Our flexible scheduling ensures that you can get the transportation you need, when you need it."
        },
        {
          WhyChooseheading: "Customizable Packages:",
          WhyChoosedescription: "We recognize that each event is unique. Our flexible and tailored rental packages can be customized to fit your specific needs for corporate events, weddings, picnics, or school outings. Whether you need specific amenities or special routes, we work with you to create the perfect travel solution."
        },
        {
          WhyChooseheading: "Comfortable Travel:",
          WhyChoosedescription: "Our buses are meticulously maintained and equipped with modern amenities like comfortable seating, air conditioning, and entertainment options. We prioritize your comfort, ensuring that every ride is a pleasant experience for all passengers, regardless of the duration of your journey."
        },
        {
          WhyChooseheading: "Strong Reputation:",
          WhyChoosedescription: "With years of experience in the travel industry, Shraddha Travels has built a solid reputation for providing reliable and efficient local bus rental services in Pune. Our track record speaks for itself, with numerous satisfied customers who return to us for their travel needs."
        },
        {
          WhyChooseheading: "On-Time Service:",
          WhyChoosedescription: "Punctuality is one of our core values. We understand the importance of being on time, whether you’re heading to an important meeting or a family function. Our commitment to on-time service ensures that you reach your destination as planned, without any hassles."
        },
        {
          WhyChooseheading: "Easy Booking Process:",
          WhyChoosedescription: "Our user-friendly booking system makes renting a bus in Pune convenient and stress-free. You can easily book your preferred vehicle online or through our dedicated customer support team, ensuring a seamless experience from start to finish."
        },
        {
          WhyChooseheading: "Focus on Customer Satisfaction:",
          WhyChoosedescription: "At Shraddha Travels, we prioritize customer satisfaction above all else. We strive to offer the best service experience possible, with a focus on listening to your needs and providing solutions that exceed your expectations. Our goal is to ensure that you choose us for your next trip and recommend us to others."
        }
      ]
      
  }


  const faqData = [
    {
      question: "What types of buses are available for local rental in Pune?",
      answer: "We offer a range of buses, including luxury, semi-luxury, and standard buses in various seating capacities (14, 17, 25, 32, 40, 45 seaters) to cater to different group sizes."
    },
    {
      question: "How do I book a local bus for rent in Pune?",
      answer: "You can easily book a bus by contacting us through our website, phone, or visiting our office. We’ll help you select the right bus based on your travel requirements."
    },
    {
      question: "What is included in the rental package?",
      answer: "Our rental packages include the bus, a professional driver, and fuel costs. Additional services like on-board amenities can be arranged on request."
    },
    {
      question: "Is there a minimum rental duration for local bus services?",
      answer: "Yes, we typically require a minimum of 4 hours for local bus rentals. However, we can customize packages based on your specific needs."
    },
    {
      question: "Can I rent a bus for a one-day trip within Pune?",
      answer: "Yes, we offer one-day trip packages for local sightseeing, events, corporate outings, or school trips."
    },
    {
      question: "Are your drivers familiar with Pune's local routes?",
      answer: "Yes, all our drivers have extensive experience and are very familiar with Pune's local routes and traffic conditions."
    },
    {
      question: "Do you offer buses for corporate events and group outings?",
      answer: "Yes, we provide buses for a wide range of events, including corporate outings, weddings, school trips, and family picnics."
    },
    {
      question: "Are the buses equipped with air conditioning?",
      answer: "Yes, we offer both AC and non-AC buses to ensure maximum comfort during your trip."
    },
    {
      question: "How far in advance should I book a bus for local travel?",
      answer: "We recommend booking your bus at least 1-2 days in advance to ensure availability, especially during peak travel times."
    },
    {
      question: "Do you provide buses for last-minute bookings?",
      answer: "Yes, we do accommodate last-minute bookings, subject to bus availability."
    }
  ];
  


  const testimonials = [
    {
      name: "Ms. Anchal Sabale",
      text: "I recently rented a bus from Shraddha Travels for a family event in Pune, and I couldn’t have been more satisfied! The bus was clean, comfortable, and arrived on time. The driver was professional and knew all the local routes, making the trip hassle-free. Shraddha Travels offered great customer service and made the entire process smooth from start to finish. Highly recommend them for local bus rentals in Pune!"
    },
    {
      name: "Mr. Rohit Prajapat",
      text: "We hired a bus from Shraddha Travels for a corporate outing, and the experience was fantastic. The bus was well-maintained and equipped with AC, making the journey comfortable for all of us. The staff was very accommodating, and the booking process was seamless. They provided excellent service at an affordable price. Shraddha Travels is definitely our go-to for any future bus rentals in Pune!"
    }
  ];
  

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Pune Local Bus on Rent Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title> Bus Hire Near me | Contact: +91 8087847191 | Shraddha Travels | Affordable Bus Rentals</title>
    <meta name="description" content="Convenient bus hire services near me in Pune for local trips, corporate events, and outings. Affordable and reliable transportation." />
    <meta name="keywords" content="Bus Hire Near Me Pune, Bus Rental in Pune, Tempo Traveller Hire, Mini Bus on Rent" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus Hire Near Me Pune | Affordable Bus Rentals" />
    <meta property="og:description" content="Affordable and convenient bus hire services near you in Pune for local trips, corporate events, and more. Book now!" />
    <meta property="og:url" content="https://www.amuratatravels.com/bus-hire-near-me-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/bus-hire-near-me-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Hire Near Me Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/bus-hire-near-me-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental Services in Pune",
                    "description": "Convenient and affordable bus hire services for local trips, corporate events, and outings in Pune."
                }
            }
        }
        `}
    </script>
</Helmet>


      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 4-09.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for “Pune Local Bus on Rent” at Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Bushirenearme;