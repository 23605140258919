
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Bushireformaharashtra() {



  const cardData =
  {
    keyword: ' Bus Hire Services for Pune to Maharashtra Darshan',
    heading: 'Shraddha Travels: Bus Hire Services for Pune to Maharashtra Darshan',
    headingDescription: 'If you’re planning a pilgrimage or sightseeing tour across Maharashtra, Shraddha Travels offers exceptional bus hire services for a comfortable and memorable journey. Our buses are ideal for groups looking to explore the diverse and culturally rich state of Maharashtra. With a focus on comfort, reliability, and affordability, we ensure your travel experience is smooth and enjoyable. Discover the top destinations and enjoy a hassle-free journey with Shraddha Travels.',
    top: 'Top Places to Visit in Maharashtra with Shraddha Travels:',

    topPlaces: [
        {
            title: "1. Mumbai",
            description: "Mumbai, the financial capital of India, is a bustling metropolis known for its vibrant culture and iconic landmarks. Key attractions include the Gateway of India, an archway overlooking the Arabian Sea, Marine Drive, a scenic promenade known as the 'Queen's Necklace,' the Elephanta Caves with ancient rock-cut sculptures, and the Chhatrapati Shivaji Maharaj Terminus, a UNESCO World Heritage Site showcasing Victorian Gothic architecture."
        },
        {
            title: "2. Pune",
            description: "Pune, often referred to as the Oxford of the East, boasts a rich cultural heritage and modern amenities. Notable places to visit include Shaniwarwada, a historical fortification that served as the seat of the Peshwas, the Aga Khan Palace, an important site in India's freedom struggle, and the Osho International Meditation Resort, a tranquil space for meditation and wellness."
        },
        {
            title: "3. Aurangabad",
            description: "Known for its historical significance, Aurangabad is home to the Ajanta and Ellora Caves, which are UNESCO World Heritage Sites featuring intricate rock-cut temples and monasteries dating back to the 2nd century BC. The Bibi Ka Maqbara, often called the 'Taj of the Deccan,' is a stunning mausoleum built in memory of Aurangzeb's wife and is renowned for its architectural beauty."
        },
        {
            title: "4. Nashik",
            description: "Nashik is a prominent pilgrimage site, known for its role in the Kumbh Mela, a major Hindu festival held every 12 years. It also features beautiful vineyards, with Sula Vineyards being a major attraction for wine lovers, offering tours and tastings. Significant religious sites include the Panchavati Temple, dedicated to Lord Rama, and the Kalaram Temple, which is highly revered by devotees."
        },
        {
            title: "5. Shirdi",
            description: "Shirdi is renowned as the home of the revered saint Sai Baba. The Sai Baba Temple attracts millions of devotees each year, making it a major pilgrimage destination. Visitors can explore the saint's dwelling, the Samadhi Mandir, and experience the spiritual ambiance that envelops the town, alongside various other shrines and temples dedicated to different deities."
        },
        {
            title: "6. Lonavala",
            description: "Lonavala, a picturesque hill station, is famous for its lush greenery, scenic viewpoints, and pleasant climate. Key attractions include the Karla and Bhaja Caves, which feature ancient Buddhist rock-cut architecture, Lion's Point, known for its breathtaking sunsets, and the stunning Bushi Dam, where visitors can enjoy a refreshing day by the water."
        },
        {
            title: "7. Mahabaleshwar",
            description: "Known for its breathtaking views and pleasant weather, Mahabaleshwar is a popular hill station. Highlights include Venna Lake, where you can enjoy boating and horse riding, Pratapgad Fort, a historic fortress offering panoramic views of the surrounding hills, and the numerous viewpoints like Arthur’s Seat, which provides stunning vistas of the valleys below."
        },
        {
            title: "8. Alibaug",
            description: "Alibaug, a coastal town near Mumbai, is known for its beautiful beaches and relaxed ambiance. Popular spots include Alibaug Beach, ideal for sunbathing and water sports, Kolaba Fort, a sea fort that dates back to the 17th century, and the serene Mandwa Beach, which is perfect for a tranquil escape and picturesque sunsets."
        },
        {
            title: "9. Ratnagiri",
            description: "Ratnagiri is famous for its historical forts, beautiful beaches, and the delightful Alphonso mangoes. The Ratnadurg Fort offers a glimpse into the region's past, while Ganpatipule Beach is known for its clear waters and tranquil surroundings, making it a popular destination for relaxation and water activities."
        },
        {
            title: "10. Sindhudurg",
            description: "Located on the Konkan coast, Sindhudurg is known for its historic forts and pristine beaches. The Sindhudurg Fort, built by Shivaji Maharaj, is a significant attraction with its unique architecture and stunning sea views. Tarkarli Beach is renowned for its crystal-clear waters and water sports like scuba diving, attracting adventure seekers and nature lovers alike."
        }
    ],    
    services: [
      {
          "name": "Pune to Kolhapur Bus Hire",
          "description": "Experience a comfortable journey from Pune to Kolhapur with our reliable bus hire services. Kolhapur, known for its rich history and cultural significance, boasts landmarks such as the Mahalakshmi Temple and the historic Panhala Fort. Our well-maintained buses, equipped with comfortable seating and modern amenities, ensure a smooth and enjoyable trip. Enjoy scenic views along the way while our professional drivers navigate the best routes to make your journey pleasant."
      },
      {
          "name": "Pune to Bhandardara Bus on Rent",
          "description": "Explore the serene beauty of Bhandardara, a popular hill station and a perfect getaway for nature lovers, with our bus rental service. Renowned for its lush greenery, mesmerizing waterfalls, and tranquil lakes like Arthur Lake, Bhandardara offers a refreshing escape from city life. Choose Shraddha Travels for a hassle-free journey, with comfortable buses that allow you to relax and take in the breathtaking landscapes as you travel."
      },
      {
          "name": "Pune to Pandharpur Bus On Rent",
          "description": "Visit the sacred town of Pandharpur, famous for the Vithoba Temple, one of the most revered pilgrimage sites in India. Our bus rental services are designed for comfort and convenience, ensuring you have a pleasant trip to this important spiritual destination. With our experienced drivers, you can focus on your prayers and offerings while we handle the logistics, making your pilgrimage a memorable experience."
      },
      {
          "name": "Pune to Tuljapur Darshan Bus Hire",
          "description": "Experience a spiritual journey to the Tuljabhavani Temple in Tuljapur with our dedicated bus hire services. This revered site attracts countless devotees seeking blessings. Our comfortable and well-equipped buses ensure a pleasant and memorable pilgrimage experience, allowing you to travel in peace. Enjoy the scenic routes and our attentive service as you embark on this spiritual adventure."
      },
      {
          "name": "Pune to Jyotiba Bus Hire",
          "description": "Travel to Jyotiba, known for the Jyotiba Temple perched atop a hill, with our reliable bus hire services. This temple is dedicated to Lord Jyotiba, attracting many devotees. Our buses offer a comfortable ride with ample space for relaxation, and our skilled drivers ensure timely arrival so you can focus on your spiritual journey. Enjoy the stunning views as you ascend to this sacred site."
      },
      {
          "name": "Pune to Nashik Darshan Bus Hire",
          "description": "Nashik, famous for its vineyards, scenic beauty, and religious significance, including sites like the Panchavati Temple and Kalaram Temple, is easily accessible with our bus hire services. Enjoy a comfortable and enjoyable trip to Nashik with Shraddha Travels. Our experienced drivers will guide you through the city's highlights, including wine tasting at renowned vineyards, making your visit both spiritual and delightful."
      },
      {
          "name": "Pune to Trimbakeshwar Bus on Rent",
          "description": "Trimbakeshwar, home to the Trimbakeshwar Temple, is one of the 12 Jyotirlingas and a significant pilgrimage destination. Our bus rental services offer a comfortable and efficient way to reach this sacred site. Enjoy the convenience of traveling in well-equipped buses that provide a smooth journey, ensuring that your spiritual experience is both fulfilling and comfortable."
      },
      {
          "name": "Pune to Bhimashankar Bus Tour Package",
          "description": "Discover the sacred Bhimashankar Temple, one of the twelve Jyotirlingas, with our exclusive bus tour package. Our comprehensive package includes a comfortable ride, experienced guides, and a well-planned itinerary to enhance your pilgrimage experience. Visit lush forests and serene landscapes as you make your way to this important spiritual site, making it a memorable journey."
      },
      {
          "name": "Pune to Konkan Darshan Bus Package",
          "description": "Explore the beautiful Konkan region with our specialized bus package. From pristine beaches like Ganpatipule to historic forts such as Sindhudurg, and lush landscapes, our Konkan Darshan package offers a comprehensive tour of this picturesque coastal area. Enjoy local cuisine and cultural experiences as we guide you through the heart of Konkan, ensuring an unforgettable travel experience."
      },
      {
          "name": "Pune to Sade Teen Shakti Peeth Package",
          "description": "Visit the revered Sade Teen Shakti Peeths with our dedicated bus package. This pilgrimage covers significant Shakti Peeths, each with its unique stories and spiritual significance, offering a deeply spiritual journey through sacred sites. Our well-planned itinerary ensures you make the most of your trip, with comfortable transportation and knowledgeable guides to enrich your experience."
      },
      {
          "name": "5 Jyotirlinga Darshan From Pune, Maharashtra",
          "description": "Embark on a spiritual journey to the five Jyotirlingas from Pune with our specialized bus service. This pilgrimage package covers key Jyotirlinga temples, providing a comprehensive travel experience that includes comfortable rides and guided visits. Our professional drivers and attentive service ensure you can focus on your spiritual journey while enjoying a hassle-free experience."
      },
      {
          "name": "Ashtavinayak Tour from Pune by Bus",
          "description": "Experience the Ashtavinayak tour with ease using our bus service. This tour covers all eight Ganesh temples, each with its unique charm and significance, offering a comfortable and convenient way to complete this important pilgrimage. Our well-maintained buses ensure a smooth journey, allowing you to focus on your prayers and reflections as you travel from one temple to another."
      },
      {
          "name": "Pune Darshan Bus Hire on Rent",
          "description": "Explore the historical and cultural landmarks of Pune with our comprehensive Pune Darshan bus hire service. Our buses are perfect for city tours, offering comfort and convenience for all passengers. From the Shaniwarwada to the Aga Khan Palace, our knowledgeable drivers will provide insights into each location, ensuring a fulfilling exploration of the city’s rich heritage."
      },
      {
          "name": "Bus hire for Pune to Maharashtra Darshan Contact Number",
          "description": "Contact Shraddha Travels at +91 8087847191 for prompt and efficient bus hire for Pune to Maharashtra Darshan. Our dedicated team is ready to assist you with your booking, ensuring a smooth and enjoyable ride for all our customers. We prioritize your comfort and satisfaction, making your travel experience with us truly remarkable."
      }
  ],

    tableData: [
        ['- Pune to Kolhapur Bus Hire', '- Pune to Bhandardara Bus on Rent'],
        ['- Pune to Pandharpur Bus On Rent', '- Pune to Tuljapur Darshan Bus Hire'],
        ['- Pune to Jyotiba Bus Hire', '- Pune to Nashik Darshan Bus Hire'],
        ['- Pune to Trimbakeshwar Bus on Rent', '- Pune to Bhimashankar Bus Tour Package'],
        ['- Pune to Konkan Darshan Bus Package', '- Pune to Sade Teen Shakti Peeth Package'],
        ['- 5 Jyotirlinga Darshan From Pune, Maharashtra', '- Ashtavinayak Tour from Pune by Bus'],
        ['- Ashtavinayak Darshan From Pune by Bus', '- Pune Darshan Bus Hire on Rent'],
        ['- Pune to Mumbai Darshan Bus On Rent', '- Pune to Nagpur Tadoba Darshan Bus on Rent'],
        ['- Shraddha Travels Pune On Rent', '']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Bus Hire from Pune to Maharashtra Darshan?",
            WhyChoosedescription: "Shraddha Travels stands out as the premier choice for bus hire services from Pune to Maharashtra Darshan. Our commitment to delivering exceptional travel experiences ensures that every journey is comfortable, safe, and enjoyable. We pride ourselves on our attention to detail and customer satisfaction, making us the preferred choice for both pilgrims and tourists."
        },
        {
            WhyChooseheading: "Comfortable Fleet:",
            WhyChoosedescription: "Our fleet includes modern, well-maintained buses equipped with comfortable seating, air conditioning, and other amenities such as charging ports and entertainment systems. Each vehicle is designed to provide a relaxing atmosphere, allowing you to unwind and enjoy the scenic views during your journey."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedescription: "Our professional drivers are not only skilled in navigating various routes but are also trained to prioritize passenger safety and comfort. With extensive experience in the industry, they are equipped to handle any travel-related concerns and ensure a smooth ride, allowing you to focus on your journey."
        },
        {
            WhyChooseheading: "Flexible Itineraries:",
            WhyChoosedescription: "We understand that every group has unique needs. Our customizable itineraries allow you to select your preferred destinations and travel times. Whether you’re planning a religious pilgrimage or a leisurely sightseeing tour, we work closely with you to create a personalized travel plan that suits your preferences."
        },
        {
            WhyChooseheading: "Affordable Pricing:",
            WhyChoosedescription: "We provide competitive pricing without compromising on quality. Our transparent pricing model means there are no hidden costs, allowing you to budget effectively for your trip. We believe that exceptional travel experiences should be accessible to everyone, which is why we strive to offer the best value for your money."
        },
        {
            WhyChooseheading: "Timely Service:",
            WhyChoosedescription: "Punctuality is a cornerstone of our service. We understand the importance of sticking to schedules, especially when it comes to religious events and pilgrimages. Our efficient planning and execution ensure that you reach your destinations on time, minimizing stress and enhancing your overall experience."
        },
        {
            WhyChooseheading: "24/7 Customer Support:",
            WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist you with bookings, answer your queries, and provide timely updates. We believe in maintaining open lines of communication, so you can rest assured knowing that help is just a phone call away whenever you need it."
        },
        {
            WhyChooseheading: "Safety First:",
            WhyChoosedescription: "Safety is our top priority. We adhere to all necessary protocols and regulations to ensure a secure journey. Our buses undergo regular maintenance checks, and our drivers follow strict safety standards, giving you peace of mind as you travel. We also provide safety briefings to passengers to further enhance their security during the journey."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What types of buses are available for hire for Maharashtra Darshan?",
        answer: "We offer a variety of buses, including standard, luxury, and sleeper buses. Our fleet is equipped with comfortable seating, air conditioning, and other amenities to cater to different travel needs and preferences."
    },
    {
        question: "How can I book a bus for Maharashtra Darshan?",
        answer: "You can book a bus by contacting us directly through our website or customer support. Provide details such as the travel dates, destinations, and number of passengers, and we will assist you with the booking process."
    },
    {
        question: "Are there any special packages for Maharashtra Darshan?",
        answer: "Yes, we offer special packages for various Maharashtra Darshan tours, including pilgrimage sites and sightseeing tours. These packages can be customized to suit your specific requirements."
    },
    {
        question: "How much does it cost to hire a bus for Maharashtra Darshan?",
        answer: "The cost of hiring a bus depends on factors such as the type of bus, the duration of the trip, and the destinations covered. We offer competitive pricing and provide a detailed quote based on your requirements."
    },
    {
        question: "Are the buses equipped with amenities?",
        answer: "Yes, our buses are equipped with essential amenities such as comfortable seating, air conditioning, and sometimes Wi-Fi. Specific amenities may vary based on the type of bus you choose."
    },
    {
        question: "What measures are taken to ensure passenger safety?",
        answer: "We prioritize safety by regularly maintaining our buses, adhering to safety protocols, and employing experienced drivers. Our buses are equipped with safety features to ensure a secure travel experience."
    },
    {
        question: "Can I make changes to my booking after confirmation?",
        answer: "Yes, you can make changes to your booking based on availability and our terms and conditions. Please contact our customer support team as soon as possible to discuss any changes."
    },
    {
        question: "Is there a limit on the number of passengers?",
        answer: "The number of passengers depends on the type of bus you hire. Our buses come in various sizes, from smaller buses for intimate groups to larger ones for bigger parties."
    },
    {
        question: "What is the cancellation policy?",
        answer: "Our cancellation policy varies depending on the booking terms and conditions. Please review the policy at the time of booking or contact our customer support team for detailed information."
    },
    {
        question: "Can Shraddha Travels assist with itinerary planning?",
        answer: "Yes, we can assist with itinerary planning based on your preferences and destinations. Our team can help you design a customized travel plan to make the most of your Maharashtra Darshan."
    }
];



const testimonials = [
    {
        name: "Mrs. Suman Deshmukh",
        text: "Our journey with Shraddha Travels for the Maharashtra Darshan was truly exceptional. From the moment we boarded the bus, the comfort and cleanliness were evident. The driver was punctual and knowledgeable about the routes, making our trip both smooth and enjoyable. The customer service team was very responsive and helped us plan a perfect itinerary. We visited several sacred sites and tourist spots without any hassle. I highly recommend Shraddha Travels for anyone planning a pilgrimage or sightseeing tour."
    },
    {
        name: "Mr. Ramesh Patel",
        text: "Shraddha Travels provided an outstanding experience for our Maharashtra Darshan. The bus was well-maintained, and the journey was very comfortable, thanks to the smooth ride and amenities. Our group was impressed with the professionalism of the drivers and the flexibility offered in our itinerary. Everything was well-organized, and the trip exceeded our expectations. We felt safe and well taken care of throughout the journey. Thank you, Shraddha Travels, for making our trip memorable and stress-free!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Bus hire for Pune to Maharashtra Darshan Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Bus Hire Services for Pune to Maharashtra Darshan | Shraddha Travels</title>
    <meta name="description" content="Hire buses for Pune to Maharashtra Darshan with Shraddha Travels. Visit Kolhapur, Pandharpur & more in comfort at affordable prices!" />
    <meta name="keywords" content="Bus Hire for Pune to Maharashtra Darshan, Pune to Kolhapur Bus Hire, Pune to Nashik Darshan, Pune to Pandharpur Bus Rent, Shraddha Travels" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Bus Hire Services for Pune to Maharashtra Darshan | Shraddha Travels" />
    <meta property="og:description" content="Explore our bus hire services for Pune to Maharashtra Darshan, ensuring comfortable travel to various holy sites. Perfect for family and group trips." />
    <meta property="og:url" content="https://www.shraddhatravels.com/bus-hire-pune-maharashtra-darshan" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/maharashtra-darshan.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Hire Services for Pune to Maharashtra Darshan",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/bus-hire-pune-maharashtra-darshan",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Pune to Maharashtra Darshan Bus Package",
                    "description": "Comfortable bus hire services for Pune to Maharashtra Darshan, including key pilgrimage destinations and flexible travel options."
                }
            },
            "keywords": [
                "Bus Hire for Pune to Maharashtra Darshan",
                "Affordable Bus Services Pune",
                "Pune to Kolhapur Bus Hire",
                "Pune to Pandharpur Bus Rent"
            ]
        }
        `}
    </script>
</Helmet>


      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 3-08.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Bus Hire for Pune to Maharashtra Darshan – Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Bushireformaharashtra;