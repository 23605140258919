
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punesadeteen() {



  const cardData =
  {
    keyword: ' Pune Sade Teen Shakti Peeth Bus on Rent ',
    heading: 'Shraddha Travels: Pune Sade Teen Shakti Peeth Bus on Rent ',
    headingDescription: 'Discover the spiritual significance of the Sade Teen Shakti Peeth with Shraddha Travels premium bus rental services. Our dedicated bus service is designed to make your pilgrimage comfortable and memorable as you visit these revered Shakti Peeths.',

    topPlaces: [
        {
          title: "1. Comfortable Travel",
          description: "Enjoy a smooth and comfortable ride with our well-maintained buses equipped with modern amenities. Our buses feature ergonomic seating, spacious interiors, and climate control systems, ensuring a relaxed journey to the Shakti Peeths. Whether you're traveling with family or friends, comfort is our priority."
        },
        {
          title: "2. Experienced Drivers",
          description: "Our professional drivers are experienced and well-versed with the routes to the Sade Teen Shakti Peeths. They not only ensure a safe and timely journey but also provide insights about the local culture and history during your trip. Their friendly demeanor and dedication to service enhance your overall travel experience."
        },
        {
          title: "3. Customizable Itineraries",
          description: "We offer flexible bus rental options that can be customized according to your schedule and preferences for visiting the Shakti Peeths. Whether you want to spend more time at a specific site or include additional stops, our team is ready to create an itinerary that suits your needs perfectly."
        },
        {
          title: "4. Spacious Buses",
          description: "Choose from a range of bus sizes to accommodate your group comfortably. Our fleet includes mini-buses for smaller gatherings and larger coaches for bigger groups, ensuring that everyone has ample space for a pleasant journey. Each bus is designed to provide a comfortable atmosphere, perfect for long trips."
        },
        {
          title: "5. Affordable Rates",
          description: "Shraddha Travels offers competitive pricing for bus rentals to the Sade Teen Shakti Peeths, ensuring that you get the best value for your money. We provide transparent pricing with no hidden charges, allowing you to enjoy your pilgrimage without worrying about exceeding your budget."
        },
        {
          title: "6. Onboard Amenities",
          description: "Our buses come with essential amenities such as air conditioning, comfortable seating, clean facilities, and entertainment options like music systems. These features are designed to enhance your travel experience, making your journey not just comfortable but also enjoyable."
        },
        {
          title: "7. Punctual Service",
          description: "We pride ourselves on punctuality. Our buses arrive on time and adhere to the schedule to ensure that your pilgrimage is smooth and uninterrupted. Our commitment to timeliness allows you to fully immerse yourself in the spiritual experience without any stress."
        },
        {
          title: "8. Group-Friendly Options",
          description: "Whether you are traveling with a small group or a large entourage, we provide buses that cater to different group sizes. Our flexible options allow us to accommodate everyone comfortably, ensuring that the whole group travels together and enjoys the journey."
        },
        {
          title: "9. Expert Guidance",
          description: "Our staff can provide valuable insights and guidance about the Shakti Peeths, enhancing your spiritual journey with informative support. They are knowledgeable about the significance of each site, helping you understand and appreciate your visit on a deeper level."
        },
        {
          title: "10. Hassle-Free Booking",
          description: "Booking your bus rental with Shraddha Travels is simple and straightforward. Our customer service team is ready to assist you with all your needs, whether you prefer to book online, via phone, or in person. We strive to make the entire process as seamless as possible so you can focus on your pilgrimage."
        }
      ],
      
      services: [
        {
          name: "Sade Teen Shakti Peeth Yatra from Pune",
          description: "Embarking on the Sade Teen Shakti Peeth Yatra from Pune? Shraddha Travels offers dedicated bus and tempo traveller services to make your pilgrimage comfortable and memorable. Our specialized travel packages cover key Shakti Peeth temples including Mahalaxmi Temple at Kolhapur, Tuljapur, Renuka Mata at Mahurgad, and Saptashrungi Mata Vani. We ensure a smooth journey with experienced drivers and well-maintained vehicles tailored to meet your spiritual travel needs."
        },
        {
          name: "Pune to Mahalaxmi Temple at Kolhapur Bus Hire",
          description: "Planning a visit to Mahalaxmi Temple in Kolhapur from Pune? Shraddha Travels provides reliable bus hire services for your pilgrimage. Choose from our fleet of comfortable buses and enjoy a hassle-free journey to this revered temple. Our professional drivers ensure safety and punctuality, making your trip to Mahalaxmi Temple a serene and enjoyable experience."
        },
        {
          name: "Pune to Tuljapur Tempo Traveller on Rent",
          description: "For a comfortable journey to Tuljapur, Shraddha Travels offers tempo travellers on rent. Ideal for small to medium-sized groups, our tempo travellers provide ample space and modern amenities. Enjoy a smooth and pleasant ride from Pune to Tuljapur with our well-maintained vehicles and experienced drivers."
        },
        {
          name: "Pune to Renuka Mata at Mahurgad Bus on Rent",
          description: "Visit the sacred Renuka Mata Temple at Mahurgad with ease by renting a bus from Shraddha Travels. Our bus hire services are designed to accommodate your group comfortably while ensuring a safe and timely journey. Explore the divine surroundings of Mahurgad with our reliable and efficient transport solutions."
        },
        {
          name: "Pune to Mahurgad Bus on Rent Package",
          description: "Shraddha Travels offers comprehensive bus on rent packages for your trip from Pune to Mahurgad. Our packages include comfortable and well-maintained buses, ensuring a pleasant journey to the sacred Mahurgad temple. Customize your travel itinerary and enjoy a memorable pilgrimage with our all-inclusive bus hire services."
        },
        {
          name: "Pune to Saptashrungi Mata Vani Bus on Rent",
          description: "Plan your visit to Saptashrungi Mata Vani with our specialized bus rental services from Pune. Shraddha Travels provides comfortable and spacious buses to accommodate your group, making the journey to this significant pilgrimage site convenient and enjoyable. Our professional drivers and well-maintained buses ensure a safe and comfortable trip."
        },
        {
          name: "Bus Hire for Sade Teen Shakti Peeth Yatra from Pune",
          description: "For the Sade Teen Shakti Peeth Yatra, Shraddha Travels offers tailored bus hire services to cover all key pilgrimage sites. Choose from our range of bus sizes to fit your group and enjoy a seamless journey to each Shakti Peeth. Our dedicated service ensures that your spiritual journey is comfortable and well-organized."
        },
        {
          name: "Mini Bus on Rent for Sade Teen Shakti Peeth Yatra",
          description: "If you’re looking for a mini bus for the Sade Teen Shakti Peeth Yatra, Shraddha Travels has you covered. Our mini buses are perfect for small groups, offering comfort and convenience for your pilgrimage from Pune. Experience a smooth and enjoyable journey with our well-equipped mini buses."
        },
        {
          name: "14, 17, 20, 25 Seater Bus for Pune to Sade Teen Shakti Peeth Yatra",
          description: "Shraddha Travels offers a range of bus options including 14, 17, 20, and 25-seater buses for the Sade Teen Shakti Peeth Yatra from Pune. Choose the bus size that best fits your group and enjoy a comfortable and memorable pilgrimage with our specialized transport services."
        },
        {
          name: "35, 40, 45, 50 Seater Bus for Pune to Sade Teen Shakti Peeth Yatra",
          description: "For larger groups, Shraddha Travels provides 35, 40, 45, and 50-seater buses for the Sade Teen Shakti Peeth Yatra from Pune. Our spacious buses are designed to accommodate larger groups comfortably, ensuring a smooth and enjoyable journey to all key pilgrimage sites. Trust us for reliable and comfortable transport solutions for your spiritual journey."
        },
        {
          name: "3.5 Shakti Peethas Darshan in Maharashtra",
          description: "Visiting the 3.5 Shakti Peethas in Maharashtra is a spiritually significant journey. These revered sites offer a unique and divine experience. Shraddha Travels provides reliable transportation to help you complete your darshan comfortably and conveniently."
        },
        {
          name: "Tempo Traveller on Rent for Sadeteen Shakti Peeth Yatra",
          description: "Our tempo traveller rentals are ideal for the Sadeteen Shakti Peeth Yatra. We offer a range of options to accommodate various group sizes, ensuring you have a comfortable and smooth journey to each sacred site."
        },
        {
          name: "Sadeteen Shakti Peeth Tour from Pune Bus Hire",
          description: "Plan your Sadeteen Shakti Peeth tour from Pune with our bus hire services. We provide a selection of buses suitable for different group sizes, offering comfort and reliability for your pilgrimage. Enjoy a well-organized tour with our expert transportation solutions."
        },
        {
          name: "17 Seater Tempo Traveller on Rent for Sadeteen Shakti Peeth Yatra",
          description: "For smaller groups embarking on the Sadeteen Shakti Peeth Yatra, our 17-seater tempo travellers offer a perfect balance of space and comfort. This option is ideal for intimate group travel, ensuring everyone enjoys a pleasant and memorable journey."
        },
        {
          name: "Shraddha Travels Pune On Rent",
          description: "Shraddha Travels is your trusted partner for all vehicle rental needs related to the Sadeteen Shakti Peeth Yatra. Our fleet includes a variety of vehicles, from tempo travellers to buses, tailored to provide a seamless travel experience. We are dedicated to delivering exceptional service and comfort for your pilgrimage."
        },
        {
          name: "Pune Sade Teen Shakti Peeth Bus on Rent Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Pune Sade Teen Shakti Peeth Bus on Rent. We ensure a smooth and enjoyable ride for all our customers."
        }
      ],
      
      tableData: [
        ['- Sade Teen Shakti Peeth Yatra from Pune', '- Pune to Mahalaxmi Temple at Kolhapur Bus Hire'],
        ['- Pune to Tuljapur Tempo Traveller on Rent', '- Pune to Renuka Mata at Mahurgad Bus on Rent'],
        ['- Pune to Mahurgad Bus on Rent Package', '- Pune to Saptashrungi Mata Vani Bus on Rent'],
        ['- Bus Hire for 3.5 Shakti Peeth Yatra from Pune', '- Mini Bus on Rent for Sadeteen Shakti Peeth Yatra'],
        ['- 14, 17, 20, 25 Seater Bus for Pune to Sade Teen Shakti Peeth Yatra', '- 35, 40, 45, 50 Seater Bus for Pune to Sade Teen Shakti Peeth Yatra'],
        ['- 3.5 Shakti Peethas Darshan in Maharashtra', '- Tempo Traveller on Rent for Sadeteen Shakti Peeth Yatra'],
        ['- Sadeteen Shakti Peeth Tour from Pune Bus Hire', '- 17 Seater Tempo Traveller on Rent for Sadeteen Shakti Peeth Yatra'],
        ['- Shraddha Travels Pune On Rent', '- Shraddha Travels Pune On Rent']
      ],
      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels for Pune Sade Teen Shakti Peeth Bus on Rent?",
          WhyChoosedescription: "When it comes to renting a bus for the Sade Teen Shakti Peeth Yatra from Pune, Shraddha Travels is your trusted provider for a comfortable and memorable pilgrimage experience. Our commitment to customer satisfaction and high-quality service sets us apart, ensuring your spiritual journey is seamless and fulfilling."
        },
        {
          WhyChooseheading: "Comprehensive Coverage of Shakti Peeth Temples:",
          WhyChoosedescription: "We specialize in bus rental services that cover all key pilgrimage sites, including the revered Mahalaxmi Temple at Kolhapur, Tuljapur, Renuka Mata at Mahurgad, and Saptashrungi Mata Vani. Our comprehensive packages ensure you don’t miss any sacred destination, allowing you to focus on your spiritual experience."
        },
        {
          WhyChooseheading: "Comfortable and Well-Maintained Buses:",
          WhyChoosedescription: "Our fleet of buses is designed for maximum comfort, featuring spacious interiors, clean environments, and modern amenities. Each vehicle undergoes regular maintenance checks to ensure a safe and pleasant journey, allowing you to relax and enjoy the scenic views along the way."
        },
        {
          WhyChooseheading: "Experienced and Professional Drivers:",
          WhyChoosedescription: "Our drivers are not only experienced but also deeply knowledgeable about the routes to the Shakti Peeth temples. They prioritize your safety and comfort, navigating through various terrains with ease and ensuring that you arrive on time for your pilgrimage activities."
        },
        {
          WhyChooseheading: "Flexible and Customizable Packages:",
          WhyChoosedescription: "We understand that every group has unique needs, which is why we offer customizable bus hire packages. Whether you're a small family gathering or a large pilgrimage group, we can tailor our services to fit your schedule and preferences, ensuring a personalized travel experience."
        },
        {
          WhyChooseheading: "Competitive and Transparent Pricing:",
          WhyChoosedescription: "At Shraddha Travels, we believe in providing high-quality services at competitive rates. Our transparent pricing means there are no hidden fees, allowing you to budget effectively for your pilgrimage while enjoying a top-notch travel experience."
        },
        {
          WhyChooseheading: "Reliable and Timely Service:",
          WhyChoosedescription: "We pride ourselves on our reliability and punctuality. Our well-maintained buses adhere to strict schedules, ensuring you can stick to your travel plans without unnecessary delays, so you can make the most of your pilgrimage."
        },
        {
          WhyChooseheading: "Personalized Customer Support:",
          WhyChoosedescription: "Our dedicated customer service team is available around the clock to assist you with any queries or special requests. From the moment you inquire about our services to the completion of your journey, we provide personalized support to ensure a smooth booking process and a pleasant travel experience."
        },
        {
          WhyChooseheading: "Safe and Secure Travel:",
          WhyChoosedescription: "Safety is our top priority. Our buses are equipped with the latest safety features, and our drivers undergo rigorous training to handle various road conditions. You can travel with peace of mind knowing that your safety is our responsibility."
        },
        {
          WhyChooseheading: "Positive Customer Feedback:",
          WhyChoosedescription: "We have built a strong reputation for reliability and customer satisfaction. Our clients consistently praise our professionalism and the quality of our services, reflecting our commitment to excellence. Your satisfaction is our greatest reward."
        },
        {
          WhyChooseheading: "Easy Booking Process:",
          WhyChoosedescription: "Booking with Shraddha Travels is simple and hassle-free. You can make reservations through our user-friendly website, by phone, or in person. We strive to provide an efficient booking experience, allowing you to focus on your spiritual journey."
        }
      ]
      
  }



  const faqData = [
    {
      question: "How can I book a bus for the Sade Teen Shakti Peeth Yatra from Pune?",
      answer: "You can book a bus by visiting our website, calling our customer service hotline, or visiting our office in person. We offer easy and convenient booking options to suit your needs."
    },
    {
      question: "What types of buses are available for the Sade Teen Shakti Peeth Yatra?",
      answer: "We offer a variety of buses including mini buses, 14-seater, 17-seater, 20-seater, 25-seater, 35-seater, 40-seater, 45-seater, and 50-seater options. Choose the bus size that best fits your group."
    },
    {
      question: "Are there any special amenities provided on the buses?",
      answer: "Yes, our buses come with comfortable seating, air conditioning, and other amenities to ensure a pleasant journey. Specific amenities may vary depending on the bus type."
    },
    {
      question: "What is the cost of hiring a bus for the Sade Teen Shakti Peeth Yatra?",
      answer: "The cost depends on factors such as the bus size, duration of hire, and any additional services required. Contact us for a customized quote based on your specific needs."
    },
    {
      question: "Can I customize the bus rental package for the Sade Teen Shakti Peeth Yatra?",
      answer: "Yes, we offer customizable packages to meet your specific travel requirements. You can adjust the itinerary and services to fit your pilgrimage plans."
    },
    {
      question: "Are your drivers experienced and knowledgeable about the routes?",
      answer: "Yes, our drivers are experienced and familiar with the routes to all the Shakti Peeth temples. They prioritize your safety and ensure a smooth journey."
    },
    {
      question: "How far in advance should I book a bus for the Sade Teen Shakti Peeth Yatra?",
      answer: "We recommend booking as early as possible to secure your preferred bus and date. Early booking helps in better planning and availability."
    },
    {
      question: "What is your cancellation policy?",
      answer: "Our cancellation policy includes specific terms and conditions, with potential charges based on the timing of the cancellation. Please contact our customer service team for detailed information."
    },
    {
      question: "Are the buses cleaned and maintained regularly?",
      answer: "Yes, we ensure that all our buses are thoroughly cleaned and regularly maintained to provide a comfortable and hygienic travel experience."
    },
    {
      question: "Can I request additional services or special requirements?",
      answer: "Yes, we can accommodate special requests or additional services based on availability. Please inform us of any specific needs when making your booking."
    }
  ];
  


  const testimonials = [
    {
      name: "Mr. Rajesh Mehta",
      text: "Our pilgrimage to the Sade Teen Shakti Peeth temples from Pune was an unforgettable experience, thanks to Shraddha Travels. We hired a 35-seater bus for our group, and it was perfect for our needs. The bus was clean, comfortable, and well-equipped, making our journey to Mahalaxmi Temple, Tuljapur, and the other sacred sites smooth and enjoyable. The driver was punctual, professional, and knowledgeable about the routes, which greatly enhanced our travel experience. Shraddha Travels made our spiritual journey seamless and memorable. Highly recommended for anyone planning a similar yatra!"
    },
    {
      name: "Mrs. Anchal Chavan",
      text: "I recently booked a 45-seater bus with Shraddha Travels for our Sade Teen Shakti Peeth Yatra from Pune. The service was exceptional from start to finish. The bus was spacious and comfortable, and the driver was courteous and experienced, ensuring we arrived at each temple safely and on time. The booking process was straightforward, and the customer support team was very helpful in accommodating our special requests. Thanks to Shraddha Travels, our pilgrimage was well-organized and enjoyable. We will definitely choose them again for future trips!"
    }
  ];
  

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Pune Sade Teen Shakti Peeth Bus on Rent Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune Sade Teen Shakti Peeth Bus on Rent | Affordable & Comfortable Journeys</title>
    <meta name="description" content="Explore the Sade Teen Shakti Peeth with our bus rental services from Pune. Options include 14, 17, 20, 25, 35, 40, 45, and 50 seater buses for an unforgettable pilgrimage." />
    <meta name="keywords" content="Sade Teen Shakti Peeth Yatra from Pune, Pune to Mahalaxmi Temple, Pune to Tuljapur Tempo Traveller, Bus Hire for Shakti Peeth, Shraddha Travels Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Pune Sade Teen Shakti Peeth Bus on Rent | Shraddha Travels | Affordable & Comfortable Journeys" />
    <meta property="og:description" content="Reliable bus rental services for Sade Teen Shakti Peeth Yatra from Pune, including options for family trips and group travel." />
    <meta property="og:url" content="https://www.amuratatravels.com/pune-sade-teen-shakti-peeth-bus-hire" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/shakti-peeth-bus-hire.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pune Sade Teen Shakti Peeth Bus on Rent",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/pune-sade-teen-shakti-peeth-bus-hire",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental Services for Sade Teen Shakti Peeth Yatra from Pune",
                    "description": "Comfortable and affordable bus rental services for Sade Teen Shakti Peeth Yatra, including options for group travel and family trips."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 4-03.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for “Pune Sade Teen Shakti Peeth Bus on Rent” – Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punesadeteen;