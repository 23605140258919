
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetoajanta() {



  const cardData =
  {
    keyword: 'Pune to Ajanta Ellora Tempo Traveller On Rent',
    heading: 'Pune to Ajanta Ellora Tempo Traveller On Rent: Shraddha Travels',
    headingDescription: ' Planning a memorable journey from Pune to the iconic Ajanta and Ellora Caves? Look no further than Shraddha Travels! We offer top-notch Tempo Traveller on rent services from Pune to Ajanta and Ellora, ensuring a hassle-free, enjoyable, and comfortable travel experience. Our spacious and well-maintained Tempo Travellers, combined with our skilled drivers and unmatched customer service, make Shraddha Travels the ideal choice for exploring these historic sites. Whether you are traveling with family, friends, or a larger group, our Tempo Traveller rentals will make your trip safe, pleasant, and full of unforgettable memories.',
    top: 'Top Places to Visit in Ajanta and Ellora with Shraddha Travel',

    topPlaces: [
        {
            title: "1. Ajanta Caves",
            description: "The Ajanta Caves, a UNESCO World Heritage Site, consist of 30 rock-cut structures that date back to the 2nd century BCE to 6th century CE. They are renowned for their exquisite Buddhist frescoes and sculptures that vividly depict Jataka tales and the teachings of Buddha. Caves 1, 2, 9, 16, and 17 are particularly celebrated for their intricate designs and religious significance, showcasing the artistic mastery of ancient Indian artisans. Visitors can immerse themselves in the spiritual ambiance and appreciate the meticulous craftsmanship that reflects the rich cultural heritage of India."
        },
        {
            title: "2. Ellora Caves",
            description: "The Ellora Caves feature 34 rock-cut temples that embody a harmonious blend of Hindu, Buddhist, and Jain influences, reflecting India's rich religious diversity. The most famous, the Kailasa Temple (Cave 16), is an architectural marvel carved from a single rock and dedicated to Lord Shiva. Its stunning intricacies include elaborate carvings of deities and mythological scenes that tell stories of ancient Indian lore. The caves date from the 5th to 10th centuries CE, making them a significant site for understanding the evolution of rock-cut architecture and spirituality in India."
        },
        {
            title: "3. Kailasa Temple",
            description: "The Kailasa Temple is the largest monolithic rock excavation in the world, showcasing the incredible engineering skills of ancient craftsmen. Carved entirely from a single rock, this temple is dedicated to Lord Shiva and features a stunning array of sculptures and intricate pillars that depict various deities and mythical narratives. The grand structure spans multiple levels and includes a large courtyard surrounded by smaller shrines, offering visitors a glimpse into the spiritual life of ancient India. Its breathtaking artistry and sheer scale make it a must-visit highlight of Ellora."
        },
        {
            title: "4. Cave 10 at Ajanta",
            description: "Known as the 'Carpenter’s Cave,' Cave 10 is renowned for its horseshoe-shaped chaitya (prayer hall) and beautifully crafted statues of Buddha. The cave's design creates a serene atmosphere ideal for meditation and reflection. The stunning rock-cut architecture includes intricately detailed columns and a massive stupa at the rear, making it a significant example of Buddhist architecture. Visitors can appreciate the peaceful ambiance and the spiritual essence captured in the artwork, providing a deeper understanding of Buddhist teachings."
        },
        {
            title: "5. Cave 16 at Ajanta (Hathi Leni)",
            description: "Cave 16, one of the largest monasteries in Ajanta, is adorned with vibrant paintings that depict Bodhisattvas and scenes from the life of Buddha. This cave showcases the artistic brilliance of the time, with murals that exhibit exquisite detail and color. The tranquil environment allows visitors to reflect on the teachings of Buddhism while appreciating the cultural significance of the cave's artistry. The combination of stunning visuals and historical context makes this cave a key attraction for those exploring Ajanta."
        },
        {
            title: "6. Dashavatara Cave (Cave 15)",
            description: "Dedicated to Lord Vishnu, the Dashavatara Cave features exceptional carvings that illustrate the ten avatars of Vishnu, including the powerful Narsimha, the half-lion avatar, and Varaha, the boar. The cave is an important site for understanding Hindu iconography and mythology. The intricate sculptures not only showcase the artistic prowess of the era but also serve as a visual narrative of Hindu beliefs and values. This cave is a significant stop for visitors interested in the rich tapestry of Indian religion and art."
        },
        {
            title: "7. Cave 32 – Jain Temple",
            description: "The Jain Temple in Cave 32 stands out for its serene ambiance and the exquisite sculptures of Tirthankaras, the revered saints in Jainism. The cave's intricate carvings reflect the finesse of Jain architecture, featuring delicate designs and symbols that are rich in meaning. The peaceful atmosphere makes it an ideal spot for contemplation and learning about Jain philosophy. Visitors can explore the meticulous craftsmanship that characterizes Jain art and architecture, offering a glimpse into the spiritual heritage of the Jain community."
        },
        {
            title: "8. Ajanta Viewpoint",
            description: "The Ajanta Viewpoint offers breathtaking panoramic views of the Ajanta Caves and the lush valley below. This scenic spot is perfect for photography enthusiasts and provides an excellent vantage point to appreciate the beauty of the entire complex. The surrounding landscape adds to the picturesque setting, making it an ideal location for a peaceful moment of reflection. Visitors often enjoy watching the sunrise or sunset from this viewpoint, which enhances the serene experience of exploring the caves."
        },
        {
            title: "9. Cave 29 – Waterfall Cave",
            description: "Nestled near a scenic waterfall, Cave 29 is a unique spot within the Ellora complex, known for its refreshing ambiance and stunning natural beauty. Adorned with finely carved figures of Hindu gods and goddesses, this cave is a testament to the artistic traditions of the time. The soothing sound of cascading water enhances the spiritual experience, inviting visitors to connect with nature while exploring the rich iconography within the cave. It’s a hidden gem that combines art and nature beautifully."
        },
        {
            title: "10. Cave 26 – Ajanta’s Chaitya Hall",
            description: "Cave 26 features a grand chaitya hall with a massive stupa at its center, surrounded by intricate carvings of Buddha in various postures. The detailed artwork on the walls and pillars exemplifies the excellence of rock-cut architecture, making it a highlight of the Ajanta complex. The cave's design promotes a sense of tranquility and reverence, inviting visitors to appreciate the spiritual significance of the space. As one of the finest examples of ancient Buddhist architecture, this cave offers a profound insight into the cultural and religious practices of the time."
        }
    ],    
    services: [
        {
            name: "Pune to Ajanta Ellora Mini Bus on Rent",
            description: "Renting a mini bus from Pune to Ajanta Ellora is the ideal way to travel in comfort and style. At Shraddha Travels, we offer a fleet of well-maintained, spacious mini buses that are equipped with air conditioning, plush seating, and ample luggage space to accommodate all your needs. Whether you’re planning a family outing, a corporate trip, a school tour, or a fun getaway with friends, our mini buses provide a relaxed and enjoyable travel experience, ensuring everyone can travel together while making memories along the way."
        },
        {
            name: "Convenient and Affordable Mini Bus Service",
            description: "Shraddha Travels prides itself on providing a reliable and convenient Pune to Ajanta Ellora mini bus service at competitive rates. Our pricing is designed to be budget-friendly, making group travel accessible without compromising on comfort or quality. Each mini bus features spacious seating, clean interiors, and exceptional customer service, so you can focus on enjoying your journey and the breathtaking sites of Ajanta and Ellora."
        },
        {
            name: "Pune Ajanta Ellora Mini Bus Rates",
            description: "When it comes to Pune Ajanta Ellora mini bus rates, Shraddha Travels is known for its competitive pricing structure that caters to various budgets. We believe in transparency, which means our pricing includes all necessary fees with no hidden costs. Additionally, we offer flexible packages for both day trips and multi-day tours, allowing you to tailor your travel experience according to your schedule, group size, and specific needs."
        },
        {
            name: "Pune to Ajanta Ellora One Way Mini Bus",
            description: "If you’re planning a one-way journey, our Pune to Ajanta Ellora one-way mini bus rental is the perfect choice. This option is designed to offer flexibility and affordability, allowing you to explore the stunning sites of Ajanta and Ellora without the hassle of a return trip. Our experienced drivers are familiar with the route and local conditions, ensuring you have a safe, efficient, and enjoyable journey to these UNESCO World Heritage sites."
        },
        {
            name: "Traveling from Pune to Ajanta Ellora by Mini Bus",
            description: "Traveling from Pune to Ajanta Ellora by mini bus provides a convenient and enjoyable way to delve into Maharashtra’s rich cultural heritage. Our mini buses prioritize comfort and safety, featuring amenities that enhance your travel experience. With experienced drivers who are knowledgeable about the area, you can sit back, relax, and enjoy the scenic landscapes as you make your way to the historic caves, allowing for a stress-free journey to some of India’s most iconic sites."
        },
        {
            name: "Pune Ajanta Ellora Mini Bus on Rent",
            description: "Booking a mini bus from Pune to Ajanta Ellora on rent with Shraddha Travels is designed to be a seamless and hassle-free experience. Our dedicated team is committed to quick bookings and responsive customer support, ensuring that your group travel is well-organized and stress-free. We offer a range of mini bus options to accommodate various group sizes, so you can choose the best vehicle for your needs and travel comfortably to your destination."
        },
        {
            name: "Affordable Pune to Ajanta Ellora Mini Bus Rentals",
            description: "Looking for an affordable Pune to Ajanta Ellora mini bus rental? Shraddha Travels specializes in providing budget-friendly rates that make group travel not only accessible but also enjoyable. We aim to deliver the best travel experience without stretching your budget, combining affordability with high-quality service. Our focus is on ensuring that your group can travel together comfortably and enjoyably, making the journey as memorable as the destination."
        },
        {
            name: "Pune to Ajanta Ellora Taxi Fare per Km",
            description: "For smaller groups or individuals seeking flexibility, Shraddha Travels offers competitive taxi fare per kilometer for travel from Pune to Ajanta Ellora. Our transparent pricing model is designed to provide great value, with various vehicle options available to accommodate your specific needs. This flexibility enables you to plan your journey effectively, ensuring you receive the best rates while enjoying the comfort and convenience of our service."
        },
        {
            name: "Pune to Ajanta Ellora Tempo Traveller On Rent Contact Number",
            description: "For prompt and efficient rentals of Tempo Travellers from Pune to Ajanta Ellora, contact Shraddha Travels at +91 8087847191. Our team is dedicated to ensuring a smooth and enjoyable ride for all customers. We focus on providing high-quality service, safety, and comfort, allowing you to fully enjoy your travel experience. Reach out to us today to book your journey and experience the convenience of traveling with us!"
        }
    ],    
    tableData: [
        ['- Pune to Ajanta Ellora Mini Bus', '- Mini bus from Pune to Ajanta Ellora'],
        ['- Pune Ajanta Ellora Mini Bus on Rent', '- Pune to Ajanta Ellora one way Mini bus'],
        ['- Pune to Ajanta Ellora Travel by Mini Bus', '- Pune Ajanta Ellora Mini Bus rates'],
        ['- Pune to Ajanta Ellora Taxi Fare per Km', '- Affordable Pune to Ajanta Ellora Mini Bus']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Pune to Ajanta Ellora Tempo Traveller On Rent?",
            WhyChoosedescription: "When it comes to renting a Tempo Traveller for your journey from Pune to Ajanta Ellora, Shraddha Travels is your trusted partner. Our service ensures a comfortable and reliable travel experience, tailored to your needs. We prioritize your satisfaction and aim to make your trip memorable and enjoyable, taking care of all the details so you can focus on exploring the magnificent Ajanta and Ellora Caves."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedcription: "Our professional, highly skilled drivers are well-acquainted with the Pune to Ajanta Ellora route. They possess extensive knowledge of local traffic patterns and road conditions, which ensures a safe and timely journey. Our drivers are not only committed to safety and punctuality but are also friendly and approachable, ready to assist you with any queries you may have during the trip."
        },
        {
            WhyChooseheading: "Comfortable and Modern Fleet:",
            WhyChoosedescription: "Our Tempo Travellers are spacious, air-conditioned, and designed with comfortable seating, ample legroom, and sufficient luggage space. We understand that comfort is key during travel, and our vehicles are equipped with modern amenities to enhance your experience. Each vehicle undergoes regular maintenance and thorough inspections to guarantee that you enjoy a smooth, stress-free ride throughout your journey."
        },
        {
            WhyChooseheading: "Transparent Pricing:",
            WhyChoosedescription: "We pride ourselves on our transparent and affordable pricing. Our fare structure is clear and free from hidden fees, ensuring you receive excellent service at a reasonable cost. We provide detailed quotes upfront, allowing you to budget for your trip without any surprises. Our commitment to affordability doesn’t compromise the quality of service we provide."
        },
        {
            WhyChooseheading: "Personalized Service:",
            WhyChoosedescription: "We offer customized rental plans, catering to one-way, round-trip, or multi-day itineraries, making it easy for you to plan your travel according to your preferences and schedule. Our team is happy to accommodate special requests or adjustments, ensuring that your journey aligns with your expectations and needs, whether you're traveling with family, friends, or colleagues."
        },
        {
            WhyChooseheading: "24/7 Customer Support:",
            WhyChoosedescription: "Our dedicated customer support team is available 24/7 to assist you, providing updates, answering queries, and helping with bookings. Whether you need assistance before your trip or during your travel, we are always just a call away. Your satisfaction and peace of mind are our top priorities, and we strive to address any concerns promptly and effectively."
        },
        {
            WhyChooseheading: "Hassle-Free Booking Process:",
            WhyChoosedescription: "With Shraddha Travels, booking a Tempo Traveller is easy and efficient. Our online booking system is user-friendly and allows you to reserve your vehicle in just a few clicks. Additionally, our team is readily available to provide prompt assistance, ensuring that any questions or special requests you have are addressed quickly. We aim to make the entire process smooth and straightforward, saving you time and effort so you can focus on enjoying your travel experience."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What is the seating capacity of the Tempo Traveller for the Pune to Ajanta Ellora route?",
        answer: "Our Tempo Travellers come in various seating capacities, typically ranging from 9-seaters to 15-seaters. We can help you choose the best option depending on your group size."
    },
    {
        question: "How long does the journey from Pune to Ajanta Ellora take in a Tempo Traveller?",
        answer: "The journey from Pune to Ajanta Ellora takes approximately 6-7 hours, depending on traffic and weather conditions. Our experienced drivers ensure a smooth and timely journey."
    },
    {
        question: "Is it possible to book a one-way Tempo Traveller from Pune to Ajanta Ellora?",
        answer: "Yes, we offer one-way as well as round-trip Tempo Traveller services from Pune to Ajanta Ellora. You can choose the option that best suits your travel plans."
    },
    {
        question: "Are there any additional charges for the Tempo Traveller rental?",
        answer: "Our pricing is transparent, and there are no hidden charges. Fuel, driver allowances, and tolls are typically included in the fare; however, any extra charges will be communicated clearly during booking."
    },
    {
        question: "Can we customize our travel itinerary for a multi-day trip?",
        answer: "Absolutely! We provide flexible packages, and you can customize your itinerary as needed. Whether it’s a one-day trip or a multi-day adventure, our team can help you plan the perfect schedule."
    },
    {
        question: "How do I book a Tempo Traveller for Pune to Ajanta Ellora?",
        answer: "Booking is simple! You can book through our website, or call our customer support team to assist with the booking process. We are available 24/7 for any booking or travel-related inquiries."
    },
    {
        question: "Is there enough luggage space in the Tempo Traveller?",
        answer: "Yes, our Tempo Travellers are designed with ample luggage space, accommodating bags and other travel essentials for your journey."
    },
    {
        question: "What safety measures does Shraddha Travels have in place?",
        answer: "Safety is our priority. Our Tempo Travellers are equipped with seat belts, GPS tracking, and are thoroughly sanitized before each trip. We also follow all safety regulations and employ highly skilled drivers."
    },
    {
        question: "Can we make stops along the way for sightseeing?",
        answer: "Yes, our drivers can accommodate reasonable stops along the way for sightseeing or breaks. Just inform us in advance so we can include it in your itinerary."
    },
    {
        question: "Is food or water provided during the journey?",
        answer: "While we don’t provide food and water as part of the package, our drivers can guide you to popular restaurants or stop as per your request during the journey."
    }
];



const testimonials = [
    {
        name: "Mr. Deshmukh and Family",
        text: "Our trip from Pune to Ajanta Ellora with Shraddha Travels was amazing! We rented a Tempo Traveller for our family, and the journey was incredibly comfortable. The vehicle was spacious, clean, and equipped with everything we needed for a long drive. Our driver was not only skilled but also very courteous, making stops whenever we needed. The whole booking process was seamless, and the pricing was transparent with no hidden fees. It was the perfect way to explore Ajanta Ellora together, and I highly recommend Shraddha Travels for anyone looking for a hassle-free experience!"
    },
    {
        name: "Miss Joshi",
        text: "I recently booked a Tempo Traveller from Shraddha Travels for a Pune to Ajanta Ellora tour with friends, and it was one of the best travel experiences we’ve had! The vehicle was in excellent condition, with comfortable seating and lots of legroom. The driver was punctual, professional, and knew the route well, which allowed us to reach Ajanta Ellora smoothly and on time. The company’s customer service was responsive and helpful with all our queries. Overall, Shraddha Travels made our trip memorable and stress-free. Highly recommended for group travel!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Pune to Ajanta Ellora Tempo Traveller On Rent Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune to Ajanta Ellora Tempo Traveller on Rent | Affordable Travel Options</title>
    <meta name="description" content="Rent a tempo traveller for your trip from Pune to Ajanta Ellora. Affordable rates for mini buses, one-way trips, and group travel." />
    <meta name="keywords" content="Pune to Ajanta Ellora Tempo Traveller, Mini Bus Rental Pune, Affordable Mini Bus Hire, Ajanta Ellora Trip, Pune to Ajanta Ellora Taxi Fare" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to Ajanta Ellora Tempo Traveller on Rent | Affordable Travel Options" />
    <meta property="og:description" content="Explore our affordable tempo traveller rentals from Pune to Ajanta Ellora, perfect for group trips and sightseeing." />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-to-ajanta-ellora-tempo-traveller-rent" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/ajanta-ellora-tempo-traveller.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller Rental from Pune to Ajanta Ellora",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-to-ajanta-ellora-tempo-traveller-rent",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental Services to Ajanta Ellora",
                    "description": "Affordable tempo traveller rentals from Pune to Ajanta Ellora, ideal for group sightseeing trips."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 3-07.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs: Pune to Ajanta Ellora Tempo Traveller On Rent - Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetoajanta;