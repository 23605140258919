
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetotrimbakeshwar() {



  const cardData =
  {
    keyword: ' Pune to Trimbakeshwar Tempo Traveller on Rent',
    heading: 'Shraddha Travels: Pune to Trimbakeshwar Tempo Traveller on Rent',
    headingDescription: 'Are you planning a spiritual journey from Pune to Trimbakeshwar? Look no further! Shraddha Travels offers comfortable and reliable Tempo Traveller rentals, ensuring you have a hassle-free travel experience. Trimbakeshwar, located approximately 175 km from Pune, is renowned for its ancient temple dedicated to Lord Shiva and is a significant pilgrimage site for devotees.',
    top: 'Top Places to Visit in Trimbakeshwar with Shraddha Travel',

    topPlaces: [
        {
          title: "1. Trimbakeshwar Temple",
          description: "The main attraction of the town, this ancient temple is renowned for its unique architecture and spiritual significance. It features three faces of Lord Shiva, representing Brahma, Vishnu, and Mahesh, symbolizing the trinity of creation, preservation, and destruction. The temple's intricate carvings and serene ambiance make it a must-visit for pilgrims and architecture enthusiasts alike."
        },
        {
          title: "2. Kundadri Hill",
          description: "Kundadri Hill is a breathtaking destination that offers panoramic views of the surrounding landscape, making it a paradise for nature lovers and photographers. The hill is also home to a Jain temple, adding to its cultural significance. The lush greenery and tranquil environment make it an ideal spot for hiking and enjoying a peaceful day amidst nature."
        },
        {
          title: "3. Godavari River",
          description: "As the source of the Godavari River, this serene water body is a sacred site for many. The banks of the river are perfect for meditation and reflection, offering a tranquil escape from the hustle and bustle of daily life. Visitors can enjoy leisurely walks, picnics, or simply sit by the water and soak in the peaceful surroundings."
        },
        {
          title: "4. Anjneri Hill",
          description: "Anjneri Hill is not only a popular trekking destination but also holds historical and mythological significance as the birthplace of Lord Hanuman. The trek to the top is invigorating, and once there, you are rewarded with stunning views of the valleys and hills. The cool breeze and vibrant flora make it a favorite among adventurers and spiritual seekers."
        },
        {
          title: "5. Brahmagiri Hill",
          description: "Known for its lush greenery and scenic trails, Brahmagiri Hill is perfect for trekking enthusiasts. The hill offers a refreshing escape into nature, with a variety of flora and fauna along the trails. The peaceful environment and beautiful landscapes provide a great backdrop for outdoor activities, photography, and even meditation."
        },
        {
          title: "6. Durgadi Fort",
          description: "Durgadi Fort, steeped in history, offers a glimpse into the past with its ancient architecture and strategic location. History buffs will appreciate exploring its ruins while enjoying panoramic views of the surrounding area. The fort's elevation provides a stunning perspective of the landscape, especially at sunrise and sunset."
        },
        {
          title: "7. Shri Kshetra Pimpalgaon Joga",
          description: "This ancient temple dedicated to Lord Vishnu is a spiritual haven for devotees. The temple showcases stunning architecture, intricate carvings, and peaceful surroundings, making it a must-visit for spiritual seekers. It’s an ideal place for prayer and contemplation, surrounded by nature’s beauty."
        },
        {
          title: "8. Gadad Mandir",
          description: "A lesser-known gem, Gadad Mandir is dedicated to Lord Shiva and offers a serene atmosphere perfect for worship and reflection. The temple's simplicity and tranquility allow visitors to connect deeply with their spirituality, making it an ideal spot for those seeking solitude and peace."
        },
        {
          title: "9. Harihar Fort",
          description: "Harihar Fort is an exhilarating trekking destination known for its challenging trails and breathtaking views. The fort's ancient ruins tell stories of the past, while the panoramic vistas from the summit provide a sense of achievement for trekkers. It's an ideal spot for adventure enthusiasts looking to explore the beauty of nature."
        },
        {
          title: "10. Nashik",
          description: "Just a short drive away, Nashik is famous for its vineyards and wineries, offering wine-tasting tours that attract visitors from around the world. Additionally, Nashik hosts the Kumbh Mela festival, drawing millions for its spiritual significance. A day trip to Nashik provides a rich blend of culture, history, and natural beauty, making it an enriching experience."
        }
      ],      
      services: [
        {
          name: "Pune to Trimbakeshwar Tempo Traveller",
          description: "Planning a trip from Pune to Trimbakeshwar? Look no further! Shraddha Travels offers a premium Tempo Traveller service that ensures a comfortable and convenient journey to this spiritual destination. Our well-maintained vehicles are equipped with modern amenities, ensuring a smooth ride for groups seeking a memorable pilgrimage experience. Enjoy spacious seating, air conditioning, and entertainment options as you travel to one of India's most revered temples."
        },
        {
          name: "Pune to Trimbakeshwar One Way Tempo Traveller",
          description: "Whether you're heading to Trimbakeshwar for a day trip or a weekend getaway, our one-way Tempo Traveller service from Pune is designed to fit your schedule and budget. With this option, you can explore Trimbakeshwar without the hassle of planning a return trip, giving you the flexibility to immerse yourself fully in the spiritual experience. Enjoy the freedom to take your time and explore the surroundings at your leisure."
        },
        {
          name: "Pune to Trimbakeshwar Tempo Traveller Booking",
          description: "Booking your Tempo Traveller with Shraddha Travels is quick and easy! Simply contact us via phone or visit our website, where our friendly staff will guide you through the booking process. We understand that every trip is unique, so we offer personalized service to ensure your specific needs are met. Reserve your vehicle today and embark on a journey filled with devotion and serenity, knowing that everything is taken care of for you."
        },
        {
          name: "Pune Trimbakeshwar Tempo Traveller Rates",
          description: "We offer competitive rates for our Tempo Traveller services from Pune to Trimbakeshwar, ensuring that you get the best value for your money. Our pricing is transparent, with no hidden charges, so you can plan your budget confidently. Contact us for a detailed quote tailored to your requirements, and discover how our affordable travel options can enhance your pilgrimage experience without compromising on comfort."
        },
        {
          name: "Affordable Pune Trimbakeshwar Tempo Traveller",
          description: "At Shraddha Travels, we believe that quality travel should be accessible to everyone. That’s why we offer affordable Tempo Traveller options for your trip to Trimbakeshwar. Enjoy a comfortable ride without breaking the bank, making it possible for families and groups to embark on this spiritual journey together. Our commitment to affordability doesn't sacrifice quality, ensuring that you travel in comfort and style."
        },
        {
          name: "Pune to Nasik Trimbakeshwar Tempo Traveller",
          description: "Combine your visit to Trimbakeshwar with a stop in Nasik! Our Tempo Traveller service provides flexible travel options, allowing you to explore both destinations conveniently. Experience the best of spirituality and wine country in one trip, visiting famous vineyards and participating in wine tastings. This journey is perfect for those looking to blend cultural exploration with spiritual reflection."
        },
        {
          name: "From Pune to Trimbakeshwar by Tempo Traveller",
          description: "Traveling from Pune to Trimbakeshwar by Tempo Traveller ensures you enjoy a seamless journey. With ample space for your group and luggage, our vehicles are perfect for families and friends alike. Sit back, relax, and enjoy the scenic views along the way, as you pass through picturesque landscapes and vibrant local culture. This journey is not just about reaching a destination; it's about creating lasting memories together."
        },
        {
          name: "Tempo Traveller from Pune to Trimbakeshwar",
          description: "Choose Shraddha Travels for your Tempo Traveller needs from Pune to Trimbakeshwar. Our professional drivers are knowledgeable about the route and local attractions, ensuring you arrive safely and on time. Experience a hassle-free journey where your comfort and satisfaction are our top priorities. Enjoy the convenience of door-to-door service, allowing you to focus on your spiritual journey rather than logistics."
        },
        {
          name: "Pune to Trimbakeshwar Tempo Traveller on Rent",
          description: "Looking for a Tempo Traveller on rent for your journey to Trimbakeshwar? Shraddha Travels offers flexible rental options that cater to your specific needs. Whether you require a vehicle for a day or longer, we’ve got you covered. Our rental service includes options for guided tours, allowing you to make the most of your trip with insights from our experienced drivers who know the best spots to visit along the way."
        },
        {
          name: "Pune to Trimbakeshwar Tempo Traveller on Rent Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Pune to Trimbakeshwar Tempo Traveller on Rent. Our dedicated team is ready to assist you with your booking and answer any questions you may have. We ensure a smooth and enjoyable ride for all our customers, making your travel experience as comfortable as possible. Don't hesitate to reach out for personalized assistance!"
        }
      ],      
      tableData: [
        ['- Pune to Trimbakeshwar Tempo Traveller', '- Pune to Trimbakeshwar One Way Tempo Traveller'],
        ['- Pune to Trimbakeshwar Tempo Traveller Booking', '- Pune Trimbakeshwar Tempo Traveller Rates'],
        ['- Affordable Pune Trimbakeshwar Tempo Traveller', '- Pune to Nasik Trimbakeshwar Tempo Traveller'],
        ['- From Pune to Trimbakeshwar by Tempo Traveller', '- Tempo Traveller from Pune to Trimbakeshwar'],
        ['- Pune to Trimbakeshwar Tempo Traveller on Rent', '']
      ],      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels' Tempo Traveller on Rent in Pune?",
          WhyChoosedescription: "When it comes to renting a Tempo Traveller in Pune, Shraddha Travels is your trusted provider for a comfortable and reliable journey. Whether you're planning a family trip, a pilgrimage, or a corporate outing, our service ensures that you travel in style and comfort. With years of experience in the travel industry, we understand the needs of our customers and strive to deliver an exceptional travel experience. Here's why Shraddha Travels is the best choice for your trip:"
        },
        {
          WhyChooseheading: "Comfortable and Spacious Vehicles:",
          WhyChoosedescription: "At Shraddha Travels, we prioritize your comfort above all. Our Tempo Travellers come equipped with spacious interiors, reclining seats, and modern air conditioning systems, ensuring a pleasant journey for you and your group. The thoughtful layout allows everyone to relax and enjoy the ride, making even long trips enjoyable."
        },
        {
          WhyChooseheading: "Experienced Drivers:",
          WhyChoosedescription: "Our drivers are not only well-trained but also possess extensive knowledge about the best routes to Trimbakeshwar. They are committed to ensuring a safe and smooth ride, allowing you to sit back, relax, and enjoy the scenic beauty along the way. Their friendly demeanor and local insights enhance your travel experience."
        },
        {
          WhyChooseheading: "Flexible Rental Options:",
          WhyChoosedescription: "We understand that every journey is unique. That's why we offer flexible rental packages tailored to your specific needs, whether you're looking for a one-way trip, a round trip, or an extended rental. Our services are designed to fit your schedule and budget, providing you with the freedom to travel at your convenience."
        },
        {
          WhyChooseheading: "Affordable Pricing:",
          WhyChoosedescription: "At Shraddha Travels, we believe that high-quality service should be accessible to everyone. Our pricing is competitive, with no hidden charges, allowing you to plan your budget effectively. We provide excellent value for money, ensuring that you receive top-notch service without compromising on quality."
        },
        {
          WhyChooseheading: "Punctuality Guaranteed:",
          WhyChoosedescription: "We understand the importance of time, especially when planning a spiritual journey. Our commitment to punctuality ensures that you reach Trimbakeshwar on time, allowing you to participate in all the rituals and activities without stress. Our drivers are trained to manage travel times efficiently, so you can focus on your spiritual experience."
        },
        {
          WhyChooseheading: "Customer Satisfaction:",
          WhyChoosedescription: "Our top priority is customer satisfaction. We go above and beyond to exceed your expectations with our service, from the moment you book until the end of your trip. Our positive testimonials and repeat customers speak volumes about our dedication to providing an exceptional travel experience tailored to your needs."
        },
        {
          WhyChooseheading: "Easy Booking Process:",
          WhyChoosedescription: "Booking your Tempo Traveller with us is hassle-free and convenient. You can reserve your vehicle through our user-friendly website or by calling our dedicated customer service team. We provide clear information about pricing and options, making the booking process straightforward and efficient."
        },
        {
          WhyChooseheading: "24/7 Support:",
          WhyChoosedescription: "We offer round-the-clock customer support to address any queries or concerns you may have before, during, or after your journey. Whether you need assistance with your booking, have questions about your trip, or require support during your travels, our dedicated team is always here to help. Your comfort and safety are our utmost priorities."
        }
      ]
        }



        const faqData = [
            {
              question: "How do I book a Tempo Traveller for my trip to Trimbakeshwar?",
              answer: "You can easily book a Tempo Traveller by visiting our website or calling our customer service. Provide your travel details, and our team will assist you with the booking process."
            },
            {
              question: "What are the rates for renting a Tempo Traveller from Pune to Trimbakeshwar?",
              answer: "Our rates are competitive and vary based on the type of vehicle, rental duration, and additional services you may require. Contact us for a customized quote."
            },
            {
              question: "Is there a minimum rental period for the Tempo Traveller?",
              answer: "We offer flexible rental options, but we typically have a minimum rental period of 8 hours or 100 kilometers for our Tempo Travellers. Please inquire for specific details."
            },
            {
              question: "Are the drivers experienced and knowledgeable about the route?",
              answer: "Yes, all our drivers are experienced and well-acquainted with the route to Trimbakeshwar. They prioritize your safety and comfort throughout the journey."
            },
            {
              question: "Can we make stops along the way to Trimbakeshwar?",
              answer: "Absolutely! We can accommodate your requests for stops along the way for meals, sightseeing, or rest breaks. Just let us know your preferences when booking."
            },
            {
              question: "What should I do if I need to change my booking?",
              answer: "If you need to modify your booking, please contact us as soon as possible. We will do our best to accommodate your requests based on availability."
            },
            {
              question: "Do you provide 24/7 customer support?",
              answer: "Yes, we offer 24/7 customer support to address any questions or concerns you may have during your trip. Your satisfaction is our priority."
            }
          ];
          
          const testimonials = [
            {
              name: "Mr. Rahul Deshmukh",
              text: "Our journey from Pune to Trimbakeshwar with Shraddha Travels was absolutely fantastic! The Tempo Traveller was spacious and comfortable, making the long ride enjoyable for our entire family. Our driver was not only punctual but also very knowledgeable about the area, pointing out interesting sights along the way. We reached Trimbakeshwar relaxed and ready for our pilgrimage. Highly recommend Shraddha Travels for anyone looking for reliable and affordable travel services!"
            },
            {
              name: "Miss Sneha Patil",
              text: "I recently booked a Tempo Traveller for a group trip to Trimbakeshwar, and I couldn’t be happier with my choice. Shraddha Travels offered excellent service right from the booking process to the end of our trip. The vehicle was well-maintained, and our driver was courteous and attentive to our needs. We had a smooth and enjoyable journey, allowing us to focus on our spiritual experience. I will definitely choose Shraddha Travels again for our future trips!"
            }
          ];
          

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Pune to Trimbakeshwar Tempo Traveller on Rent Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune to Trimbakeshwar Tempo Traveller on Rent | Affordable Group Travel</title>
    <meta name="description" content="Rent a tempo traveller for your trip from Pune to Trimbakeshwar. Affordable rates and comfortable travel for groups." />
    <meta name="keywords" content="Pune to Trimbakeshwar Tempo Traveller, Tempo Traveller Booking Pune, Affordable Tempo Traveller Pune, Trimbakeshwar Trip Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to Trimbakeshwar Tempo Traveller on Rent | Affordable Group Travel" />
    <meta property="og:description" content="Book our tempo traveller for a comfortable journey from Pune to Trimbakeshwar. Ideal for family and group travel." />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-to-trimbakeshwar-tempo-traveller-rent" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/trimbakeshwar-tempo-traveller.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller Rental from Pune to Trimbakeshwar",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-to-trimbakeshwar-tempo-traveller-rent",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental from Pune to Trimbakeshwar",
                    "description": "Affordable tempo traveller rentals from Pune to Trimbakeshwar, perfect for family trips and group travel."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 3-05.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Pune to Trimbakeshwar Tempo Traveller on Rent</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetotrimbakeshwar;