
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Exploreallindia() {



  const cardData =
  {
    keyword: ' All India Bus Hire Services from Pune',
    heading: 'Shraddha Travels: All India Bus Hire Services from Pune',
    headingDescription: 'If you are planning an extensive journey across India and need reliable transportation from Pune, Shraddha Travels offers comprehensive bus hire services tailored to meet all your travel needs. Our All India Bus Hire Services are designed to provide comfort, safety, and flexibility, ensuring a seamless travel experience as you explore diverse destinations across the country. Here’s a look at our top All India Bus Hire Services from Pune:',

    topPlaces: [
        {
            title: "1. Pune to Mumbai Bus Hire",
            description: "Travel comfortably and efficiently between Pune and Mumbai with our well-maintained buses. Whether it’s for a corporate event, family visit, or sightseeing, our service ensures a smooth journey. Enjoy spacious seating, air conditioning, and onboard refreshments that keep you relaxed throughout the trip. With frequent departures and flexible timings, your travel plans can be accommodated with ease."
        },
        {
            title: "2. Pune to Goa Bus Rental",
            description: "Discover the vibrant beaches and lively culture of Goa with our customized bus rental services. Ideal for large groups and family vacations, our buses provide a comfortable ride with ample space for luggage and personal belongings. Relax with modern amenities and enjoy the scenic views along the way, making every moment of your getaway unforgettable. Our experienced drivers will ensure a safe and enjoyable journey, allowing you to focus on having fun."
        },
        {
            title: "3. Pune to Delhi Bus Hire",
            description: "Experience a hassle-free journey from Pune to the capital city of Delhi. Our long-distance buses are equipped with modern facilities, including reclining seats and entertainment systems, to make your travel enjoyable. Perfect for business trips or exploring historical landmarks in Delhi, we ensure you arrive refreshed and ready for your adventures. Our drivers are well-versed in the best routes, so you can enjoy a smooth and timely journey."
        },
        {
            title: "4. Pune to Bangalore Bus Service",
            description: "Travel to Bangalore, the Silicon Valley of India, with ease and comfort. Our buses are designed to handle long distances, featuring comfortable seating and amenities that provide a pleasant experience. With complimentary Wi-Fi and charging ports, you can stay connected during your journey. Whether you’re visiting for business or leisure, our service ensures you arrive ready for your activities, with knowledgeable drivers who can share local insights along the way."
        },
        {
            title: "5. Pune to Jaipur Bus Rental",
            description: "Discover the royal heritage of Jaipur with our efficient bus hire services. From the magnificent forts to vibrant bazaars, our buses offer a comfortable way to explore the Pink City. We cater to both small and large groups, providing ample space and amenities to enhance your travel experience. With our knowledgeable drivers, you can dive deep into the rich culture and history of Jaipur, making stops at key attractions along the way."
        },
        {
            title: "6. Pune to Hyderabad Bus Hire",
            description: "Enjoy a smooth ride from Pune to Hyderabad, diving into the rich culture and cuisine of the city. Our bus rental service ensures a comfortable journey with professional drivers who know the best routes and local attractions. Take in the sights during your trip, with the option to stop at interesting landmarks. With onboard refreshments and a comfortable environment, you’ll find that every part of your journey is enjoyable and hassle-free."
        },
        {
            title: "7. Pune to Ahmedabad Bus Service",
            description: "Whether you're visiting Ahmedabad for business or leisure, our bus hire services ensure a comfortable journey. Explore the vibrant city known for its stunning architecture and culinary delights with ease. Our buses are equipped with modern amenities to enhance your travel experience, and our dedicated staff is committed to providing top-notch service. Enjoy a smooth ride as you discover the rich culture and history of Ahmedabad."
        },
        {
            title: "8. Pune to Chennai Bus Rental",
            description: "Travel to Chennai with our well-equipped buses, offering a comfortable ride across states. Ideal for group travel, our service ensures that you experience the southern hospitality and cultural richness of Chennai without the stress of planning transportation. Enjoy onboard amenities and refreshments, allowing you to relax and socialize with fellow travelers. Our experienced drivers will guide you safely to your destination, making your trip enjoyable from start to finish."
        },
        {
            title: "9. Pune to Udaipur Bus Hire",
            description: "Explore the enchanting city of Udaipur, known for its stunning lakes and palaces, with our specialized bus hire services. Our luxurious buses are designed to provide a first-class experience for your journey, complete with comfortable seating and scenic views. Enjoy the beauty of Rajasthan as you travel, and let our knowledgeable drivers navigate the routes, stopping at key attractions along the way for a truly immersive experience."
        },
        {
            title: "10. Pune to Amritsar Bus Service",
            description: "Make your trip to Amritsar, home to the iconic Golden Temple, a memorable one with our efficient bus services. Our buses are equipped to ensure a pleasant journey, complete with modern amenities and comfortable seating. Experience the spiritual and cultural richness of Punjab as you travel, with knowledgeable drivers who can provide insights along the way. We aim to make your journey as enjoyable and hassle-free as possible, allowing you to focus on your spiritual exploration."
        }
    ],    
    services: [
        {
            name: "Pune to Kolhapur Bus Rental Service",
            description: "Searching for a reliable Pune to Kolhapur bus rental service? Whether you’re organizing a family trip, corporate outing, or simply a day of exploration, Shraddha Travels provides comfortable and efficient bus hire solutions. Our fleet includes luxury buses and affordable options, guaranteeing a smooth and enjoyable journey. Book your Pune to Kolhapur bus hire today for a stress-free travel experience!"
        },
        {
          "name": "All India Bus Hire Services from Pune",
          "description": "Looking for reliable and efficient All India Bus Hire Services from Pune? Shraddha Travels offers comprehensive bus rental solutions to destinations across the country. Whether it's a family vacation, corporate trip, or special event, our well-maintained buses ensure comfort and safety throughout your journey. From short trips to long-haul routes, our professional drivers and modern fleet provide a seamless travel experience. Book with Shraddha Travels today for top-notch bus rental services that cater to your every need."
        },
        {
            name: "Pune to Bhandardara Bus on Rent",
            description: "Discover the scenic beauty of Bhandardara with our Pune to Bhandardara bus rental service. Perfect for family vacations, corporate retreats, or school picnics, our buses are equipped for a comfortable ride. Enjoy picturesque landscapes while traveling in comfort and style with our reliable bus rental services. Contact Shraddha Travels for affordable and convenient options from Pune to Bhandardara."
        },
        {
            name: "Pune to Pandharpur Bus on Rent",
            description: "For a spiritual journey or family visit to Pandharpur, Shraddha Travels offers top-notch Pune to Pandharpur bus rental services. Our buses are perfect for temple visits, group tours, and special events. Enjoy a comfortable ride with ample space and amenities, making your trip from Pune to Pandharpur a pleasant experience. Book your bus hire with us and ensure a hassle-free pilgrimage."
        },
        {
            name: "Pune to Tuljapur Darshan Bus Hire",
            description: "Planning to visit the sacred Tuljapur temple? Our Pune to Tuljapur bus hire service provides a convenient and comfortable travel option. With our well-maintained buses and professional drivers, your journey to Tuljapur will be smooth and enjoyable. Ideal for groups and family trips, our service ensures a comfortable and memorable Darshan experience."
        },
        {
            name: "Pune to Jyotiba Bus Hire",
            description: "Experience the spiritual and scenic beauty of Jyotiba with our Pune to Jyotiba bus hire services. Whether it’s a pilgrimage or a group outing, we offer comfortable buses for your journey. Enjoy a seamless travel experience with our reliable and efficient bus rental options. Book with Shraddha Travels for a comfortable ride and exceptional service."
        },
        {
            name: "Pune to Nashik Darshan Bus Hire",
            description: "Discover the vibrant city of Nashik with our Pune to Nashik bus hire service. Perfect for Darshan, sightseeing, and group travel, our buses provide comfort and convenience for your journey. Explore Nashik with ease, thanks to our professional service and well-maintained buses. Choose Shraddha Travels for an enjoyable and hassle-free trip."
        },
        {
            name: "Pune to Trimbakeshwar Bus on Rent",
            description: "Embark on a spiritual journey to Trimbakeshwar with our Pune to Trimbakeshwar bus rental service. Our buses are ideal for temple visits, religious tours, and group travels. Enjoy a comfortable and smooth ride with our well-maintained vehicles and experienced drivers. Book your bus rental with Shraddha Travels for a memorable visit to Trimbakeshwar."
        },
        {
            name: "Pune to Bhimashankar Bus Tour Package",
            description: "Explore the sacred temple town of Bhimashankar with our Pune to Bhimashankar bus tour package. Our service offers a comfortable and efficient travel option for your visit. Perfect for religious tours, family trips, and corporate outings, our buses ensure a pleasant journey. Contact Shraddha Travels for a well-organized and enjoyable bus tour package."
        },
        {
            name: "Pune to Konkan Darshan Bus Package",
            description: "Discover the beauty of Konkan with our Pune to Konkan Darshan bus package. Ideal for family vacations, group tours, and corporate outings, our service provides a comfortable and convenient way to explore Konkan’s stunning landscapes and attractions. Enjoy a seamless travel experience with Shraddha Travels and make the most of your Konkan tour."
        },
        {
            name: "Pune to Sade Teen Shakti Peeth Package",
            description: "Experience the spiritual significance of the Sade Teen Shakti Peeths with our Pune to Sade Teen Shakti Peeth package. Our bus rental service offers a comfortable and organized way to visit these sacred sites. Perfect for religious tours and group travel, our buses ensure a smooth and enjoyable journey. Book with Shraddha Travels for a memorable pilgrimage."
        },
        {
            name: "5 Jyotirlinga Darshan from Pune Maharashtra",
            description: "Embark on a divine journey with our 5 Jyotirlinga Darshan from Pune Maharashtra service. Our bus rental options provide a comfortable and convenient way to visit these sacred Jyotirlingas. Ideal for spiritual tours and group outings, our service ensures a hassle-free and enjoyable travel experience. Choose Shraddha Travels for your Jyotirlinga Darshan."
        },
        {
            name: "Ashtavinayak Tour from Pune by Bus",
            description: "Explore the Ashtavinayak temples with our Ashtavinayak tour from Pune by bus. Our rental service offers a comfortable and reliable way to visit these important Ganpati temples. Perfect for group tours and family visits, our buses are equipped for a pleasant journey. Book with Shraddha Travels for an exceptional Ashtavinayak Darshan experience."
        },
        {
            name: "Pune Darshan Bus Hire on Rent",
            description: "Discover Pune's top attractions with our Pune Darshan bus hire service. Ideal for sightseeing tours, corporate outings, and school trips, our buses provide comfort and convenience for exploring Pune. Enjoy a comprehensive city tour with Shraddha Travels and make your Pune Darshan memorable."
        },
        {
            name: "Pune to Mumbai Darshan Bus On Rent",
            description: "Travel from Pune to Mumbai with ease using our Pune to Mumbai Darshan bus on rent service. Whether you're exploring Mumbai's landmarks or attending corporate events, our buses offer comfort and reliability. Choose Shraddha Travels for a seamless travel experience between Pune and Mumbai."
        },
        {
            name: "Pune to Nagpur Tadoba Darshan Bus on Rent",
            description: "Explore Tadoba's wildlife and natural beauty with our Pune to Nagpur Tadoba Darshan bus on rent service. Ideal for wildlife enthusiasts and group trips, our buses ensure a comfortable and enjoyable journey. Book with Shraddha Travels for a memorable Tadoba adventure."
        },
        {
            name: "Shraddha Travels Pune On Rent",
            description: "For all your bus rental needs in Pune, Shraddha Travels offers a range of services from local tours to long-distance travel. Our fleet includes various bus sizes and types to suit your requirements, ensuring a comfortable and reliable travel experience. Contact Shraddha Travels for exceptional bus rental services in Pune."
        },
        {
            name: "Pune to Hyderabad Mini Bus on Rent",
            description: "Traveling from Pune to Hyderabad? Our mini bus rental service offers a comfortable and convenient solution for your journey. Perfect for smaller groups, our mini buses provide a cozy and efficient travel experience between these two vibrant cities."
        },
        {
            name: "Pune to Goa 14, 17, 20, and 25 Seater Bus Hire",
            description: "Enjoy a seamless trip from Pune to Goa with our range of bus hire options. Choose from 14, 17, 20, and 25-seater buses to fit your group size and travel needs. Our well-maintained buses ensure a comfortable journey to the beautiful beaches and attractions of Goa."
        },
        {
            name: "Pune to Karnataka Bus Hire for Rent",
            description: "Explore Karnataka with our reliable bus hire service from Pune. Whether you’re planning a trip to Bengaluru, Mysuru, or other destinations in Karnataka, our buses offer comfort and convenience for your travel needs."
        },
        {
            name: "Pune to Maharashtra Darshan Bus on Rent",
            description: "Embark on a spiritual journey across Maharashtra with our Maharashtra Darshan bus rental service. Visit key religious sites and historical landmarks with ease, thanks to our comfortable and well-equipped buses."
        },
        {
            name: "5, 40, 45, 50 Seater Bus Rental for Agra from Pune",
            description: "For a trip to Agra from Pune, we offer flexible bus rental options including 5, 40, 45, and 50-seater buses. Choose the size that best suits your group and enjoy a smooth journey to see the iconic Taj Mahal and other attractions in Agra."
        },
        {
            name: "Pan India Bus Service from Pune",
            description: "Shraddha Travels provides comprehensive Pan India bus services from Pune. Whether you're traveling to major cities or remote destinations across India, our extensive network and well-maintained fleet ensure a reliable and comfortable travel experience."
        },
        {
            name: "Shraddha Travels Pune On Rent",
            description: "For all your travel needs, including interstate and local bus rentals, Shraddha Travels Pune offers exceptional service. From mini buses to large coaches, we have a range of options to suit your group size and travel preferences. Trust us for a seamless and enjoyable journey."
        },
        {
            name: "Explore All India Bus Hire Services from Pune",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Explore All India Bus Hire Services from Pune. We ensure a smooth and enjoyable ride for all our customers."
        }
    ],    
    tableData: [
        ['- Pune to Somnath Gujarat Bus On Rent', '- Pune to Badrinath Bus Hire on Rent'],
        ['- Pune to Kedarnath Bus Hire on Rent', '- Pune to Madhya Pradesh Bus for Trip'],
        ['- Pune to Mysore Ooty Bus Tour Package', '- Pune Dev Darshan Bus for Trip'],
        ['- Pune to Nashik Darshan Bus Hire', '- Pune to Rajasthan Bus On Rent'],
        ['- Bus On Hire in Ujjain', '- Best Traveller Bus On Rent'],
        ['- Pune to 12 Jyotirlinga Darshan Tour Package', '- 7 Jyotirlinga Tour Package Bus Hire in Pune'],
        ['- Pune to All India Bus Hire', '- Pune to Hyderabad Mini Bus on Rent'],
        ['- Pune to Goa 14, 17, 20, 25 Seater Bus Hire', '- Pune to Karnataka Bus Hire for Rent'],
        ['- Pune to Maharashtra Darshan Bus On Rent', '- 5, 40, 45, 50 Seater Bus Rental for Agra from Pune'],
        ['- Pan India Bus Service From Pune', '- Shraddha Travels Pune On Rent']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for All India Bus Hire Services from Pune?",
            WhyChoosedescription: "When it comes to bus hire services, Shraddha Travels stands out as your trusted partner for a comfortable and reliable journey. Whether you’re planning a pilgrimage, sightseeing trip, family vacation, or corporate outing, here’s why Shraddha Travels is the best choice for your travel needs:"
        },
        {
            WhyChooseheading: "Wide Coverage Across India:",
            WhyChoosedescription: "Our extensive network covers every corner of India, ensuring that you can reach your desired destination with ease, no matter where it is."
        },
        {
            WhyChooseheading: "Comfortable & Modern Fleet:",
            WhyChoosedescription: "Our buses are equipped with modern amenities, including air-conditioned interiors, reclining seats, and ample legroom, prioritizing your comfort on both short and long journeys."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedescription: "Safety is our priority. Our experienced drivers are trained to handle various routes, ensuring a safe and efficient journey for all passengers."
        },
        {
            WhyChooseheading: "Affordable Pricing:",
            WhyChoosedescription: "We offer competitive pricing with transparent billing and no hidden charges, making quality services accessible without straining your budget."
        },
        {
            WhyChooseheading: "Customized Travel Packages:",
            WhyChoosedescription: "We tailor our travel packages to meet your specific needs, whether for corporate events, family trips, or special occasions."
        },
        {
            WhyChooseheading: "24/7 Customer Support:",
            WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist with bookings, inquiries, and any concerns, ensuring a seamless experience."
        },
        {
            WhyChooseheading: "Eco-Friendly Travel Option:",
            WhyChoosedescription: "By choosing our bus hire services, you contribute to a greener environment, as group travel reduces the number of individual vehicles on the road."
        },
        {
            WhyChooseheading: "On-Time Service:",
            WhyChoosedescription: "We prioritize punctuality with timely departures and arrivals, ensuring your schedule stays on track and providing a hassle-free travel experience."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What types of buses are available for hire?",
        answer: "We offer a range of buses, including luxury coaches, AC and non-AC buses, mini-buses, and sleeper coaches to cater to different group sizes and preferences."
    },
    {
        question: "Can I book a bus for an outstation trip across India?",
        answer: "Yes, Shraddha Travels provides All India permit bus hire services, allowing you to travel comfortably to any destination within India."
    },
    {
        question: "How far in advance should I book a bus?",
        answer: "We recommend booking as early as possible, especially during peak seasons or for special events, to ensure availability and preferred bus options."
    },
    {
        question: "What safety measures are in place on your buses?",
        answer: "All our buses undergo regular maintenance, and we follow strict safety protocols. Additionally, our drivers are trained in defensive driving to ensure a safe journey."
    },
    {
        question: "Is there flexibility in pick-up and drop-off locations?",
        answer: "Yes, we offer flexible pick-up and drop-off points within Pune and can arrange customized routes as per your requirements."
    },
    {
        question: "Can we make multiple stops during our journey?",
        answer: "Absolutely. You can discuss the planned route and stops with our team while booking, and we’ll arrange the trip accordingly."
    },
    {
        question: "How do I make a reservation for bus hire?",
        answer: "You can book directly through our website, call our customer service, or visit our office in Pune. We offer a seamless booking process to make it convenient for you."
    },
    {
        question: "What amenities are available on the bus?",
        answer: "Our buses come equipped with amenities like air conditioning, comfortable seats, luggage storage, and, on request, additional facilities like WiFi, charging points, and entertainment systems."
    },
    {
        question: "Do you provide drivers with local knowledge?",
        answer: "Yes, our drivers are experienced and well-versed with routes and local areas across India, ensuring a smooth travel experience."
    },
    {
        question: "Are there any hidden charges?",
        answer: "No, Shraddha Travels believes in transparency. Our pricing is straightforward, with no hidden fees. Any additional costs will be communicated upfront."
    }
];

const testimonials = [
    {
        name: "Mr. Amit S.",
        text: "Our experience with Shraddha Travels for an all-India bus hire was outstanding! We were a large group on a pilgrimage tour across multiple states, and the level of comfort, safety, and professionalism was exceptional. The bus was spacious and clean, equipped with comfortable seating, and the driver was skilled and courteous. The journey was smooth and enjoyable, allowing us to focus on our trip rather than the logistics. Highly recommended for anyone looking for reliable and comfortable travel across India!"
    },
    {
        name: "Miss Priya M.",
        text: "Choosing Shraddha Travels for our all-India family vacation was the best decision! From Pune to the beaches of Goa and all the way to Rajasthan’s forts, the entire experience was seamless. The bus was modern and had all the amenities we needed for long travel. The team was professional, on time, and kept us informed at every step. Our driver was friendly, well-versed with the routes, and ensured our comfort throughout the journey. Thank you, Shraddha Travels, for a fantastic travel experience!"
    }
];

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Explore All India Bus Hire Services From Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>All India Bus Hire Services from Pune | Affordable & Reliable Transport | Shraddha Travels</title>
    <meta name="description" content="Book All India Bus Hire Services from Pune with Shraddha Travels. Offering trips to destinations like Somnath, Badrinath, Goa, Rajasthan, and more. Affordable and reliable transport options available." />
    <meta name="keywords" content="All India Bus Hire Services from Pune, Bus Rental from Pune, Pune to Somnath, Pune to Badrinath, Pune to Goa, Pune to Rajasthan, Affordable Bus Hire Pune, All India Bus Hire, Bus Service Pune" />

    <meta property="og:title" content="All India Bus Hire Services from Pune | Shraddha Travels | Affordable & Reliable Transport" />
    <meta property="og:description" content="Explore our All India Bus Hire Services from Pune, including trips to popular destinations like Somnath, Badrinath, Mysore, Rajasthan, and more. Affordable, reliable, and convenient." />
    <meta property="og:url" content="https://www.shraddhatravels.com/all-india-bus-hire" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/all-india-bus-hire.jpg" />

    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "All India Bus Hire Services from Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/all-india-bus-hire",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "All India Bus Hire Services from Pune",
                    "description": "Reliable and affordable bus rental services for all major Indian destinations, including religious and tourist tours from Pune."
                }
            }
        }
        `}
    </script>
</Helmet>


      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 4-06.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs: Explore All India Bus Hire Services from Pune - Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Exploreallindia;