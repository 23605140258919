
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Busonrentinpune() {



  const cardData =
  {
    keyword: ' Bus on Rent in Pune ',
    heading: 'Shraddha Travels: Bus on Rent in Pune ',
    headingDescription: 'If you’re looking for reliable and affordable bus rental services in Pune, Shraddha Travels has you covered. We offer a diverse fleet of buses tailored to various needs, from corporate events to school trips and private tours. Here is a guide to our top bus rental options in Pune that ensure comfort, safety, and convenience for every journey.',

    topPlaces: [
      {
        title: "1. Luxury Bus on Rent in Pune",
        description: "Experience the epitome of comfort with our luxury bus rentals in Pune. Ideal for corporate events, weddings, or high-profile gatherings, our luxury buses come equipped with plush seating, modern amenities, and exceptional service. Each vehicle ensures a serene travel experience with entertainment systems, refreshments, and more, making your journey as memorable as the destination."
      },
      {
        title: "2. 40-Seater Bus on Rent in Pune",
        description: "Catering to large groups, our 40-seater buses are perfect for corporate outings, family gatherings, or group tours in Pune. With spacious seating, air conditioning, and large windows, passengers enjoy a relaxed and enjoyable ride. These buses are designed for comfort and convenience, ensuring that your journey is smooth and hassle-free."
      },
      {
        title: "3. 32-Seater Bus on Rent in Pune",
        description: "For medium-sized groups, our 32-seater buses offer the perfect blend of space and affordability. These buses are ideal for school trips, day picnics, or group events in Pune. With comfortable seating, ample legroom, and modern amenities, passengers can enjoy a seamless travel experience. Our drivers are skilled and prioritize punctuality, ensuring timely arrivals."
      },
      {
        title: "4. 17-Seater Mini Bus on Rent in Pune",
        description: "Perfect for small groups, our 17-seater mini buses provide an efficient travel solution in Pune. Whether it's a family outing or a corporate meeting, these mini buses ensure a cozy and enjoyable ride. With essential amenities and well-maintained interiors, they cater to all your travel requirements with ease."
      },
      {
        title: "5. Tempo Traveller on Rent in Pune",
        description: "Our tempo travellers are versatile and cater to diverse travel needs in Pune. Whether you're planning a day trip or a longer journey, these vehicles offer space for both passengers and luggage. With air conditioning, reclining seats, and ample storage, they ensure a comfortable and convenient travel experience."
      },
      {
        title: "6. Mini Bus on Rent in Pune",
        description: "For compact yet comfortable travel, our mini buses are the ideal choice in Pune. Designed for smaller groups, they offer convenience and ease of movement in urban traffic without compromising on passenger comfort. These buses are perfect for short trips or local excursions, ensuring a smooth ride."
      },
      {
        title: "7. Luxury Mini Bus on Rent in Pune",
        description: "Our luxury mini buses in Pune offer a premium travel experience for small groups. With plush interiors, advanced entertainment systems, and modern amenities, these buses are perfect for special occasions or business events. Travel in style and comfort, making your journey as delightful as your destination."
      },
      {
        title: "8. Bus on Rent in Kothrud",
        description: "Residents and businesses in Kothrud can benefit from our reliable bus rental services. Whether it's for local trips, corporate events, or personal gatherings, our buses ensure timely and efficient transportation. Equipped with the latest amenities, we prioritize safety and customer satisfaction for all journeys."
      },
      {
        title: "9. Bus on Rent in Hadapsar",
        description: "Our specialized bus rental services in Hadapsar cater to both local and outstation trips. With a fleet of well-maintained buses, we provide a smooth and comfortable travel experience. Whether for corporate needs or personal events, we ensure punctuality and customer satisfaction at every step."
      },
      {
        title: "10. Bus on Rent in Pimpri Chinchwad",
        description: "For group travel in Pimpri Chinchwad, our bus rental services are tailored to meet all your needs. From large family gatherings to corporate outings, we offer a variety of bus sizes and types. With a focus on safety, comfort, and reliability, your travel experience will be enjoyable and stress-free."
      }
    ],

      services: [
  {
    name: "Pune to Goa Mini Bus on Rent",
    description: "Experience the scenic journey from Pune to Goa with our mini bus rentals. Ideal for small to medium-sized groups, our mini buses are equipped with comfortable seating and modern amenities. Enjoy a spacious interior and large windows for stunning views along the route, ensuring a smooth and enjoyable trip for all passengers."
  },
  {
    name: "Pune to Goa Tempo Traveller on Rent",
    description: "For a more flexible travel experience, opt for our tempo travellers. Perfect for group trips to Goa, these vehicles offer ample space for both passengers and luggage. With comfortable seating, air conditioning, and a professional driver, your journey will be enjoyable and hassle-free, allowing you to focus on the fun ahead."
  },
  {
    name: "Shraddha Travels Pune",
    description: "Shraddha Travels is your trusted partner for reliable bus rental services from Pune to Goa. Our extensive fleet includes a variety of vehicles to suit every group size and budget. With exceptional service and a commitment to customer satisfaction, we ensure a comfortable and enjoyable travel experience from start to finish."
  },
  {
    name: "Pune to Goa Urbania on Rent",
    description: "Travel in style with our Urbania buses, which are designed for comfort and luxury. Equipped with modern amenities such as reclining seats, entertainment systems, and Wi-Fi, these buses provide an upscale travel experience for your journey from Pune to Goa. Enjoy the comfort of a spacious interior as you relax and take in the beautiful scenery."
  },
  {
    name: "Pune to Goa 17 Seater on Rent",
    description: "Our 17-seater buses are perfect for small groups traveling from Pune to Goa. These buses provide cozy and comfortable seating, along with sufficient space for everyone’s belongings. Ideal for family trips or outings with friends, our 17-seater ensures that you travel together comfortably, making memories along the way."
  },
  {
    name: "Pune to Goa 14, 17, 25, 32 Seater on Rent",
    description: "Choose from a range of bus sizes tailored to suit your group’s needs. Whether you require a 14-seater, 17-seater, 25-seater, or 32-seater, we have the perfect vehicle for your trip to Goa. Each bus is well-maintained and designed for passenger comfort, allowing your group to travel together with ease."
  },
  {
    name: "Pune to Goa 35, 45, 50 Seater on Rent",
    description: "For larger groups, our 35-seater, 45-seater, and 50-seater buses provide ample space and comfort. Enjoy a spacious and relaxing journey from Pune to Goa, complete with air conditioning and comfortable seating. Our professional drivers ensure a safe and smooth ride, so you can enjoy your time with friends or colleagues."
  },
  {
    name: "Pune to Goa Bus Package on Rent",
    description: "Opt for our comprehensive bus packages for your trip from Pune to Goa. These packages offer great value, including not just transportation but also additional services like refreshments, sightseeing options, and guided tours. We handle all the logistics so you can focus on enjoying your trip."
  },
  {
    name: "Hire Pune to Goa Mini Bus & Tempo Traveller on Rent",
    description: "Combine the flexibility of tempo travellers with the comfort of mini buses. Our rental options cater to various group sizes and preferences for your journey from Pune to Goa. Whether you’re planning a fun family getaway or a corporate outing, we have the right vehicle to meet your needs."
  },
  {
    name: "Hire Tempo Traveller Minibus",
    description: "For a versatile travel solution, consider hiring a tempo traveller or mini bus. These options provide comfort and space for your group while traveling from Pune to Goa. Equipped with modern amenities and safety features, our vehicles ensure that you enjoy a pleasant journey, no matter the distance."
  },
  {
    name: "Bus on Rent in Pune",
    description: "Shraddha Travels offers a wide range of buses for rent in Pune, catering to various travel needs. Whether you need a bus for a local trip, a wedding, or an outstation journey, we have the right vehicle for you. Our well-maintained fleet and professional drivers ensure that your travel experience is safe and enjoyable."
  },
  {
    name: "Pune to Goa Bus Hire on Rent",
    description: "Our bus hire services for the Pune to Goa route ensure a smooth and comfortable journey. Choose from our fleet of well-maintained buses, equipped with air conditioning and modern seating. Our experienced drivers are familiar with the route, ensuring you arrive at your destination safely and on time."
  },
  {
    name: "Pune to Goa AC Bus on Rent",
    description: "Travel in comfort with our AC buses for your journey from Pune to Goa. Our air-conditioned buses provide a cool and pleasant environment, making your trip more enjoyable. With spacious seating and entertainment options, you can relax and unwind as you travel to your destination."
  },
  {
    name: "Pune to Goa Bus Rental Service",
    description: "Shraddha Travels provides top-notch bus rental services for trips from Pune to Goa. Our professional drivers and well-maintained buses ensure a seamless and pleasant travel experience. Whether it’s a family vacation, a corporate trip, or a group outing, we are committed to making your journey enjoyable."
  }
],

      tableData: [
        ['- Pune to Goa Mini Bus on Rent', '- Pune to Goa Tempo Traveller on Rent'],
        ['- Shraddha Travels Pune', '- Pune to Goa Urbania on Rent'],
        ['- Pune to Goa 17 Seater on Rent', '- Pune to Goa 14, 17, 25, 32 Seater on Rent'],
        ['- Pune to Goa 35, 45, 50 Seater on Rent', '- Pune to Goa Bus Package on Rent'],
        ['- Hire Pune to Goa Mini Bus & Tempo Traveller on Rent', '- Hire Tempo Traveller Minibus'],
        ['- Bus on Rent in Pune', '- Pune to Goa Bus Hire on Rent'],
        ['- Pune to Goa AC Bus on Rent', '- Pune to Goa Bus Rental Service'],
        ['- Hire a Bus from Pune to Goa', '- Pune to Goa Holiday Bus Rental'],
        ['- Pune to Goa Family Trip Bus Hire', '- Pune to Goa Weekend Getaway Bus Rental'],
        ['- Pune to Goa Corporate Bus Hire', '- Pune to Goa Group Travel Bus Rental'],
        ['- AC Bus Hire Pune to Goa', '- Comfortable Bus from Pune to Goa'],
        ['- 32-Seater Bus Rental Pune to Goa', '- 50-Seater Bus Hire from Pune to Goa'],
        ['- Tempo Traveller on Rent in Pune for Corporate Events', '- Luxury Tempo Traveller on Rent in Pashan Pune']
      ],      
       "whychoose": [
    {
      "WhyChooseheading": "Why Choose Shraddha Travels for Bus on Rent in Pune?",
      "WhyChoosedescription": "Shraddha Travels offers premium bus rental services in Pune, ensuring your travel needs are met with the highest standards of quality and reliability. Whether you’re planning a corporate retreat, a wedding, or a school trip, we provide the best-in-class buses equipped with modern amenities and professional drivers for a seamless journey."
    },
    {
      "WhyChooseheading": "Extensive Fleet Options:",
      "WhyChoosedescription": "We provide an extensive range of buses to cater to diverse group sizes and occasions. From luxurious AC coaches with premium features to budget-friendly non-AC options, our fleet ensures that we have the perfect bus for your travel needs, be it a local tour or an outstation trip."
    },
    {
      "WhyChooseheading": "Comfort & Safety:",
      "WhyChoosedescription": "Our buses are designed to offer unparalleled comfort and safety. Featuring spacious seating, climate control, and entertainment systems, they ensure a pleasant journey. We prioritize safety with regular maintenance, GPS tracking, and compliance with all safety regulations to give you peace of mind during your travels."
    },
    {
      "WhyChooseheading": "Experienced & Professional Drivers:",
      "WhyChoosedescription": "Our team of professional drivers is well-versed in navigating Pune’s roads and beyond. With their expertise and friendly demeanor, they ensure a smooth, punctual, and enjoyable travel experience. Safety and customer satisfaction are their top priorities, making your journey worry-free."
    },
    {
      "WhyChooseheading": "Affordable & Transparent Pricing:",
      "WhyChoosedescription": "We offer competitive pricing with no hidden charges, making quality bus rentals accessible to all. Whether it’s a short city commute or a long-distance journey, our pricing ensures great value for money while delivering top-notch service."
    },
    {
      "WhyChooseheading": "Customizable Packages:",
      "WhyChoosedescription": "Shraddha Travels understands that every travel requirement is unique. We offer tailor-made packages to suit your itinerary, group size, and budget. From wedding transportation to sightseeing tours, we create solutions that align perfectly with your needs."
    },
    {
      "WhyChooseheading": "24/7 Customer Support:",
      "WhyChoosedescription": "Our dedicated support team is available round-the-clock to assist with bookings, route planning, and any other inquiries. With our 24/7 availability, you can count on us for a smooth and hassle-free travel experience, no matter the time of day."
    },
    {
      "WhyChooseheading": "Punctuality and Reliability:",
      "WhyChoosedescription": "We value your time and ensure timely departures and arrivals. Our commitment to punctuality and reliable service means you can relax and focus on your journey without worrying about delays or disruptions."
    }
  ]
      
  }


  const faqData = [
    {
      question: "What types of buses are available for rent?",
      answer: "We offer a variety of buses including luxury coaches, semi-luxury buses, AC/non-AC buses, and mini-buses to accommodate different group sizes and preferences."
    },
    {
      question: "How many passengers can your buses accommodate?",
      answer: "Our buses can accommodate anywhere between 18 to 50 passengers, depending on the model you choose."
    },
    {
      question: "Can I book a bus for an outstation trip?",
      answer: "Yes, we provide bus rental services for both local travel within Pune and outstation trips across Maharashtra and beyond."
    },
    {
      question: "How do I make a booking for a bus on rent?",
      answer: "You can book a bus through our website, call our customer service hotline, or visit our office. We recommend booking in advance, especially during peak seasons."
    },
    {
      question: "Do your buses come with a driver?",
      answer: "Yes, all our buses come with professional, experienced drivers who are familiar with local and long-distance routes to ensure a safe and smooth journey."
    },
    {
      question: "What are your payment options?",
      answer: "We accept multiple payment methods, including online transfers, credit/debit cards, and cash payments."
    },
    {
      question: "Are there any additional charges for fuel or tolls?",
      answer: "Our pricing is transparent, and any additional charges such as fuel and tolls will be clearly mentioned upfront during the booking process."
    },
    {
      question: "Can I rent a bus for a few hours?",
      answer: "Yes, we offer flexible rental options, whether you need a bus for a few hours, a full day, or an extended trip."
    },
    {
      question: "Is there a cancellation policy?",
      answer: "Yes, we have a cancellation policy in place. You can cancel your booking; however, cancellation charges may apply depending on the timing of the cancellation."
    }
  ];
  

  const testimonials = [
    {
      name: "Mr. Rahul Mehta",
      text: "We booked a luxury bus from Shraddha Travels for our corporate event, and the experience was fantastic! The bus was extremely comfortable, spacious, and well-maintained. The driver was very professional and navigated through the city smoothly. The entire team at Shraddha Travels ensured that our group was well taken care of from start to finish. I highly recommend their bus rental service in Pune for any occasion!"
    },
    {
      name: "Miss Sneha Kulkarni",
      text: "Shraddha Travels made our family trip to Lonavala truly enjoyable! We rented a bus for 30 people, and the service exceeded our expectations. The booking process was easy, the rates were very reasonable, and the bus arrived right on time. Our driver was friendly, skilled, and knew the best routes. We felt safe and comfortable throughout the journey. I will definitely use their services again for future trips!"
    }
  ];
  

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Bus On Rent in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Bus On Rent in Pune | Shraddha Travels | Reliable Bus Rentals</title>
    <meta name="description" content="Looking for a bus on rent in Pune? Shraddha Travels offers comfortable and affordable bus rental services for group travel, weddings, corporate events, and more." />
    <meta name="keywords" content="Bus On Rent in Pune, Bus Rental Services in Pune, Affordable Bus Hire Pune, Wedding Bus Rental Pune, Corporate Bus Rentals Pune, Shraddha Travels" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Bus On Rent in Pune | Shraddha Travels | Affordable Bus Rentals" />
    <meta property="og:description" content="Get reliable and comfortable bus rental services in Pune for family trips, corporate events, and special occasions. Contact Shraddha Travels today!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/bus-on-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/bus-rental-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus On Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/bus-on-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental Services in Pune",
                    "description": "Affordable and comfortable bus rental services in Pune for group travel, family outings, corporate events, and more."
                }
            }
        }
        `}
    </script>
</Helmet>


      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 4-08.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for “Bus on Rent in Pune” – Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Busonrentinpune;