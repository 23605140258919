

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Seaterbus14() {



  const cardData =
  {
    keyword: '14 Seater Tempo Traveller on Rent',
    heading: '14 Seater Tempo Traveller on Rent: Shraddha Travels',
    headingDescription: 'Are you planning a group outing or a family trip and looking for a reliable and comfortable mode of transportation? Shraddha Travels offers the 14 Seater Tempo Traveller on Rent, perfectly designed to accommodate your needs. Whether you’re heading for a weekend getaway, a corporate retreat, or a family vacation, our 14-seater tempo travellers provide ample space and comfort for everyone.',
    top: 'Top Reasons to Rent a 14-Seater Tempo Traveller from Shraddha Travels',

    topPlaces: [
        {
            title: "1. Perfect for Medium-Sized Groups",
            description: "Our 14-seater tempo traveller is ideal for family trips, corporate outings, and group adventures. It allows everyone to travel together comfortably, fostering camaraderie and reducing the stress of coordinating separate vehicles. With enough room for luggage, snacks, and personal belongings, you can focus on making memories rather than logistics."
        },
        {
            title: "2. Spacious Interior",
            description: "Enjoy a spacious interior designed for comfort. With generous seating and ample legroom, our tempo travellers ensure that all passengers can relax during their journey. Whether it's a short ride or a long-distance trip, the ergonomic seating and thoughtful layout make it perfect for families and friends, allowing you to stretch out and enjoy the ride."
        },
        {
            title: "3. Affordable Group Travel",
            description: "Renting a tempo traveller can be significantly more economical than booking multiple cars. By sharing fuel and parking costs, your overall travel expenses are reduced, allowing for more spending on activities and dining at your destination. This cost-effective solution ensures you can travel together without breaking the bank."
        },
        {
            title: "4. Customizable Itineraries",
            description: "Tailor your travel plans to fit your group's unique needs with flexible pick-up and drop-off options. This allows you to design your own journey, whether you're visiting specific attractions, making spontaneous stops for photos, or adjusting your schedule as you go. Personalizing your itinerary enhances the overall travel experience, making it more memorable and enjoyable."
        },
        {
            title: "5. Well-Maintained Vehicles",
            description: "Our tempo travellers undergo regular maintenance checks to ensure reliability and safety. We take pride in providing vehicles that are not only clean and well-maintained but also equipped with the latest safety features. This commitment to quality ensures a worry-free journey, giving you peace of mind while you travel."
        },
        {
            title: "6. Experienced Drivers",
            description: "Our professional drivers are highly trained and possess extensive knowledge of local routes and traffic patterns. Their expertise ensures a smooth and safe ride, allowing you to relax and enjoy the scenery. Additionally, our drivers are courteous and attentive, providing excellent service throughout your journey."
        },
        {
            title: "7. Entertainment Amenities",
            description: "Many of our tempo travellers come equipped with entertainment options, such as music systems and screens for movies or presentations. This feature enhances your travel experience, making long journeys enjoyable and keeping passengers entertained. Whether you're on a family trip or a corporate outing, these amenities help create a lively and engaging atmosphere."
        },
        {
            title: "8. Easy Booking Process",
            description: "Our straightforward booking system simplifies the process of reserving a 14-seater tempo traveller. With 24/7 support available, you can easily make reservations, ask questions, and get assistance whenever you need it. Our dedicated team is here to ensure your booking experience is seamless and hassle-free from start to finish."
        },
        {
            title: "9. Safety First",
            description: "We prioritize passenger safety above all else. Our vehicles comply with all safety regulations and are equipped with necessary safety features, including seat belts and emergency exits. Regular inspections and trained drivers follow strict safety protocols, ensuring you can travel with confidence, knowing that your well-being is our top concern."
        },
        {
            title: "10. Positive Customer Feedback",
            description: "Our commitment to exceptional service has earned us a loyal customer base. Many clients return for their travel needs, reflecting our dedication to quality and customer satisfaction in every journey. We value feedback and continuously strive to improve our services, ensuring that each trip is a delightful experience for our passengers."
        }
    ],
              
    services: [
        {
            name: "14 Seater Tempo Traveller on Rent Near Hadapsar, Pune",
            description: "If you’re located near Hadapsar, Pune, and looking for a 14 Seater Tempo Traveller on Rent, Shraddha Travels is your go-to solution. Our services are easily accessible in the Hadapsar area, ensuring a quick and convenient booking experience. We pride ourselves on our prompt service and dedication to customer satisfaction, making your travel plans hassle-free."
        },
        {
            name: "14-Seater Bus on Rent in Pune",
            description: "Looking for a 14-Seater Bus on Rent in Pune? Shraddha Travels offers a fleet of well-maintained buses for small to medium-sized groups. Perfect for corporate outings, family picnics, or trips to tourist destinations, our 14-seater buses guarantee a comfortable journey tailored to your group's needs."
        },
        {
            name: "Hire 14-Seater Minibus for Picnic",
            description: "Planning a picnic? Hire our 14-Seater Minibus to make your outing hassle-free. With ample space and comfortable seating, our minibuses are perfect for day trips to nearby picnic spots, allowing you to enjoy your time together without worrying about transport."
        },
        {
            name: "14-Seater Bus Rental in Pune",
            description: "Our 14-Seater Bus Rental in Pune service offers flexibility and convenience for your travel needs. Whether you require the bus for a few hours or a full day, we cater to your schedule, ensuring you have a smooth and enjoyable experience throughout your journey."
        },
        {
            name: "13-Seater Bus Hire in Pune",
            description: "For slightly smaller groups, our 13-Seater Bus Hire in Pune provides an excellent choice. Enjoy the same level of comfort and service in a vehicle designed for a cozy travel experience, perfect for family outings or group adventures."
        },
        {
            name: "14-Seater Mini Bus Rental for Corporate Events",
            description: "Our 14-Seater Mini Bus Rental is ideal for corporate events, ensuring timely and professional transportation for employees to conferences, meetings, and team-building activities. We prioritize punctuality and quality service to enhance your corporate outings."
        },
        {
            name: "14 Seater Tempo Traveller Per Km Rate in Pune",
            description: "We offer transparent and competitive rates for our 14 Seater Tempo Traveller, starting at approximately ₹25-₹35 per km. Enjoy high-quality service without hidden fees, ensuring great value for your rental needs."
        },
        {
            name: "14-Seater AC Bus Rental for Picnic",
            description: "Our 14-Seater AC Bus Rental for Picnic ensures a refreshing travel experience on hot summer days. With a fully air-conditioned interior, your comfort is our priority, allowing you to enjoy your day trip without the heat."
        },
        {
            name: "13-Seater Non-AC Bus on Rent",
            description: "Looking for a budget-friendly option? Our 13-Seater Non-AC Bus on Rent provides essential amenities at a lower cost, making it ideal for short trips and outings while keeping your travel expenses in check."
        },
        {
            name: "Pune to Goa 14-Seater AC Bus Rental",
            description: "Planning a trip to Goa? Our Pune to Goa 14-Seater AC Bus Rental ensures a comfortable ride with all the necessary amenities for a fun trip to the beach. Enjoy a smooth journey with family or friends while we take care of the travel details."
        },
        {
            name: "Pune to Mahabaleshwar 14 Seater Tempo Traveller",
            description: "Experience the scenic beauty of Mahabaleshwar with our Pune to Mahabaleshwar 14 Seater Tempo Traveller service. Enjoy the journey while we handle all logistics, ensuring a stress-free trip to one of Maharashtra's most popular hill stations."
        },
        {
            name: "Pune to Shirdi 14-Seater Bus on Rent",
            description: "Travel to the sacred city of Shirdi comfortably with our Pune to Shirdi 14-Seater Bus on Rent. Our buses are equipped for a smooth and peaceful journey, ensuring you arrive at your destination ready to explore."
        },
        {
            name: "Pune to Shirdi Tempo Traveller Hire",
            description: "Hire a Tempo Traveller for your Pune to Shirdi trip. Enjoy the convenience and comfort of our well-maintained vehicles as you visit one of Maharashtra’s most revered pilgrimage sites, making your journey spiritually fulfilling."
        },
        {
            name: "Pune Airport to Lonavala Tempo Traveller on Rent",
            description: "Arriving at Pune Airport? Our Pune Airport to Lonavala Tempo Traveller on Rent service ensures a seamless transfer to the beautiful hill station. Enjoy a comfortable ride with our professional drivers who know the best routes."
        },
        {
            name: "Pune Airport to Shirdi Tempo Traveller on Rent",
            description: "Our Pune Airport to Shirdi Tempo Traveller on Rent provides reliable transfer service for those heading to Shirdi directly from the airport. Experience comfort and convenience right from your arrival."
        },
        {
            name: "14 Seater Tempo Traveller Rate Per Km in Pune",
            description: "We provide clear information about the 14 Seater Tempo Traveller Rate Per Km in Pune. Our pricing is competitive, typically starting around ₹25-₹35 per km, ensuring you know exactly what to expect when budgeting for your trip."
        },
        {
            name: "14 Seater Tempo Traveller Hire in Pimpri Chinchwad",
            description: "For residents in Pimpri Chinchwad, our 14 Seater Tempo Traveller Hire is easily accessible. Enjoy a comfortable journey tailored to your travel requirements, ensuring a pleasant ride wherever you go."
        },
        {
            name: "14 Seater Tempo Traveller on Rent Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient 14 Seater Tempo Traveller on Rent. We guarantee a smooth and enjoyable ride for all our customers, making your travel experience as stress-free as possible."
        }
    ],    
    tableData: [
        ['- 14-Seater Bus on Rent in Pune', '- 14-Seater Tempo Traveller on Rent Pimpri Chinchwad'],
        ['- Hire 14-Seater Minibus for Picnic', '- 14-Seater Bus Rental in Pune'],
        ['- 13-Seater Bus Hire in Pune', '- 14-Seater Mini Bus Rental for Corporate'],
        ['- 14-Seater Tempo Traveller Per Km Rate in Pune', '- 14-Seater AC Bus Rental for Picnic'],
        ['- 13-Seater Non-AC Bus on Rent', '- Pune to Goa 14-Seater AC Bus Rental'],
        ['- Pune to Mahabaleshwar 14-Seater Tempo Traveller', '- Pune to Shirdi 14-Seater Bus on Rent'],
        ['- Pune to Shirdi Tempo Traveller Hire', '- Pune Airport to Lonavala Tempo Traveller on Rent'],
        ['- Pune Airport to Shirdi Tempo Traveller on Rent', '- 14-Seater Tempo Traveller Rate Per Km in Pune'],
        ['- 14-Seater Tempo Traveller Hire in Pimpri Chinchwad', '']
    ],    
    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Your 14-Seater Tempo Traveller Rental?",
            WhyChoosedescription: "When you're in need of a comfortable and reliable 14-seater tempo traveller, Shraddha Travels stands out as the best choice. We prioritize your travel experience, ensuring that every journey is seamless and enjoyable. Here’s why you should choose us:"
        },
        {
            WhyChooseheading: "Spacious and Comfortable:",
            WhyChoosedescription: "Our 14-seater tempo travellers are meticulously designed to provide maximum comfort, featuring spacious seating and ample room for both passengers and luggage. The interiors are crafted to create a relaxing environment, allowing you to unwind during your journey, whether you're heading to a family gathering, corporate event, or a weekend getaway."
        },
        {
            WhyChooseheading: "Affordable Rates:",
            WhyChoosedescription: "At Shraddha Travels, we believe in offering competitive pricing that provides great value for your money. Our rates are structured to ensure you receive top-notch service without straining your budget. We offer transparent pricing with no hidden fees, allowing you to plan your travel expenses confidently."
        },
        {
            WhyChooseheading: "Reliable Service:",
            WhyChoosedescription: "We pride ourselves on our punctuality and reliability. Your vehicle will always be ready when you need it, helping you stick to your schedule. Our team is dedicated to providing a hassle-free experience, so you can focus on enjoying your trip without the stress of transportation logistics."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedescription: "Our drivers are not only experienced but also knowledgeable about local routes and traffic patterns. They ensure a safe and smooth ride, allowing you to relax and enjoy the scenery. Their friendly demeanor and professionalism contribute to a pleasant travel experience, making them an integral part of your journey."
        },
        {
            WhyChooseheading: "Customizable Travel Plans:",
            WhyChoosedescription: "We understand that each trip is unique and may require different arrangements. Our flexible rental options allow you to create an itinerary that fits your group's specific needs, whether you're planning a short trip, a full-day outing, or a longer adventure. We work with you to tailor the journey to your preferences, ensuring maximum satisfaction."
        },
        {
            WhyChooseheading: "Well-Maintained Fleet:",
            WhyChoosedescription: "Our tempo travellers undergo regular inspections and maintenance to ensure safety and reliability. You can trust that you're traveling in a vehicle that's in top condition, providing you with a worry-free experience. Cleanliness and hygiene are also priorities, ensuring a pleasant atmosphere during your journey."
        },
        {
            WhyChooseheading: "Entertainment Options:",
            WhyChoosedescription: "Enhance your travel experience with our onboard entertainment systems. Whether it's music, movies, or games, we make sure that your long journeys are enjoyable for all passengers. These amenities are designed to keep everyone engaged and entertained, making the time spent on the road feel shorter and more enjoyable."
        },
        {
            WhyChooseheading: "24/7 Customer Support:",
            WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist with any inquiries or changes to your booking. Whether you have questions before your trip or need assistance during your journey, we're here to ensure your experience is as smooth as possible. Your satisfaction is our top priority."
        },
        {
            WhyChooseheading: "Positive Reviews:",
            WhyChoosedescription: "Our commitment to providing excellent service has resulted in numerous positive reviews from satisfied customers. We take pride in the feedback we receive, which reflects our dedication to making every travel experience memorable. Join our growing community of happy travelers who choose Shraddha Travels for their transportation needs."
        },
        {
            WhyChooseheading: "Convenient Booking Process:",
            WhyChoosedescription: "We have streamlined our booking process with an easy-to-use online system, making reserving your tempo traveller quick and hassle-free. You can browse available vehicles, compare options, and complete your booking in just a few clicks. Our team is also available to assist you at any stage, ensuring a seamless experience from booking to travel."
        }
    ]
    
    
  }

  const faqData = [
    {
        question: "What is the seating capacity of the tempo traveller?",
        answer: "Our 14-seater tempo traveller comfortably accommodates 14 passengers along with luggage, making it ideal for group outings and family trips."
    },
    {
        question: "Are there any additional charges?",
        answer: "We provide transparent pricing with no hidden charges. Any additional fees, such as for extra mileage or overtime, will be clearly communicated upfront so you can plan accordingly."
    },
    {
        question: "Can we customize our itinerary?",
        answer: "Yes! We offer flexible itineraries tailored to your travel plans. Just let us know your requirements, and we will work with you to create the perfect journey."
    },
    {
        question: "Are the drivers experienced?",
        answer: "Absolutely! All our drivers are professional, well-trained, and familiar with local routes, ensuring a safe and efficient journey for all passengers."
    },
    {
        question: "What amenities are included in the tempo traveller?",
        answer: "Our tempo travellers come equipped with comfortable seating, air conditioning, and entertainment options. Additional amenities, such as Wi-Fi or refreshments, may be available upon request."
    },
    {
        question: "How do I book a tempo traveller?",
        answer: "You can easily book online through our website or by contacting our customer support team, who will guide you through the process for a hassle-free experience."
    },
    {
        question: "What is your cancellation policy?",
        answer: "We offer a flexible cancellation policy to accommodate changes in your travel plans. Please refer to our website or contact us for specific details regarding cancellations."
    },
    {
        question: "Is the vehicle well-maintained?",
        answer: "Yes, our fleet undergoes regular maintenance checks to ensure safety, reliability, and cleanliness, providing you with peace of mind during your travels."
    },
    {
        question: "Can we take the tempo traveller out of Pune?",
        answer: "Yes, you can take the tempo traveller for outstation trips. Additional charges may apply based on distance and duration, which will be discussed before your journey."
    },
    {
        question: "What should we do in case of an emergency during the trip?",
        answer: "Our drivers are trained to handle emergencies. In case of any issues, you can contact our support team 24/7 for immediate assistance, ensuring your safety and comfort."
    }
];

  

const testimonials = [
    {
        name: "Mr. Ramesh Patil",
        text: "I recently hired a 14 Seater Tempo Traveller from Shraddha Travels for a family trip to Mahabaleshwar. The vehicle was clean, spacious, and comfortable. Our driver was punctual and very friendly, making the journey enjoyable. Highly recommended!"
    },
    {
        name: "Ms. Sneha Kulkarni",
        text: "Shraddha Travels provided us with a fantastic 14 Seater Tempo Traveller for our office outing. The booking process was smooth, and the vehicle exceeded our expectations. I will definitely choose them again for our future trips!"
    }
];



  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "14 Seater Tempo Traveller on Rent Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | 14 Seater Tempo Traveller on Rent in Pune | Affordable 14 Seater Rentals</title>
    <meta name="description" content="Rent 14 Seater Tempo Travellers in Pune for various occasions including picnics, corporate trips, and local travel. Enjoy competitive rates and excellent service!" />
    <meta name="keywords" content="14 Seater Bus on Rent in Pune, 14 Seater Tempo Traveller on Rent Pimpri Chinchwad, Hire 14 Seater Minibus for Picnic, 14 Seater Bus Rental in Pune, 13 Seater Bus Hire in Pune, 14 Seater Mini Bus Rental for Corporate, 14 Seater Tempo Traveller Per Km Rate in Pune, 14 Seater AC Bus Rental for Picnic, 13 Seater Non AC Bus on Rent, Pune to Goa 14 Seater AC Bus Rental, Pune to Mahabaleshwar 14 Seater Tempo Traveller, Pune to Shirdi 14 Seater Bus on Rent, Pune to Shirdi Tempo Traveller Hire, Pune Airport to Lonavala Tempo Traveller on Rent, Pune Airport to Shirdi Tempo Traveller on Rent, 14 Seater Tempo Traveller Rate Per Km in Pune, 14 Seater Tempo Traveller Hire in Pimpri Chinchwad" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | 14 Seater Tempo Traveller on Rent in Pune | Affordable 14 Seater Rentals" />
    <meta property="og:description" content="Discover 14 Seater Tempo Traveller rental services in Pune for picnics, corporate trips, and local travel. Competitive pricing and excellent service!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/14-seater-tempo-traveller-on-rent" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/14-seater-tempo-traveller.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "14 Seater Tempo Traveller Rental in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/14-seater-tempo-traveller-on-rent",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "14 Seater Tempo Traveller",
                    "description": "Comfortable 14 Seater Tempo Traveller on rent for various occasions in Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-05.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs 14-Seater Tempo Traveller Rental for Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Seaterbus14;