
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function BusBookingformarrieag() {



  const cardData =
  {
    keyword: 'Bus Booking for Marriage Functions in Pune',
    heading: 'Shraddha Travels: Bus Booking for Marriage Functions in Pune',
    headingDescription: 'Organizing a wedding is a delightful journey filled with details, and ensuring smooth transportation is key to a successful event. At Shraddha Travels, we provide specialized bus booking services tailored for marriage functions in Pune. From local events to outstation celebrations, our reliable, comfortable, and luxurious options are designed to cater to all your transportation needs.',
    top: 'Top Features of Our Marriage Function Bus Rental Services',

    topPlaces: [
        {
            title: "1. Diverse Bus Sizes",
            description: "We offer a wide range of bus sizes, including 17-seater, 25-seater, 32-seater, 35-seater, and 40-seater options. This variety ensures that all your guests can travel together comfortably, accommodating both small and large groups without compromising on space or comfort."
        },
        {
            title: "2. Comfort and Elegance",
            description: "Our buses are thoughtfully designed with plush seating, spacious interiors, and tasteful decor. This luxurious ambiance not only enhances the travel experience but also aligns perfectly with the grandeur of your wedding, making your guests feel pampered from the moment they step on board."
        },
        {
            title: "3. Climate-Controlled Comfort",
            description: "All our buses are equipped with state-of-the-art air conditioning systems, ensuring a cool and refreshing journey for your guests, regardless of the weather outside. This feature allows everyone to arrive at the venue feeling relaxed and comfortable."
        },
        {
            title: "4. Skilled Drivers",
            description: "Our professional drivers are not only experienced in handling large vehicles but also excel at managing the logistics of wedding transportation. Their expertise ensures safe, timely, and smooth travel, allowing you to focus on your special day without worries."
        },
        {
            title: "5. Tailored Packages",
            description: "We recognize that every wedding is unique, and so are your transportation needs. Our customizable rental packages allow you to select specific features and amenities, such as onboard refreshments or entertainment options, ensuring that everything fits your vision perfectly."
        },
        {
            title: "6. Honest Pricing",
            description: "Enjoy straightforward and transparent pricing with no hidden charges. We provide detailed estimates upfront, allowing you to manage your budget effectively while enjoying high-quality service without unexpected costs."
        },
        {
            title: "7. Timeliness Guaranteed",
            description: "We prioritize punctuality to ensure that all your guests arrive at the venue on time. Our meticulous planning and scheduling mean you can focus on enjoying your special day, knowing that your transportation is in reliable hands."
        },
        {
            title: "8. Local and Outstation Services",
            description: "Whether your wedding is taking place within Pune or at an outstation location, we offer dependable transportation solutions for both. Our extensive network allows us to cater to all your wedding-related travel needs, no matter the distance."
        },
        {
            title: "9. Simple Booking Process",
            description: "Booking your wedding transportation with Shraddha Travels is a breeze. You can easily reach out to us via phone, email, or our user-friendly online form. Our team will handle all the details, ensuring a hassle-free experience from start to finish."
        },
        {
            title: "10. Dedicated Customer Support",
            description: "Our responsive customer support team is always ready to assist you with any questions or concerns. Whether you need help with booking modifications or specific requests, we're here to ensure a smooth and enjoyable experience for you and your guests."
        }
    ],

    "services": [
      {
          "name": "Full Bus Booking for Marriage Price",
          "description": "Get competitive pricing for full bus bookings specifically tailored to your wedding function. At Shraddha Travels, we provide transparent and detailed quotes to help you manage your budget effectively. Our pricing structure is designed to be straightforward, ensuring no hidden costs. Enjoy peace of mind knowing that we accommodate all your transportation needs for a seamless wedding experience."
      },
      {
          "name": "Tourist Bus Booking for Marriage",
          "description": "For an enjoyable and comfortable travel experience, consider our tourist bus options. Ideal for both long-distance journeys and local events, our tourist buses are equipped with modern amenities such as air conditioning, plush seating, and entertainment systems. We ensure that your guests have a pleasant ride, making every moment of travel enjoyable, from the first mile to the last."
      },
      {
          "name": "Reserve Bus for Marriage in Pune",
          "description": "Booking your wedding transportation is simple and hassle-free with Shraddha Travels. Our easy reservation process is designed specifically for marriage functions in Pune. Whether you're planning a grand celebration or a more intimate gathering, we’ll ensure your transportation needs are met smoothly, allowing you to focus on what truly matters on your special day."
      },
      {
          "name": "Bus Booking for Marriage Party",
          "description": "Ensure seamless transportation for your wedding party with our reliable bus rental services. Our buses are designed to accommodate all your guests comfortably, featuring spacious interiors and amenities to enhance the travel experience. This allows you to focus on celebrating your special day while we handle the logistics of getting everyone to and from the venue."
      },
      {
          "name": "Bus Booking for Marriage Pune to Outstation",
          "description": "Planning an outstation wedding? We offer dependable bus services for marriage functions traveling from Pune to various destinations. Our buses are designed for comfort and safety, ensuring that your guests arrive at their destination relaxed and ready to celebrate. With Shraddha Travels, your outstation wedding logistics are taken care of with professionalism and care."
      },
      {
          "name": "Bus for Marriage Booking in Pune",
          "description": "Find the perfect bus for your wedding function in Pune with our extensive range of rental options. From small groups to large gatherings, we provide customized solutions to fit every requirement. Our expert team is here to guide you through the selection process, ensuring a smooth transportation experience for you and your guests, tailored to your unique celebration."
      },
      {
          "name": "Bus Hire in Pimpri Chinchwad for Marriage Function",
          "description": "Residents of Pimpri Chinchwad can take advantage of our specialized bus hire services for wedding functions. Our comfortable and reliable transportation options are tailored to meet the unique needs of your celebration. We prioritize your guests' comfort and convenience, ensuring they travel in style to enjoy your special occasion without any worries."
      },
      {
          "name": "Sleeper Bus Booking for Marriage",
          "description": "For long-distance travel or overnight wedding events, consider our sleeper bus options. These buses provide a cozy and restful journey for your guests, featuring comfortable sleeping arrangements and essential amenities. This ensures they arrive refreshed and ready to enjoy your special day, making your wedding travel experience as memorable as the event itself."
      },
      {
          "name": "Luxury Bus Booking for Marriage Function in Pune",
          "description": "Add a touch of elegance to your wedding with our luxury bus rentals. Featuring high-end amenities such as leather seating, climate control, and onboard refreshments, our luxury buses create a sophisticated travel experience that enhances the overall celebration. Treat your guests to a level of comfort that makes your marriage function truly memorable."
      },
      {
          "name": "Full Bus Booking for Marriage Event in Pune",
          "description": "Our comprehensive full bus booking services in Pune cater to all your wedding transportation needs. We focus on providing reliable and comfortable options, ensuring that every guest enjoys a smooth journey to and from your marriage event. From planning to execution, our dedicated team is here to assist you. Contact Shraddha Travels today to secure the perfect transportation for your special day!"
      },
      {
          "name": "Bus Booking for Marriage Function in Pune Contact Number",
          "description": "Contact Shraddha Travels at +91 8087847191 for prompt and efficient bus booking for marriage functions in Pune. Our customer service team is available to assist you with any inquiries, ensuring a smooth and enjoyable ride for all our customers. Let us handle your wedding transportation needs, so you can focus on celebrating your love!"
      },
      {
          "name": "Bus Hire Services for Weddings in Pune",
          "description": "Looking for reliable and comfortable bus hire services for weddings in Pune? Shraddha Travels offers a wide range of wedding bus rental services designed to ensure your guests' convenience and comfort. Our fleet of modern buses is perfect for local and outstation weddings, offering excellent seating, air conditioning, and entertainment options. Whether you need transportation for a small wedding or a large gathering, our tailored services are here to make your wedding transportation experience smooth and enjoyable. Let Shraddha Travels be a part of your celebration by providing safe and stylish travel solutions for your wedding day."
      }
  ],    
    tableData: [
        ['- Full Bus Booking for Marriage Price', '- Tourist Bus Booking for Marriage'],
        ['- Reserve Bus for Marriage in Pune', '- Bus Booking for Marriage Party'],
        ['- Bus Booking for Marriage Pune to Outstation', '- Bus for Marriage Booking in Pune'],
        ['- Bus Hire in Pimpri Chinchwad for Marriage Function', '- Bus Booking for Marriage Events in Pune'],
        ['- Sleeper Bus Booking for Marriage', '- Bus Booking for Marriage Events in Pimpri Chinchwad'],
        ['- Luxury Bus Booking for Marriage Function in Pune', '- Luxury Bus Booking for Marriage Function in Karve Nagar'],
        ['- Luxury Bus Booking for Marriage Function in Kothrud', '- Full Bus Booking for Marriage Price in Pune'],
        ['- Full Bus Booking for Marriage in Viman Nagar', '- Full Bus Booking for Marriage in Baner Aundh'],
        ['- Full Bus Booking for Marriage in Pimpri Chinchwad', '- Full Bus Booking for Marriage Event in Pune']
    ],
    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Bus Booking for Marriage Functions in Pune?",
            WhyChoosedcription: "With years of experience in wedding transportation, we understand the unique needs of marriage functions and offer tailored solutions to ensure everything runs smoothly. Our team is dedicated to providing a seamless experience that allows you to focus on what truly matters—your special day."
        },
        {
            WhyChooseheading: "Expertise in Wedding Logistics:",
            WhyChoosedcription: "We specialize in managing the unique challenges of wedding transportation, providing efficient solutions tailored to your needs. Our knowledgeable staff will work with you to create a detailed transportation plan that accounts for every aspect of your wedding, ensuring that your guests arrive on time and in style."
        },
        {
            WhyChooseheading: "Diverse Fleet of Buses:",
            WhyChoosedcription: "Our extensive range of buses, from standard to luxury options, ensures you find the perfect fit for your guest list. Whether you have a small group or a large gathering, we have vehicles that accommodate groups of all sizes comfortably, providing ample space for guests and their belongings."
        },
        {
            WhyChooseheading: "Comfort and Luxury:",
            WhyChoosedcription: "Our buses are equipped with plush seating, modern amenities, and air conditioning, creating a comfortable and enjoyable travel experience for your guests. With features such as entertainment systems and onboard refreshments, we ensure that your guests feel pampered throughout their journey."
        },
        {
            WhyChooseheading: "Reliable Service:",
            WhyChoosedcription: "We pride ourselves on punctuality and reliability. Our professional drivers are experienced and knowledgeable, ensuring timely arrivals and departures while keeping your wedding schedule on track. You can rest assured that your guests will be transported safely and efficiently."
        },
        {
            WhyChooseheading: "Customizable Packages:",
            WhyChoosedcription: "We offer flexible rental packages that can be tailored to your specific needs, whether you require local transportation or outstation travel for your wedding. Choose from a variety of options, including hourly rentals or full-day packages, to best suit your event."
        },
        {
            WhyChooseheading: "Transparent Pricing:",
            WhyChoosedcription: "Enjoy clear and upfront pricing with no hidden fees. We provide detailed quotes that outline all costs, helping you budget effectively for your wedding transportation. Our commitment to transparency ensures there are no surprises on your special day."
        },
        {
            WhyChooseheading: "Excellent Customer Support:",
            WhyChoosedcription: "Our dedicated support team is available to assist you with any queries or concerns throughout the booking process. From the initial consultation to the final drop-off, we are here to provide guidance and support, ensuring a seamless experience from start to finish."
        },
        {
            WhyChooseheading: "Focus on Safety:",
            WhyChoosedcription: "The safety of your guests is our top priority. All our buses are well-maintained and regularly inspected to meet safety standards. Our drivers adhere to strict safety protocols, providing peace of mind so you can enjoy your celebration without worry."
        },
        {
            WhyChooseheading: "Local Knowledge:",
            WhyChoosedcription: "As a Pune-based service, we have in-depth knowledge of the area, ensuring we can navigate efficiently and recommend the best routes for your wedding travel needs. Our familiarity with local venues helps us avoid traffic and ensure timely arrivals."
        },
        {
            WhyChooseheading: "Stress-Free Planning:",
            WhyChoosedcription: "Let us handle your transportation needs so you can focus on enjoying your special day. We work closely with you to ensure every detail is taken care of, providing you with the confidence that your transportation logistics are in expert hands."
        }
    ]    
  }



  const faqData = [
    {
        question: "How do I book a bus for my wedding function?",
        answer: "You can easily book a bus by contacting us through our website, phone, or email. Our team will guide you through the booking process and help customize your package."
    },
    {
        question: "What types of buses are available?",
        answer: "We offer a variety of bus sizes, including 17-seater, 25-seater, 32-seater, 35-seater, and 40-seater options, along with luxury buses to suit different needs."
    },
    {
        question: "Are your buses air-conditioned?",
        answer: "Yes, all our buses are fully air-conditioned, ensuring a comfortable journey for your guests, regardless of the weather."
    },
    {
        question: "Can I customize the rental package?",
        answer: "Absolutely! We offer customizable packages that can be tailored to meet your specific requirements, including additional amenities and special arrangements."
    },
    {
        question: "What are your safety measures?",
        answer: "All our buses are regularly maintained, and our drivers are trained to follow strict safety protocols. Your guests' safety is our highest priority."
    },
    {
        question: "Is there a minimum rental duration?",
        answer: "Yes, we typically have a minimum rental duration, but please contact us for specific details as it may vary depending on the bus type and destination."
    },
    {
        question: "Do you provide services for outstation travel?",
        answer: "Yes, we offer dependable transportation solutions for both local and outstation wedding functions."
    },
    {
        question: "How do you ensure punctuality?",
        answer: "Our drivers are trained to manage logistics efficiently, and we emphasize punctual service to ensure all guests arrive on time."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods, including cash, bank transfers, and online payments, for your convenience."
    },
    {
        question: "Can I make changes to my booking?",
        answer: "Yes, we understand that plans can change. Please contact us as soon as possible, and we will do our best to accommodate any changes to your booking."
    }
];



const testimonials = [
    {
        name: "Mr. Ravi Joshi",
        text: "I recently booked a bus for my sister's marriage function in Pune through Shraddha Travels, and I must say the experience was excellent! The bus was clean, spacious, and arrived on time, making the entire process hassle-free. The driver was polite and professional, ensuring a smooth ride for all our guests. Shraddha Travels took care of everything, allowing us to focus on the wedding festivities. Highly recommended for any large event!"
    },
    {
        name: "Miss. Sneha Patil",
        text: "We chose Shraddha Travels for bus booking for our wedding function in Pune, and it was the best decision! The buses were well-maintained, air-conditioned, and comfortable for all our guests. The staff was extremely cooperative, and the driver was punctual and courteous throughout the day. Shraddha Travels made the transportation logistics stress-free, which really helped us enjoy the occasion without worry. Amazing service!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Bus Booking for Marriage function in Pune Contact Number: ",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus Hire Services for Weddings in Pune | Affordable Wedding Bus Rentals</title>
    <meta name="description" content="Book buses for weddings in Pune with Shraddha Travels. Reliable and affordable bus hire services for marriage functions and events." />
    <meta name="keywords" content="Bus Hire Services for Weddings in Pune, Wedding Bus Hire Pune, Marriage Bus Rentals in Pune, Luxury Bus Hire for Weddings, Affordable Wedding Transport Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus Hire Services for Weddings in Pune | Affordable Wedding Bus Rentals" />
    <meta property="og:description" content="Rent buses for weddings in Pune with Shraddha Travels. Comfortable and affordable bus hire services for all types of marriage events." />
    <meta property="og:url" content="https://www.shraddhatravels.com/bus-hire-services-for-weddings-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/wedding-bus-hire.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Hire Services for Weddings",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/bus-hire-services-for-weddings-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Wedding Bus Rental Services in Pune",
                    "description": "Reliable and affordable bus hire services for weddings and marriage functions in Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>


      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-09.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Bus Booking for Marriage Functions in Pune</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default BusBookingformarrieag;