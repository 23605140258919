
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Busonrentinpimpri() {



  const cardData =
  {
    keyword: ' Bus on Rent in Pimpri Chinchwad ',
    heading: 'Shraddha Travels: Bus on Rent in Pimpri Chinchwad ',
    headingDescription: 'Shraddha Travels offers exceptional bus rental services in Pimpri Chinchwad, catering to various needs from corporate events to family outings. Our fleet is designed to provide comfort, convenience, and reliability, making us the top choice for bus rentals in the area. Here’s a look at the top reasons to choose Shraddha Travels for your next bus rental in Pimpri Chinchwad:',
    top: 'Top Places to Visit in Pune with Shraddha Travels:',

    topPlaces: [
        {
          title: "1. Diverse Fleet",
          description: "Shraddha Travels offers an extensive fleet of buses for rent, including luxury coaches, standard buses, and mini-buses. Whether you need a 17-seater mini-bus for a family outing or a 40-seater luxury coach for a corporate event, we ensure that you have the perfect vehicle tailored to your specific needs and group size, providing comfort and convenience throughout your journey."
        },
        {
          title: "2. Affordable Rates",
          description: "Our competitive pricing strategy guarantees that you receive the best value for your money. We believe in transparent pricing with no hidden fees, allowing you to budget effectively for your event. Additionally, we often have special offers and packages that can help you save even more on your bus rental."
        },
        {
          title: "3. Comfortable Interiors",
          description: "Each bus in our fleet is designed with passenger comfort in mind. Enjoy plush, ergonomic seating, ample legroom, and climate control features that cater to all seasons. Our vehicles also include modern amenities such as charging ports and entertainment systems to enhance your travel experience, ensuring that your journey is both enjoyable and relaxing."
        },
        {
          title: "4. Experienced Drivers",
          description: "Our professional drivers are not only experienced but also trained in customer service. They ensure your safety by adhering to all traffic regulations and are knowledgeable about the best routes to take, especially during peak traffic hours. This expertise allows you to focus on enjoying your trip, knowing that you are in capable hands."
        },
        {
          title: "5. Customizable Packages",
          description: "We understand that each event is unique, which is why we offer customizable rental packages. Whether you’re planning a corporate function, wedding, family picnic, or school trip, we can tailor our services to fit your specific requirements, including itinerary adjustments and additional amenities to suit your group's needs."
        },
        {
          title: "6. Timely Service",
          description: "Punctuality is a cornerstone of our service. We guarantee timely pick-ups and drop-offs to keep your plans on track. Our meticulous planning and scheduling processes ensure that you won’t face any delays, allowing you to enjoy your event without the stress of waiting or rescheduling."
        },
        {
          title: "7. Safety First",
          description: "Your safety is our highest priority. All our buses undergo rigorous maintenance and regular safety checks to meet the highest standards. We also provide safety briefings and ensure that all safety equipment, including seat belts and first aid kits, are available on board, giving you peace of mind during your journey."
        },
        {
          title: "8. Flexible Booking Options",
          description: "We recognize that plans can change, which is why we offer flexible booking options. Whether you need a bus for a few hours or several days, we are ready to accommodate last-minute changes or special requests. Our easy booking process and responsive customer support make it simple to adjust your arrangements as needed."
        },
        {
          title: "9. Excellent Customer Support",
          description: "Our dedicated customer support team is available around the clock to assist you with any queries or concerns you may have. From the initial booking process to the conclusion of your journey, our friendly staff is committed to ensuring a seamless experience, providing timely updates and support at every step."
        },
        {
          title: "10. Positive Reviews",
          description: "We take pride in our long-standing reputation for excellence, backed by a wealth of positive reviews from satisfied customers. Our commitment to providing top-notch bus rental services in Pimpri Chinchwad is reflected in the experiences shared by our clients, encouraging new customers to trust us for their travel needs."
        }
      ],      
      services: [
        {
          name: "Bus Hire in Hinjewadi Pune",
          description: "Discover reliable and comfortable bus hire services in Hinjewadi, Pune, with Shraddha Travels. Our fleet features a variety of buses suitable for all occasions, from corporate events and team outings to school trips and family gatherings. Enjoy timely service and competitive rates, ensuring you have the best experience possible. Our well-maintained vehicles come equipped with modern amenities, allowing you to relax and enjoy your journey."
        },
        {
          name: "Bus on Rent in Aundh Pune",
          description: "Shraddha Travels provides premium bus rental services in Aundh, Pune. Whether you're organizing a family reunion, a corporate retreat, or a special celebration, we have the perfect bus for you. Our buses are equipped with comfortable seating, air conditioning, and entertainment systems to ensure an enjoyable ride. Trust us to deliver a seamless travel experience tailored to your specific needs."
        },
        {
          name: "Shraddha Travels Pune",
          description: "At Shraddha Travels, we pride ourselves on offering top-notch bus rental services throughout Pune. Our extensive fleet includes a range of vehicles, from mini buses to luxury coaches, designed to accommodate any group size and travel requirement. Our commitment to customer satisfaction ensures a hassle-free experience, with dedicated support from booking to your journey’s end. Choose us for a reliable and enjoyable travel experience."
        },
        {
          name: "Bus on Rent in Baner Pune",
          description: "Rent a bus in Baner, Pune, with Shraddha Travels for your next event or outing. Our diverse fleet includes various bus sizes and styles, ensuring comfort and convenience for groups of all sizes. We offer flexible booking options to meet your specific schedule and competitive pricing that won't break the bank. With our professional drivers and top-quality vehicles, your journey will be smooth and enjoyable."
        },
        {
          name: "Bus on Rent in Bhosari Pune",
          description: "Looking for bus rental services in Bhosari, Pune? Shraddha Travels offers a diverse range of buses for rent, ensuring you find the perfect vehicle for your needs. Whether it's a corporate event, wedding, or family trip, our buses provide reliable and comfortable transportation. Our team is dedicated to making your travel experience as stress-free as possible, with excellent customer service and well-maintained vehicles."
        },
        {
          name: "Mini Bus on Rent in Talawade Pune",
          description: "For smaller groups or short trips, Shraddha Travels offers mini buses on rent in Talawade, Pune. Ideal for local events, school outings, or private gatherings, our mini buses provide a comfortable and economical travel solution. Each vehicle is equipped with essential amenities to enhance your journey, ensuring that you and your guests arrive at your destination feeling relaxed and refreshed."
        },
        {
          name: "Tempo Traveller on Rent in Pimpri Chinchwad",
          description: "Shraddha Travels provides tempo travellers on rent in Pimpri Chinchwad for those needing more space and comfort. Ideal for group travel, our tempo travellers offer ample seating and luggage space, making them perfect for both short excursions and longer journeys. Experience the convenience of traveling together with friends, family, or colleagues in a safe and comfortable environment."
        },
        {
          name: "17 Seater Bus on Rent in Akurdi Pune",
          description: "Rent a 17-seater bus in Akurdi, Pune, with Shraddha Travels for an intimate group experience. Our well-maintained buses are designed for comfort and style, perfect for family outings, corporate trips, or special occasions. Enjoy features like reclining seats, air conditioning, and ample storage for your luggage, ensuring a pleasant journey for everyone."
        },
        {
          name: "14, 17, 20, 25 Seater Bus Hire in Pimpri Chinchwad",
          description: "Choose from our range of 14, 17, 20, and 25-seater buses available for hire in Pimpri Chinchwad. Shraddha Travels offers flexible and affordable bus rental solutions to cater to different group sizes and travel needs. Whether it's a small family gathering or a larger corporate event, we have the right vehicle to make your journey comfortable and enjoyable."
        },
        {
          name: "32, 35, 40, 45 Seater Bus Hire in Pimpri Chinchwad",
          description: "For larger groups, Shraddha Travels provides 32, 35, 40, and 45-seater buses in Pimpri Chinchwad. Our spacious and comfortable buses are perfect for corporate events, weddings, and other large gatherings. Each bus is equipped with modern amenities to enhance your travel experience, ensuring that your group arrives at your destination together and on time."
        },
        {
          name: "Bus on Rent in Wakad Pune",
          description: "Rent a bus in Wakad, Pune, with Shraddha Travels for reliable and comfortable transportation. Our extensive fleet ensures you find the right bus for your event, from mini buses for small gatherings to large coaches for bigger groups. Our focus on customer satisfaction means you can expect excellent service from booking to drop-off."
        },
        {
          name: "Bus on Rent in Pimple Gurav Pune",
          description: "Shraddha Travels offers bus rental services in Pimple Gurav, Pune. Our buses are perfect for any occasion, whether it's a local event, a group outing, or a family celebration. Enjoy affordable rates and excellent service with our diverse fleet, designed to provide comfort and convenience for all your travel needs."
        },
        {
          name: "Urbania Bus on Rent in Pimpri Chinchwad",
          description: "Experience premium travel with Urbania buses on rent in Pimpri Chinchwad from Shraddha Travels. Ideal for special events and high-profile gatherings, our Urbania buses offer luxury and comfort for an exceptional travel experience. With spacious interiors and modern amenities, you can travel in style and enjoy every moment of your journey."
        },
        {
          name: "Bus on Rent in Pimple Gurav",
          description: "Looking for a reliable bus rental service in Pimple Gurav? Shraddha Travels offers comfortable and well-maintained buses for various needs, including local events and group outings. Our buses are designed to accommodate different group sizes, ensuring a smooth and enjoyable travel experience. Trust us to handle your transportation needs with professionalism and care."
        },
        {
          name: "Bus on Rent in Akurdi",
          description: "Shraddha Travels provides top-notch bus rental services in Akurdi, perfect for both local and outstation travel. Our buses are equipped with modern amenities to ensure comfort and convenience for your journey. Whether you’re organizing a corporate event, a family gathering, or a school trip, our bus rental service in Akurdi has you covered with reliable transportation."
        },
        {
          name: "Bus on Rent in Baner Aundh",
          description: "For bus rentals in Baner Aundh, Shraddha Travels offers a range of options to meet your travel requirements. Our buses are ideal for group travel, whether it’s for a local tour, an outing, or any special event. With a focus on comfort and reliability, our bus rental service ensures a pleasant and efficient travel experience that you can count on."
        },
        {
          name: "17-Seater Tempo Traveller on Rent in Pimpri Chinchwad",
          description: "If you need a comfortable and compact vehicle for group travel in Pimpri Chinchwad, consider renting a 17-seater tempo traveller from Shraddha Travels. Our tempo travellers are perfect for medium-sized groups, offering a comfortable ride with modern amenities such as air conditioning and entertainment options, ensuring a memorable travel experience."
        },
        {
          name: "Mini Bus on Rent in Pimpri Chinchwad",
          description: "Shraddha Travels offers mini bus rentals in Pimpri Chinchwad, providing an ideal solution for medium-sized groups. Our mini buses are perfect for family outings, corporate events, or any group activity requiring comfortable and reliable transportation. With a focus on safety and comfort, you can relax and enjoy the journey."
        },
        {
          name: "Urbania on Rent in Pimpri Chinchwad",
          description: "For a luxurious and premium travel experience, rent an Urbania from Shraddha Travels in Pimpri Chinchwad. Our Urbania service offers a high level of comfort and style, ideal for special occasions and corporate events. Experience the convenience of traveling in a modern and well-equipped vehicle that caters to your every need."
        },
        {
          name: "Bus On Rent in Pimpri Chinchwad Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Bus On Rent in Pimpri Chinchwad. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
      ],      
      tableData: [
        ['- Bus hire in Hinjewadi Pune', '- Bus on Rent in Aundh Pune'],
        ['- Bus On Rent in Baner Pune', '- Bus On Rent in Bhosari Pune'],
        ['- Mini Bus On Rent in Talawade Pune', '- Tempo Traveller on rent in Pimpri Chinchwad'],
        ['- 17 Seater bus on Rent in Akurdi Pune', '- 14, 17, 20, 25 Seater Bus Hire in Pimpri Chinchwad'],
        ['- 32, 35, 40, 45 Seater Bus Hire in Pimpri Chinchwad', '- Bus On Rent in Wakad Pune'],
        ['- Bus on Rent in Pimple Gurav Pune', '- Urbania bus on rent in Pimpri Chinchwad'],
        ['- Bus on Rent in Pimple Gurav', '- Bus on Rent in Akurdi'],
        ['- Bus on Rent in Baner Aundh', '- 17 Seater Tempo Traveller on Rent in Pimpri Chinchwad'],
        ['- Mini Bus On Rent in Pimpri Chinchwad', '- Urbania on Rent in Pimpri Chinchwad'],
        ['- Shraddha Travels Pune', '']
      ],      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels for Bus On Rent in Pimpri Chinchwad?",
          WhyChoosedescription: "When it comes to renting a bus in Pimpri Chinchwad, Shraddha Travels is your trusted partner for a comfortable and reliable journey. Whether you're organizing a corporate event, wedding, or group outing, our service ensures that you travel with ease and style. Here's why Shraddha Travels is the best choice for your trip:"
        },
        {
          WhyChooseheading: "Comprehensive Fleet:",
          WhyChoosedescription: "We offer a diverse range of buses, including mini buses, luxury coaches, and large buses, ensuring we have the right vehicle to meet your group size and travel needs."
        },
        {
          WhyChooseheading: "Affordable Rates:",
          WhyChoosedescription: "Our competitive pricing structure guarantees you receive great value without compromising on quality, making your travel experience cost-effective and enjoyable."
        },
        {
          WhyChooseheading: "Reliable Service:",
          WhyChoosedescription: "Timeliness and reliability are our top priorities. We pride ourselves on punctual service, ensuring that our buses arrive on time for a smooth journey."
        },
        {
          WhyChooseheading: "Modern Amenities:",
          WhyChoosedescription: "Our buses come equipped with comfortable seating, air conditioning, and entertainment systems, making every ride enjoyable for our passengers."
        },
        {
          WhyChooseheading: "Professional Drivers:",
          WhyChoosedescription: "Our experienced drivers are well-trained to handle various travel situations, prioritizing safety and customer satisfaction for a pleasant experience."
        },
        {
          WhyChooseheading: "Customized Solutions:",
          WhyChoosedescription: "We offer tailored solutions to meet your specific requirements, whether for a corporate event, wedding, or group outing."
        },
        {
          WhyChooseheading: "Excellent Customer Support:",
          WhyChoosedescription: "Our dedicated customer support team is available to assist with any queries or concerns, providing exceptional service from booking to the end of your journey."
        },
        {
          WhyChooseheading: "Clean and Well-Maintained Vehicles:",
          WhyChoosedescription: "We ensure all our buses are regularly cleaned and maintained to the highest standards, guaranteeing a comfortable and hygienic travel environment."
        },
        {
          WhyChooseheading: "Flexible Booking Options:",
          WhyChoosedescription: "Our user-friendly booking process allows for flexible options, accommodating changes in your travel plans with ease."
        },
        {
          WhyChooseheading: "Local Expertise:",
          WhyChoosedescription: "As a local provider, we have in-depth knowledge of Pimpri Chinchwad, offering valuable insights and recommendations for your journey."
        }
      ]
      
  }



  const faqData = [
    {
      question: "What types of buses are available for rent in Pimpri Chinchwad?",
      answer: "We offer a variety of buses for rent in Pimpri Chinchwad, including mini buses, luxury coaches, and larger buses. Whether you need a small vehicle for a few passengers or a large coach for a big group, we have options to suit your needs."
    },
    {
      question: "How do I book a bus rental with Shraddha Travels?",
      answer: "Booking a bus rental with us is simple. You can contact us directly via phone or email, or use our online booking system. Provide us with details about your trip, such as the date, time, and destination, and we will assist you in reserving the right bus for your needs."
    },
    {
      question: "Are your buses equipped with amenities?",
      answer: "Yes, our buses are equipped with modern amenities to ensure a comfortable journey. Depending on the bus type, amenities may include air conditioning, comfortable seating, entertainment systems, and more."
    },
    {
      question: "What is the pricing structure for bus rentals?",
      answer: "Our pricing is competitive and varies depending on factors such as the type of bus, rental duration, and distance. We provide transparent pricing with no hidden costs. For a detailed quote, please contact us with your specific requirements."
    },
    {
      question: "Are your drivers experienced and professional?",
      answer: "Yes, all our drivers are experienced and professional. They are trained to handle various travel situations and prioritize safety and customer satisfaction."
    },
    {
      question: "Can I make changes to my booking after it is confirmed?",
      answer: "Yes, we offer flexible booking options. If you need to make changes to your reservation, please contact us as soon as possible, and we will assist you in adjusting your booking."
    },
    {
      question: "How do you ensure the cleanliness and maintenance of your buses?",
      answer: "We take cleanliness and maintenance very seriously. Our buses are regularly cleaned and maintained to ensure they meet high standards of hygiene and safety."
    },
    {
      question: "What should I do if I have a problem during the journey?",
      answer: "If you encounter any issues during your journey, please contact our customer support team immediately. We are available to address any concerns and ensure that your travel experience remains pleasant."
    },
    {
      question: "Do you offer special packages for corporate events or large groups?",
      answer: "Yes, we offer customized packages for corporate events, weddings, and large groups. Contact us to discuss your specific requirements, and we will tailor a package that suits your needs."
    },
    {
      question: "How far in advance should I book a bus?",
      answer: "To ensure availability and the best rates, we recommend booking your bus as early as possible. For peak times or special events, booking well in advance is especially important."
    }
  ];
  



  const testimonials = [
    {
      name: "Ms. Sanchi Sabale",
      text: "We recently rented a bus from Shraddha Travels for a corporate team outing in Pimpri Chinchwad, and we couldn't be happier with the service. The booking process was smooth, the bus was pristine, and the driver was both professional and courteous. Our team had a comfortable and enjoyable journey, thanks to Shraddha Travels. We highly recommend their services for any group travel needs!"
    },
    {
      name: "Ms. Sakshi Deshmukh",
      text: "I organized a family reunion and chose Shraddha Travels for our bus rental in Pimpri Chinchwad. From start to finish, their service was exceptional. The mini bus we rented was well-maintained, and the amenities provided made our trip enjoyable. The driver was friendly and knew the best routes to avoid traffic. Thank you, Shraddha Travels, for making our family gathering so memorable!"
    }
  ];
  

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Bus On Rent in Pimpri Chinchwad Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus On Rent in Pimpri Chinchwad | Mini Bus Hire</title>
    <meta name="description" content="Affordable bus rental services in Pimpri Chinchwad for various occasions, including corporate outings and local trips." />
    <meta name="keywords" content="Bus On Rent in Pimpri Chinchwad, Mini Bus Hire, Tempo Traveller Rental, Urbania Bus on Rent, Bus Hire in Aundh" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus On Rent in Pimpri Chinchwad | Mini Bus Hire" />
    <meta property="og:description" content="Reliable bus rental services in Pimpri Chinchwad, including mini buses and tempo travellers for various events." />
    <meta property="og:url" content="https://www.amuratatravels.com/bus-on-rent-pimpri-chinchwad" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/bus-rent-pimpri-chinchwad.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus On Rent in Pimpri Chinchwad",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pimpri Chinchwad, Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/bus-on-rent-pimpri-chinchwad",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental in Pimpri Chinchwad",
                    "description": "Affordable bus rental services in Pimpri Chinchwad for corporate events, picnics, and local travel."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 2 -11.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for "Bus On Rent in Pimpri Chinchwad" - Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Busonrentinpimpri;