import React, { useState } from "react";
import { Helmet } from "react-helmet";

function Enquiry() {
  const [result, setResult] = useState("");
  const [showModal, setShowModal] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Submitting your request, please wait...");
    setShowModal(true);
    const formData = new FormData(event.target);
    formData.append("access_key", "10538944-fdf1-4d53-897a-d150fdc231aa");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Thank you! Your enquiry has been successfully submitted. We will get back to you shortly.");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult("Oops! Something went wrong. Please try again later.");
    }
  };

  return (
    <div>
    <Helmet>
                <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
                <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
                <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />
                <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
                <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
                <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />
            </Helmet>


            <section id="about" className="jarallax text-light">
                <div className="center-y relative text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1 className="text-white">Enquiry</h1>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </section>

      <section className="contact-form-section">
        <div className="contact-form-container">
          <h2 className="contact-form-title text-white">Get in Touch with Us</h2>
          <form onSubmit={onSubmit}
          name="contactForm"
          id="contact_form"
          className="contact-form"
          action="send_email.php"
          method="post"
          data-redirect="c"
        >
          <div className="contact-form-row">
            <div className="contact-form-group">
              <label htmlFor="name" className="contact-form-label">Your Full Name</label>
              <input
                type="text"
                name="name"
                id="name"
                className="contact-form-input"
                placeholder="Your Full Name"
                required
                pattern="[A-Za-z\s]+"
                title="Name must contain only letters"
              />
            </div>
            <div className="contact-form-group">
              <label htmlFor="phone" className="contact-form-label">Your Contact Number</label>
              <input
                type="tel"
                name="phone"
                id="phone"
                className="contact-form-input"
                placeholder="Your Contact Number"
                required
                pattern="[0-9]{10}"
                title="Phone number must be of 10 digits"
              />
            </div>
          </div>
          {/* <div className="contact-form-row">
            
            <div className="contact-form-group">
              <label htmlFor="bus_location" className="contact-form-label">Buses</label>
              <input
                type="text"
                name="bus_location"
                id="bus_location"
                className="contact-form-input"
                placeholder="Bus Name"
                required
                pattern="[A-Za-z\s]+"
                title="Bus can only have letters"
              />
            </div>
          </div> */}
          <div className="contact-form-row">


        
            
            <div className="contact-form-group">
              <label htmlFor="pick_up_point" className="contact-form-label">Pick Up Point</label>
              <input
                type="text"
                name="pick_up_point"
                id="pick_up_point"
                className="contact-form-input"
                placeholder="Pick Up Point"
                required
                pattern="[A-Za-z\s]+"
                title="Pick Up Point must contain only letters"
              />
            </div>

            <div className="contact-form-group">
              <label htmlFor="pick_up_point" className="contact-form-label">Dropoff Location</label>
              <input
                type="text"
                name="dropoff_location"
                id="dropoff_location"
                className="contact-form-input"
                placeholder="Dropoff Location"
                required
                pattern="[A-Za-z\s]+"
                title="Pick Up Point must contain only letters"
              />
            </div>
          </div>
          {/* <div className="contact-form-group">
            <label htmlFor="Buses" className="contact-form-label">Select Buses</label>
            <select 
                                                        name="Buses" 
                                                        id="Buses" 
                                                        className="border py-2 form-control form-control-lg rounded-0" 
                                                        required
                                                    >
                                                        <option value="" disabled>Select Buses</option>
                                                        <option value="Swift Dzire (4 + 1 Seater)">Swift Dzire (4 + 1 Seater)</option>
                                                        <option value="Kia Carens(6 + 1 Seater)">Kia Carens(6 + 1 Seater)</option>
                                                        <option value="Ertiga (6 + 1 Seater)">Ertiga (6 + 1 Seater)</option>
                                                        <option value="Tavera (6 + 1 Seater)">Tavera (6 + 1 Seater)</option>
                                                        <option value="Innova (6 + 1 Seater)">Innova (6 + 1 Seater)</option>
                                                        <option value="13 Seater(13 Seater)">13 Seater(13 Seater)</option>
                                                        <option value="17 Seater(17 Seater)">17 Seater(17 Seater)</option>
                                                        <option value="Innova Crysta(6 + 1 Seater)">Innova Crysta(6 + 1 Seater)</option>
                                                        <option value="Innova Crysta(6 + 1 Seater)">Other</option>
                                                    </select>
          </div> */}

<div className="contact-form-row">
          <div className="contact-form-group">
            <label htmlFor="date" className="contact-form-label">Date of Journey</label>
            <input
              type="date"
              name="date"
              id="date"
              className="contact-form-input"
              placeholder="Date of Journey"
              required
            />
          </div>

          <div className="contact-form-group">
              <label htmlFor="days" className="contact-form-label">Number of Passengers</label>
              <input
                type="number"
                name="days"
                id="days"
                className="contact-form-input"
                placeholder="Number of Passengers"
                required
                min="1"
                max="100"
                step="1"
              />
            </div>

            </div>
          <div className="contact-form-group">
            <textarea
              name="message"
              id="message"
              className="contact-form-textarea"
              placeholder="Your Message"
              required
            ></textarea>
          </div>
          <div className="contact-form-recaptcha">
            <div className="g-recaptcha" data-sitekey="copy-your-site-key-here"></div>
          </div>
          <div className="contact-form-submit">
            <input
              type="submit"
              id="send_message"
              value="Submit Now"
              className="default-btn border-0"
            />
          </div>
        </form>
        </div>
      </section>

      {/* Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <p>{result}</p>
            <button onClick={() => setShowModal(false)} className="modal-close-btn">OK</button>
          </div>
        </div>
      )}

      <style>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 50%;
          height: 50%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .modal {
          background: white;
          padding: 20px;
          border-radius: 5px;
          text-align: center;
        }
        .modal-close-btn {
          margin-top: 10px;
          padding: 5px 10px;
          border: none;
          background: #007bff;
          color: white;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
}

export default Enquiry;
