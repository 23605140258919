
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Busesforcorporate() {



  const cardData =
  {
    keyword: ' Buses for Corporate Events in Pune',
    heading: 'Shraddha Travels: Buses for Corporate Events in Pune',
    headingDescription: 'When it comes to organizing corporate events, seamless transportation is crucial for ensuring a smooth and professional experience. At Shraddha Travels, we offer a range of high-quality buses designed to meet the needs of corporate gatherings in Pune. Whether it’s a conference, team-building event, or corporate outing, our fleet is equipped to handle various requirements with efficiency and style. Here’s a look at the top Shraddha Travels buses for corporate events in Pune:',
    top: 'Top Buses in Pune by Shraddha Travels:',

    topPlaces: [
        {
            title: "1. Executive Coach Bus",
            description: "Our Executive Coach Bus is perfect for corporate events requiring a touch of luxury and comfort. Featuring plush seating, ample legroom, and high-end amenities such as onboard Wi-Fi and charging ports, this bus is designed to provide a first-class experience for your executives and clients. It's ideal for long journeys, ensuring that every passenger travels in style."
        },
        {
            title: "2. 32-Seater Luxury Bus",
            description: "Ideal for medium-sized groups, the 32-Seater Luxury Bus offers a blend of comfort and functionality. With comfortable seating, air-conditioning, and modern entertainment options like flat-screen TVs and sound systems, it’s a great choice for corporate events where a higher level of comfort is desired. This bus ensures your team stays relaxed and entertained during the journey."
        },
        {
            title: "3. 35-Seater Executive Bus",
            description: "The 35-Seater Executive Bus combines style and practicality. Equipped with premium seating, spacious interiors, and advanced features such as mood lighting and individual climate control, it’s suitable for corporate groups that require a comfortable and sophisticated travel experience. Perfect for team outings, this bus enhances productivity and comfort."
        },
        {
            title: "4. 45-Seater Coach Bus",
            description: "Our 45-Seater Coach Bus is designed for larger groups. It offers spacious seating, ample luggage space, and modern amenities like restroom facilities and Wi-Fi, making it an excellent option for corporate events that involve transporting a sizable team or multiple departments. This bus ensures everyone travels comfortably, regardless of the distance."
        },
        {
            title: "5. 50-Seater Luxury Bus",
            description: "For very large corporate gatherings, the 50-Seater Luxury Bus provides a spacious and comfortable solution. With high-end features including reclining seats, in-bus refreshments, and advanced audio-visual systems, it ensures that your team travels in style and comfort. This bus is perfect for conferences, events, and corporate retreats."
        },
        {
            title: "6. VIP Shuttle Bus",
            description: "The VIP Shuttle Bus is perfect for high-profile clients or executives. This bus offers top-tier comfort and privacy, featuring plush seating, tinted windows, and personalized service, making it suitable for transporting key personnel to and from corporate events with a touch of exclusivity. Ideal for business meetings and special events."
        },
        {
            title: "7. Mini Bus (14-Seater)",
            description: "Our 14-Seater Mini Bus is ideal for smaller corporate groups or for use as a shuttle between locations. It provides a cozy and efficient transport solution for team outings or smaller executive meetings. With comfortable seating and a compact design, this bus is perfect for navigating city traffic."
        },
        {
            title: "8. Tempo Traveller (10-Seater)",
            description: "The 10-Seater Tempo Traveller offers a compact yet comfortable option for smaller corporate groups. With its easy maneuverability, comfortable seating, and modern amenities, it’s perfect for short trips or small team events. This vehicle is ideal for intimate gatherings, workshops, or team-building exercises."
        },
        {
            title: "9. Corporate Outing Bus",
            description: "Designed specifically for corporate outings, this bus is equipped with amenities that enhance the travel experience. Features include entertainment systems, adjustable seating, and complimentary refreshments, making it ideal for team-building events or off-site meetings. This bus ensures your team has a memorable experience."
        },
        {
            title: "10. Conference Bus",
            description: "Our Conference Bus is tailored for on-the-go meetings. It features a dedicated space for presentations and discussions, along with Wi-Fi and multimedia capabilities, allowing your team to stay productive during transit. This bus is perfect for companies that want to maximize time while traveling to meetings or events."
        }
    ],    
    "services": [
      {
          "name": "Bus on Rent for Events in Pune",
          "description": "Shraddha Travels offers reliable and comfortable bus rental services for events in Pune. Whether you're planning a corporate event, wedding, seminar, or any other special occasion, we provide a wide range of buses that cater to your event's specific transportation needs. Our fleet includes well-maintained vehicles designed for group travel, ensuring that your guests or participants travel comfortably and on time."
      },
      {
          "name": "Event Bus Hire for Weddings in Pune",
          "description": "For weddings in Pune, Shraddha Travels provides premium bus rental services to ensure your guests travel in comfort and style. Our buses are equipped with modern amenities and are driven by professional drivers who prioritize safety and punctuality. Whether it's transporting guests to the venue or to various wedding events, our buses are the perfect choice."
      },
      {
          "name": "Corporate Event Bus on Rent in Pune",
          "description": "Make your corporate events in Pune hassle-free with Shraddha Travels' bus rental services. We provide buses tailored to meet the needs of corporate gatherings, from team-building outings to client meetings and seminars. Our buses are designed for comfort and convenience, ensuring that your team arrives refreshed and ready for the event."
      },
      {
          "name": "Bus Rental for Conferences and Seminars in Pune",
          "description": "Shraddha Travels offers specialized bus rental services for conferences and seminars in Pune. Our buses provide a comfortable and efficient way to transport attendees to and from the event venue, helping you keep the focus on your event while we handle the logistics. Our fleet includes buses of various sizes to accommodate any group size."
      },
      {
          "name": "Wedding Event Bus Hire in Pune",
          "description": "Ensure seamless transportation for your wedding guests with Shraddha Travels' wedding event bus hire in Pune. Our buses are ideal for transporting large groups, whether it's from the hotel to the wedding venue or from one event location to another. With a range of buses to choose from, we can meet your needs for both small and large groups."
      },
      {
          "name": "Bus on Rent for Group Events in Pune",
          "description": "Planning a group event in Pune? Shraddha Travels offers a range of buses for rent that are perfect for any type of event. Our buses are designed to provide a comfortable and enjoyable travel experience for your group, whether you're hosting a party, a conference, or any other type of event in Pune."
      },
      {
          "name": "Luxury Bus on Rent for Events in Pune",
          "description": "For those looking for a high-end travel experience for their event, Shraddha Travels offers luxury bus rentals in Pune. Our luxury buses come with premium amenities like plush seating, air conditioning, and onboard entertainment, ensuring that your event guests experience the highest level of comfort and style."
      },
      {
          "name": "Mini Bus Rental for Events in Pune",
          "description": "If you're hosting a smaller event in Pune, Shraddha Travels provides mini buses for rent. These compact buses are perfect for intimate gatherings, allowing your guests to travel comfortably while enjoying a cozy and relaxed journey to the event venue."
      },
      {
          "name": "Affordable Bus Rental for Events in Pune",
          "description": "At Shraddha Travels, we offer affordable bus rental options for events in Pune. We provide cost-effective solutions for transporting large groups without compromising on comfort or quality. Whether it's a wedding, corporate event, or any other occasion, our affordable pricing ensures you stay within budget while enjoying reliable transportation."
      },
      {
          "name": "Bus Rental for Large Events in Pune",
          "description": "For large-scale events in Pune, Shraddha Travels offers buses that can accommodate a significant number of passengers. Whether it's a concert, exhibition, or large corporate gathering, we have the right bus to transport your guests safely and efficiently, ensuring a smooth and organized event."
      },
      {
          "name": "Bus on Rent for Conferences in Pune",
          "description": "Make your conference a success with Shraddha Travels' bus rental services in Pune. We offer a range of buses to transport delegates, speakers, and attendees to your conference venue in comfort and style. Our buses ensure that your event runs smoothly, with reliable and timely transportation throughout the day."
      },
      {
          "name": "Bus on Rent for Special Events in Pune",
          "description": "For any special events, Shraddha Travels provides customized bus rental services in Pune. From birthday parties to anniversary celebrations and everything in between, we ensure that your guests travel comfortably and on time. Our fleet includes buses suited to all types of events, large or small."
      },
      {
          "name": "Event Transportation Bus on Rent in Pune Contact",
          "description": "For quick and easy bookings of event transportation in Pune, contact Shraddha Travels at +91 8087847191. Our team will assist you with choosing the right bus, provide information on pricing, and ensure that your event transportation needs are met with precision and care."
      }
  ],

    tableData: [
    ['- Corporate Bus Hire for Outstation', '- Corporate Bus Hire Service in Pune'],
    ['- Hire a Bus for Corporate Sector', '- Shraddha Travels Pune'],
    ['- Corporate Pick-Up and Drop Service', '- AC Bus Hire for Corporate'],
    ['- Affordable Corporate Bus Hire', '- 25-Seater Bus Hire for Corporate'],
    ['- 26-Seater Bus Hire for Corporate', '- 32-Seater Bus Hire for Corporate'],
    ['- 50-Seater Bus Hire for Corporate in Pune', '- Cheap Bus Service in Pune'],
    ['- 35-Seater Bus Hire for Corporate Events', '- Corporate Bus Service from Pune'],
    ['- Corporate Bus Service from Pune', '- Bus Hire for Corporate Event Party'],
    ['- Corporate Bus Services in Pune', '- Private Bus Service in Pune for Office'],
    ['- Private Buses for Corporate Events in Pune', '- Luxury Buses for Corporate Events in Pune'],
    ['- Buses for Corporate Events in Pimpri Chinchwad', '- Buses for Corporate Events in Hinjewadi'],
    ['- Buses for Corporate Events in Kharadi', '- Business Events in Hire Bus on Rent'],
    ['- Buses for Corporate Events in Pune', '- Buses for Corporate Events in Magarpatta City'],
    ['- Buses for Corporate Events in Talawade MIDC', '- Buses for Corporate Events in Ranjangaon MIDC']
],
whychoose: [
    {
        WhyChooseheading: "Why Choose Shraddha Travels Buses for Corporate Events in Pune?",
        WhyChoosedescription: "When it comes to corporate events in Pune, Shraddha Travels stands out as the ideal choice for bus hire services. We understand the unique needs of businesses and strive to deliver a seamless transportation experience. Our commitment to excellence ensures that every aspect of your travel is taken care of, allowing you to focus on what truly matters—your event."
    },
    {
        WhyChooseheading: "Extensive Fleet:",
        WhyChoosedescription: "At Shraddha Travels, we offer a diverse fleet of buses specifically designed to cater to various corporate event needs. Our options range from luxury coaches equipped with plush interiors for executive travel to spacious buses suitable for transporting large teams. Each vehicle is well-maintained and regularly updated, ensuring a reliable choice for your transportation requirements."
    },
    {
        WhyChooseheading: "Professional Service:",
        WhyChoosedescription: "Our team of experienced drivers and staff are committed to providing exceptional service. Each driver is trained not only to navigate Pune’s roads efficiently but also to ensure a pleasant experience for your guests. Punctuality is paramount; we guarantee that your team arrives on time, every time, setting the right tone for your event."
    },
    {
        WhyChooseheading: "Customizable Options:",
        WhyChoosedescription: "We understand that every corporate event is unique, which is why we offer customizable bus hire solutions. You can tailor your booking to include special amenities such as Wi-Fi, refreshments, and specific seating arrangements. Whether it’s a formal event or a casual outing, we can adapt our services to fit your vision."
    },
    {
        WhyChooseheading: "Reliable and Efficient:",
        WhyChoosedescription: "Timely and reliable service is crucial for corporate events. Shraddha Travels guarantees on-time arrivals and departures, minimizing any disruption to your schedule. Our efficient routing and scheduling ensure that your guests are transported smoothly from start to finish, allowing you to concentrate on your agenda without worrying about logistics."
    },
    {
        WhyChooseheading: "Competitive Pricing:",
        WhyChoosedescription: "We provide high-quality bus hire services at competitive rates, offering great value for money. Our transparent pricing model ensures there are no hidden costs, making budgeting for your corporate event straightforward. We believe that exceptional service should be accessible, allowing you to plan your event without financial strain."
    },
    {
        WhyChooseheading: "Modern Amenities:",
        WhyChoosedescription: "Our buses come equipped with modern amenities such as air conditioning, comfortable seating, and entertainment options. We prioritize your guests’ comfort, ensuring that they can relax and enjoy their journey. Features like onboard restrooms and charging ports make longer trips more convenient and enjoyable."
    },
    {
        WhyChooseheading: "Safety First:",
        WhyChoosedescription: "Safety is our top priority. All our buses undergo regular maintenance and are equipped with the latest safety features. Our drivers are trained to adhere to the highest safety standards, ensuring a secure journey for all passengers. We conduct thorough checks before every trip to guarantee that your journey is both safe and worry-free."
    },
    {
        WhyChooseheading: "Excellent Customer Support:",
        WhyChoosedescription: "From the moment you contact us until the end of your event, our customer support team is here to assist you. We offer personalized service to address any queries or concerns you may have. Our staff is available 24/7 to ensure that you have a smooth experience, providing peace of mind as you plan your event."
    },
    {
        WhyChooseheading: "Flexibility:",
        WhyChoosedescription: "We offer flexible booking options to accommodate changes in your event plans. Whether you need to adjust the number of buses, change pickup times, or modify your itinerary, we can adapt to your needs with ease. Our goal is to provide you with a hassle-free experience, allowing you to make adjustments as necessary."
    },
    {
        WhyChooseheading: "Positive Reputation:",
        WhyChoosedescription: "Shraddha Travels has built a solid reputation for excellence in bus hire services for corporate events in Pune. Our satisfied clients and positive reviews reflect our commitment to delivering outstanding service. We take pride in the long-term relationships we’ve built with our clients, ensuring that they return to us for their future transportation needs."
    }
]

  }



  const faqData = [
    {
        question: "What types of buses are available for corporate events?",
        answer: "We offer a range of buses including luxury coaches, executive buses, and standard coaches. Each type is equipped to meet different needs, from high-end travel to practical group transport."
    },
    {
        question: "How can I book a bus for a corporate event?",
        answer: "You can book a bus by contacting us through our website, phone, or email. Provide details about your event, such as the number of passengers, date, and specific requirements, and we’ll help you with the booking process."
    },
    {
        question: "Are your buses equipped with amenities?",
        answer: "Yes, our buses come with various amenities such as air conditioning, comfortable seating, and entertainment options. We can also accommodate special requests depending on your needs."
    },
    {
        question: "How do you ensure the safety of passengers?",
        answer: "Our buses are regularly maintained and inspected for safety. Our drivers are trained in safety protocols, and we adhere to strict safety standards to ensure a secure journey for all passengers."
    },
    {
        question: "Can I make changes to my booking?",
        answer: "Yes, we offer flexible booking options. If you need to make changes to your reservation, such as adjusting the number of buses or changing pickup times, please contact us as soon as possible, and we’ll accommodate your request."
    },
    {
        question: "What are your payment options?",
        answer: "We offer various payment options including credit/debit cards and bank transfers. Payment details will be provided during the booking process."
    },
    {
        question: "How far in advance should I book a bus?",
        answer: "It’s recommended to book your bus as early as possible to ensure availability, especially for large corporate events. We suggest booking at least a few weeks in advance."
    },
    {
        question: "What is your cancellation policy?",
        answer: "Our cancellation policy varies depending on the timing of the cancellation. Please contact us directly for details on our cancellation terms and conditions."
    },
    {
        question: "Are there any additional costs?",
        answer: "We strive to provide transparent pricing with no hidden costs. Any additional charges, such as for extra amenities or changes in booking, will be communicated to you upfront."
    },
    {
        question: "How do I contact customer support?",
        answer: "You can reach our customer support team via phone, email, or through our website’s contact form. We are here to assist you with any questions or concerns regarding your corporate bus hire."
    }
];



const testimonials = [
    {
        name: "Mr. Rajesh Kumar",
        text: "We recently hired Shraddha Travels for our company's annual corporate retreat, and the experience was exceptional. Their fleet of buses was top-notch, with all the modern amenities we needed. The drivers were punctual and professional, ensuring that our team traveled in comfort and arrived on time for each session. The level of service provided exceeded our expectations, and we will definitely choose Shraddha Travels for future corporate events. Highly recommended!"
    },
    {
        name: "Ms. Priya Deshmukh",
        text: "Shraddha Travels made our corporate team-building event a breeze with their outstanding bus hire services. The 35-seater bus we rented was spacious, clean, and well-maintained. The driver was courteous and ensured a smooth journey for our team. Their flexible booking process and competitive rates made it easy to organize everything. We appreciate their excellent service and look forward to using Shraddha Travels again for our upcoming events."
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Buses For Corporate Events in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Bus on Rent for Events in Pune | Shraddha Travels | Affordable Bus Rentals</title>
    <meta name="description" content="Looking for a bus on rent for events in Pune? Shraddha Travels offers reliable, affordable bus rental services for all types of events, from corporate gatherings to weddings." />
    <meta name="keywords" content="Bus on Rent for Events in Pune, Pune Event Bus Rental, Affordable Event Bus Hire, Bus Rental for Weddings, Group Bus Hire for Events Pune, Shraddha Travels" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Bus on Rent for Events in Pune | Shraddha Travels | Affordable Bus Rentals" />
    <meta property="og:description" content="Book a bus on rent for events in Pune with Shraddha Travels. Reliable, comfortable, and affordable transportation for corporate events, weddings, and group gatherings." />
    <meta property="og:url" content="https://www.shraddhatravels.com/bus-on-rent-for-events-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/event-bus-rental.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus on Rent for Events",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/bus-on-rent-for-events-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Event Bus Rental in Pune",
                    "description": "Reliable and affordable bus rental services for events, corporate functions, weddings, and more in Pune."
                }
            }
        }
        `}
    </script>
</Helmet>



      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-06.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Buses for Corporate Events in Pune</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Busesforcorporate;