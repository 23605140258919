
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetolonavalatempo() {



  const cardData =
  {
    keyword: 'Pune to Lonavala Mini Bus on Rent',
    heading: 'Pune to Lonavala Mini Bus on Rent - Shraddha Travels',
    headingDescription: 'If you’re planning a getaway from Pune, Lonavala is the perfect destination. Nestled in the Western Ghats, this hill station is renowned for its stunning landscapes, lush greenery, and cool climate. Whether you are traveling with family, friends, or colleagues, renting a mini bus from Shraddha Travels ensures a comfortable and convenient journey to this picturesque destination.',
    top: 'Top Places to Visit in Lonavala with Shraddha Travel',

    topPlaces: [
        {
            title: "1. Bhushi Dam",
            description: "A popular spot, especially during the monsoon, where visitors can enjoy splashing in the water and taking scenic photographs. The dam's cascading waterfalls create a mesmerizing sight, attracting families and photographers alike. It's an ideal place for a day out, with food stalls nearby offering local snacks."
        },
        {
            title: "2. Lonavala Lake",
            description: "A serene place to relax and enjoy the view of the lake surrounded by lush hills. Perfect for picnics and peaceful walks, this tranquil spot is ideal for unwinding amidst nature. The reflection of the hills on the lake's surface creates a picturesque scene, making it a favorite for romantic getaways and nature lovers."
        },
        {
            title: "3. Khandala",
            description: "Just a short distance from Lonavala, Khandala offers stunning viewpoints and beautiful landscapes that are perfect for a quick escape. The lush greenery, winding roads, and breathtaking views from spots like Sunset Point make it a favorite among trekkers and adventure seekers. Don't miss the chance to capture some amazing photographs."
        },
        {
            title: "4. Rajmachi Fort",
            description: "This ancient fort offers a glimpse into history along with breathtaking views of the Sahyadri mountains. It’s an ideal hiking destination for adventure enthusiasts, with trails that wind through dense forests. Exploring the fort's ruins and enjoying a picnic amidst nature adds to the overall experience, making it a must-visit for history buffs and outdoor lovers."
        },
        {
            title: "5. Tiger’s Leap",
            description: "Named for its resemblance to a tiger leaping into the valley, this viewpoint offers panoramic views of the lush forests and waterfalls below. The sheer cliffs provide an exhilarating backdrop, and it's a popular spot for adventure enthusiasts looking for a thrilling experience. Sunrise and sunset here are truly magical, offering stunning views that captivate every visitor."
        },
        {
            title: "6. Della Adventure Park",
            description: "Perfect for thrill-seekers, this adventure park features a variety of activities, including zip-lining, paintball, and bungee jumping. With something for everyone, from families to adventure junkies, it's a great place to spend an action-packed day. The park's vibrant atmosphere and well-organized activities ensure a memorable experience for all."
        },
        {
            title: "7. Bhira Dam",
            description: "A hidden gem that offers serene surroundings and a perfect picnic spot for families and friends. Surrounded by hills and greenery, the dam is ideal for a relaxed day out, complete with beautiful views and the sound of flowing water. It's less crowded than other attractions, allowing for a peaceful retreat into nature."
        },
        {
            title: "8. The Karla Caves",
            description: "A group of ancient rock-cut caves that are rich in history and architecture, making them a must-visit for history buffs. These caves date back to the 2nd century BC and showcase intricate carvings and stupas. Exploring the caves provides insight into the region's ancient Buddhist heritage and the craftsmanship of the time."
        },
        {
            title: "9. Lions Point",
            description: "An ideal spot to enjoy stunning sunsets and panoramic views of the valley. It’s a favorite among photographers and nature lovers, with its breathtaking vistas of the surrounding hills and valleys. The cool breeze and vibrant sunset colors create a serene atmosphere, making it perfect for a romantic evening or a peaceful getaway."
        },
        {
            title: "10. Pawna Lake",
            description: "A beautiful man-made lake that’s perfect for camping and relaxing by the water. It's great for those looking to unwind and connect with nature, with facilities for water sports and boating available. The picturesque setting, surrounded by hills, makes it a popular spot for weekend getaways, especially for families and friends."
        }
    ],    
    services: [
        {
            name: "Pune to Lonavala Mini Bus",
            description: "Traveling from Pune to Lonavala is a delightful experience with our mini bus service. Enjoy the scenic views of the Western Ghats as you journey to this popular hill station, known for its lush greenery, refreshing waterfalls, and pleasant climate. Our mini buses are equipped with comfortable seating, large windows for panoramic views, and ample space for your group to relax and unwind, making the ride enjoyable for everyone. Whether you're planning a day trip or a weekend getaway, our service caters to all your travel needs."
        },
        {
            name: "Mini Bus from Pune to Lonavala",
            description: "Our mini bus service from Pune to Lonavala provides a comfortable and spacious travel option for groups of all sizes. With experienced drivers who know the best routes and local attractions, you can relax and enjoy the journey while taking in the breathtaking views of the hills and valleys. The onboard amenities include air conditioning, comfortable seating, and entertainment options to ensure your journey is as enjoyable as the destination itself. Perfect for family outings or corporate trips, our service guarantees a smooth and pleasant ride."
        },
        {
            name: "Pune to Lonavala Mini Bus Booking",
            description: "Booking a mini bus for your trip from Pune to Lonavala is easy and convenient. Simply contact us to reserve your vehicle, and our friendly team will assist you in planning your itinerary to include must-see attractions in Lonavala, such as Bhushi Dam and Tiger's Leap. We aim to ensure a hassle-free travel experience so you can focus on enjoying your trip, complete with a personalized service that meets your group's unique needs."
        },
        {
            name: "Pune Lonavala Mini Bus Rates",
            description: "We offer competitive rates for our mini bus services from Pune to Lonavala, designed to be affordable for all travelers. Our pricing structure takes into account the size of your group and the duration of your trip, providing excellent value for your travel needs. We pride ourselves on transparency, ensuring you receive a clear understanding of costs upfront with no hidden fees. Enjoy the beauty of Lonavala without overspending, while still benefiting from high-quality service."
        },
        {
            name: "Affordable Pune to Lonavala Mini Bus",
            description: "Looking for an affordable travel option? Our Pune to Lonavala mini bus service is budget-friendly and ideal for family outings, corporate trips, or group adventures. We strive to keep our rates low while maintaining high standards of comfort and safety. Enjoy the beautiful landscapes of Lonavala without breaking the bank, and take advantage of our group discounts to maximize your savings. Our goal is to make your travel experience both enjoyable and economical."
        },
        {
            name: "Lonavala to Pune Mini Bus",
            description: "After enjoying the beautiful sights of Lonavala, return to Pune comfortably with our mini bus service. We ensure a smooth ride back to your starting point, allowing you to relax and reflect on your day filled with adventure and exploration. Our drivers are committed to providing a safe journey, and we can accommodate any last-minute stops or detours you may wish to make, ensuring flexibility for your return trip."
        },
        {
            name: "Pune to Lonavala Mini Bus Price",
            description: "The price for our mini bus service from Pune to Lonavala varies based on the distance, duration of your trip, and the size of your group. Contact us for a customized quote that reflects your travel requirements. We offer flexible pricing plans to suit different budgets, ensuring you receive the best value without compromising on service quality. Our dedicated customer support team is here to help you navigate the options and find the best deal for your trip."
        },
        {
            name: "Pune to Lonavala Mini Bus On Rent",
            description: "Renting a mini bus for your trip to Lonavala is an excellent choice for larger groups who want to travel together. Enjoy the flexibility of traveling at your own pace, exploring the stunning surroundings, and stopping at picturesque spots along the way. Our well-maintained vehicles come with experienced drivers who ensure a safe and comfortable journey, allowing you to focus on making memories with your friends or family."
        },
        {
            name: "Pune to Lonavala Khandala Mini Bus",
            description: "Combine your trip to Lonavala with a visit to nearby Khandala, known for its stunning viewpoints and lush landscapes. Our mini bus service can accommodate your itinerary, ensuring you make the most of your time in this beautiful region. With customizable routes and knowledgeable drivers, you can explore popular attractions like Lion’s Point and the Khandala Ghat, creating an unforgettable travel experience."
        },
        {
            name: "Pune to Lonavala Round Trip Mini Bus",
            description: "For those planning a round trip from Pune to Lonavala, our mini bus service offers great deals that make your journey seamless. Enjoy a worry-free experience with convenient pick-up and drop-off options, ensuring you get the most out of your day. Our drivers are well-versed in the routes and local sights, making recommendations along the way for an enriching travel experience."
        },
        {
            name: "Pune to Lonavala Mini Bus on Rent Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Pune to Lonavala mini bus rentals. Our dedicated team is ready to assist you with your booking, answer any questions, and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today and embark on a memorable journey with us!"
        }
    ],    
    tableData: [
        ['- Pune to Lonavala Mini Bus', '- Mini Bus from Pune to Lonavala'],
        ['- Pune to Lonavala Mini Bus Booking', '- Pune Lonavala Mini Bus Rates'],
        ['- Affordable Pune to Lonavala Mini Bus', '- Lonavala to Pune Mini Bus'],
        ['- Pune to Lonavala Mini Bus Price', '- Pune to Lonavala Mini Bus On Rent'],
        ['- Pune to Lonavala Khandala Mini Bus', '- Pune to Lonavala Round Trip Mini Bus']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels' Mini Bus on Rent for Pune to Lonavala?",
            WhyChoosedescription: "When it comes to renting a mini bus for your journey from Pune to Lonavala, Shraddha Travels is your trusted provider for a comfortable and reliable experience. Our service is designed to cater to both leisure and corporate travelers, ensuring you travel in style and comfort. With our dedication to customer satisfaction, we go the extra mile to make your trip memorable, whether it's a day outing or a longer excursion."
        },
        {
            WhyChooseheading: "Comfortable and Spacious Vehicles:",
            WhyChoosedescription: "At Shraddha Travels, we prioritize your comfort above all. Our mini buses are designed with spacious seating and generous legroom, allowing you to relax during the journey. Additionally, we ensure ample storage for your luggage, making it easy to carry everything you need for your trip to Lonavala. Our vehicles are also equipped with climate control to keep you comfortable, regardless of the weather outside."
        },
        {
            WhyChooseheading: "Affordable Rates:",
            WhyChoosedescription: "We believe that quality travel should be accessible to everyone. Our competitive pricing means you can enjoy a comfortable ride without breaking the bank. We pride ourselves on our transparent pricing structure, which means no hidden fees or unexpected costs. This allows you to budget effectively for your trip and enjoy a seamless experience from start to finish."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedescription: "Safety and comfort go hand in hand, and that's why our drivers are not only professional but also highly experienced in navigating the Pune-Lonavala route. They are familiar with the local roads and traffic conditions, ensuring a smooth journey. Our drivers are trained in customer service, making them friendly and approachable, so you can feel at ease throughout your trip."
        },
        {
            WhyChooseheading: "Customizable Itineraries:",
            WhyChoosedescription: "We understand that every traveler has unique preferences and schedules. That's why we offer customizable itineraries to fit your specific needs. Whether you're interested in a quick round trip, a leisurely one-way journey, or a detailed exploration of Lonavala's beautiful sights and nearby attractions, our team is here to create a personalized plan that works for you."
        },
        {
            WhyChooseheading: "Timely Service:",
            WhyChoosedescription: "In the world of travel, time is precious. We understand the importance of punctuality and strive to ensure that our service is always on time. From your initial pick-up to your drop-off back in Pune, you can rely on us for prompt and efficient service. We monitor traffic conditions and adjust our schedules accordingly, so you can enjoy your journey without the stress of delays."
        },
        {
            WhyChooseheading: "Excellent Customer Support:",
            WhyChoosedescription: "Our commitment to customer satisfaction doesn't end when you book your trip. Our dedicated customer support team is available to assist you throughout your journey, addressing any questions or concerns you may have. Whether you need help with your booking, changes to your itinerary, or assistance during your trip, we’re just a phone call away, ensuring a hassle-free experience."
        },
        {
            WhyChooseheading: "Safety First:",
            WhyChoosedescription: "Your safety is our top priority. All of our vehicles undergo regular maintenance and safety checks to ensure they meet the highest standards. Our adherence to safety regulations provides peace of mind as you travel, allowing you to focus on enjoying the beautiful scenery and your time with friends or family. We equip our drivers with the necessary training to handle any situation, ensuring a secure travel environment for all passengers."
        }
    ]
    
  }



  const faqData = [
    {
      question: "How do I book a mini bus from Pune to Lonavala?",
      answer: "You can book a mini bus by contacting us through our website or customer service hotline. Just provide details like the date of travel, number of passengers, and any specific requirements you may have to get started."
    },
    {
      question: "What are the rates for renting a mini bus?",
      answer: "Our rates depend on various factors, including the type of vehicle, duration of the trip, and distance. For an accurate quote, please reach out to us with your travel details, and we’ll provide a competitive estimate."
    },
    {
      question: "Can I customize my itinerary?",
      answer: "Yes, we offer customizable itineraries! You can choose specific places you wish to visit in Lonavala and surrounding areas, and we will adjust the schedule accordingly to make sure you get the most out of your trip."
    },
    {
      question: "Are the drivers experienced?",
      answer: "Absolutely! All our drivers are experienced and knowledgeable about the Pune-Lonavala route, ensuring a safe and pleasant journey while providing insights about the area."
    },
    {
      question: "What amenities are available in the mini bus?",
      answer: "Our mini buses are equipped with comfortable seating, air conditioning, and ample luggage space. Additional amenities such as entertainment systems may be available upon request to enhance your travel experience."
    },
    {
      question: "What is your cancellation policy?",
      answer: "Our cancellation policy varies depending on the booking conditions. Please contact us directly to understand our cancellation terms better and to avoid any confusion."
    },
    {
      question: "Is there a limit on the number of passengers?",
      answer: "Our mini buses can accommodate different group sizes. Please specify the number of passengers when booking, and we will recommend the appropriate vehicle to ensure everyone's comfort."
    },
    {
      question: "How far in advance should I book?",
      answer: "While we accept bookings up to a few days in advance, we recommend booking at least a week in advance to ensure availability and to accommodate any special requests you may have for your trip."
    }
];



const testimonials = [
    {
      name: "Mr. Ravi Deshmukh",
      text: "I recently hired a mini bus from Shraddha Travels for a family trip to Lonavala, and I couldn’t have been more pleased with the experience! The booking process was straightforward, and the staff was very accommodating to our needs. Our driver was punctual and friendly, making the journey enjoyable. The bus was spacious and comfortable, allowing us to relax and take in the beautiful scenery along the way. I highly recommend Shraddha Travels for anyone looking for reliable and affordable transport to Lonavala!"
    },
    {
      name: "Ms. Priya Sharma",
      text: "Our company organized a team outing to Lonavala, and we chose Shraddha Travels for our transportation. From start to finish, the service was exceptional! The mini bus was well-maintained and clean, providing a comfortable ride for all 15 of us. Our driver was knowledgeable about the area and recommended some great spots to visit. We had a fantastic time, and the entire experience was seamless. I will definitely use Shraddha Travels again for future trips!"
    }
];

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Pune to Lonavala Mini bus on Rent Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune to Lonavala Mini Bus on Rent | Affordable Rates</title>
    <meta name="description" content="Rent a mini bus for your trip from Pune to Lonavala. Affordable rates and comfortable travel for groups." />
    <meta name="keywords" content="Pune to Lonavala Mini Bus, Mini Bus Rental Pune, Lonavala Mini Bus Booking, Affordable Mini Bus Pune, Pune to Lonavala Round Trip" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to Lonavala Mini Bus on Rent | Affordable Rates" />
    <meta property="og:description" content="Book our mini bus for a comfortable and affordable trip from Pune to Lonavala. Perfect for family and group travel." />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-to-lonavala-mini-bus-rent" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/lonavala-mini-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Mini Bus Rental from Pune to Lonavala",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-to-lonavala-mini-bus-rent",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Mini Bus Rental from Pune to Lonavala",
                    "description": "Affordable mini bus rentals from Pune to Lonavala, perfect for family trips, corporate outings, and group travel."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 3-01.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for "Pune to Lonavala Mini Bus on Rent" - Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetolonavalatempo;