
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Busrentalforschooltripinpune() {



  const cardData =
  {
    keyword: 'Bus Rentals for School Trips in Pune',
    heading: 'Bus Rentals for School Trips in Pune: Shraddha Travels',
    headingDescription: 'Planning a safe and enjoyable school trip is a big responsibility, and transportation is a key part of that. When it comes to reliable, affordable, and comfortable bus rentals for school trips in Pune, Shraddha Travels stands out as the top choice. With years of experience in providing transportation for schools, we make sure your students travel in comfort and safety to their destination. Whether it is a field trip, a day picnic, or an educational tour, Shraddha Travels ensures smooth and hassle-free travel.',
    top: 'Top reasons why Shraddha Travels is the best choice for bus rentals for school trips in Pune:',

    topPlaces: [
        {
            title: "1. Safety First",
            description: "At Shraddha Travels, we prioritize the safety of students above all else. All our buses undergo regular maintenance and are equipped with modern safety features, including seat belts, first-aid kits, and fire extinguishers. Our drivers are not only skilled but also trained to follow all road safety regulations, ensuring that students reach their destination securely. We conduct routine safety drills and checks to reinforce our commitment to passenger safety."
        },
        {
            title: "2. Comfortable and Spacious Buses",
            description: "Shraddha Travels offers a diverse fleet of spacious and comfortable buses, ideal for school trips of any duration. Our vehicles are designed with ample legroom, cushioned seats, and clean interiors, providing a pleasant travel experience for both students and teachers. Whether it's a short day trip or a long excursion, we ensure that every journey is enjoyable, allowing students to relax and focus on their activities."
        },
        {
            title: "3. Affordable Pricing",
            description: "Understanding the importance of budget-friendly transportation for schools, Shraddha Travels provides affordable bus rental options without compromising on quality or safety. Our competitive pricing structure allows schools to organize trips effectively while staying within budget. We also offer special packages for larger groups, making it easier for schools to plan outings and excursions."
        },
        {
            title: "4. Experienced and Friendly Drivers",
            description: "Our team of experienced drivers is well-versed in the routes around Pune and neighboring areas, ensuring a smooth journey for all. They are not only friendly and professional but also trained to cater to the unique needs of school trips. Our drivers understand the importance of engaging with students and maintaining a positive atmosphere, making every trip enjoyable and stress-free."
        },
        {
            title: "5. Customizable Bus Sizes",
            description: "At Shraddha Travels, we understand that each school trip has different requirements. That's why we offer a range of bus sizes to accommodate various group sizes, from small buses for class trips to larger coaches for entire school groups. This flexibility allows us to meet the specific needs of each trip, ensuring that everyone travels comfortably."
        },
        {
            title: "6. Timely Service",
            description: "Punctuality is essential for school trips, and Shraddha Travels prides itself on reliable service. We guarantee timely pickups and drop-offs, ensuring that buses arrive on schedule for departures and return students back to school promptly. Our commitment to punctuality minimizes disruptions to the school day and enhances the overall experience of the trip."
        },
        {
            title: "7. Eco-Friendly Travel",
            description: "Shraddha Travels is dedicated to protecting the environment. We operate a fleet of fuel-efficient buses that minimize carbon emissions, contributing to a greener planet. By choosing Shraddha Travels for school trips, you are not only ensuring safe transportation but also making an eco-friendly choice that supports sustainability efforts."
        },
        {
            title: "8. 24/7 Customer Support",
            description: "Our dedicated customer support team is available around the clock to assist schools with trip planning, route inquiries, and any last-minute changes. Whether you have questions before your trip or need assistance during it, Shraddha Travels is always ready to help. Our commitment to excellent customer service ensures a hassle-free experience from start to finish."
        },
        {
            title: "9. Clean and Sanitized Buses",
            description: "In the wake of heightened health concerns, hygiene has become more important than ever. Shraddha Travels ensures that all our buses are thoroughly cleaned and sanitized before and after every trip. We use high-quality cleaning agents and follow strict sanitation protocols to keep students safe and healthy throughout their journey."
        },
        {
            title: "10. Trusted by Schools Across Pune",
            description: "With a proven track record, Shraddha Travels has earned the trust of numerous schools in Pune by consistently providing safe and reliable bus rental services for school trips. Our long-standing relationships with educational institutions highlight our commitment to delivering high-quality service. Many schools return to us for their transportation needs, reflecting the satisfaction and trust we've built over the years."
        }
    ],    
    services: [
        {
            name: "Bus Rentals for School Trips in Pune",
            description: "At Shraddha Travels, we offer affordable and reliable bus rentals for school trips in Pune. Our fleet includes buses of various sizes, such as 32-seater, 35-seater, 45-seater, and 50-seater buses, suitable for any school or college group. Whether you're going on a field trip, a picnic, or an educational tour, we have the perfect transportation solution."
        },
        {
            name: "School Buses for Field Trips Near Me",
            description: "If you're searching for school buses for field trips near you, Shraddha Travels Pune has you covered. Our buses are available across Pune and surrounding areas, ensuring you get a reliable, safe, and convenient service for your field trips, with experienced drivers who prioritize student safety."
        },
        {
            name: "Bus Companies for School Field Trips",
            description: "When it comes to finding reputable bus companies for school field trips, Shraddha Travels stands out for its commitment to punctuality and safety. Our buses are equipped with all the necessary amenities to make your trip enjoyable and stress-free, ensuring that your students arrive at their destination on time."
        },
        {
            name: "Bus Rent for Picnic in Pune",
            description: "Planning a school or college picnic? Our bus rent for picnic in Pune services are designed to accommodate group outings. Whether it’s a local picnic spot or an outstation tour, Shraddha Travels provides budget-friendly options for bus rentals for school picnics and mini-bus rentals for picnics."
        },
        {
            name: "Tourist Bus Rent Per Day",
            description: "For longer trips or multi-day excursions, Shraddha Travels offers flexible tourist bus rent per day services. Whether you need transportation for one day or more, we have various options to ensure your trip is comfortable and economical."
        },
        {
            name: "Buses for College Picnic in Pune",
            description: "For colleges planning an outing, our buses for college picnics in Pune are perfect for large groups. With options like the 50-seater bus on rent in Pune and 45-seater buses, we ensure that all your students travel together safely and comfortably."
        },
        {
            name: "Rental Buses for Trips in Pune",
            description: "Looking for rental buses for trips in Pune? We provide services for all types of trips, including educational tours, picnics, and field trips. Whether you need a 32-seater bus on rent in Pune for a school trip or a larger bus, we cater to groups of all sizes."
        },
        {
            name: "Coach Hire for School Trips",
            description: "For more comfort and convenience, opt for coach hire for school trips. Our coaches come with modern amenities and ample seating, ensuring that your students and staff have an enjoyable journey. Whether it's a short trip or a long-distance excursion, Shraddha Travels is here to make your journey stress-free."
        },
        {
            name: "Sleeper Bus Hire for School Trips",
            description: "For overnight journeys, we offer sleeper bus hire for school trips. These buses are ideal for long-distance school tours or trips that require overnight stays, providing comfortable sleeping arrangements for students and staff."
        },
        {
            name: "Bus Hire for College Trips in Pune",
            description: "Our services aren't just for schools! If you need a bus hire for college trips in Pune, we provide a wide range of buses suited for group travel. With options for long-distance and day trips, we ensure your college group travels safely and comfortably."
        },
        {
            name: "Cheap School Bus Rental for Field Trip",
            description: "At Shraddha Travels, we understand the importance of budget-friendly options for schools. That's why we offer cheap school bus rental for field trips without compromising on safety or comfort. Schools in Pune and surrounding areas can rely on us for affordable and efficient transport solutions."
        },
        {
            name: "School Bus on Rent in Pimpri Chinchwad",
            description: "If you're located in Pimpri Chinchwad, Shraddha Travels provides school buses on rent in your area. Whether it's a school field trip or a picnic, our services are available to ensure that your students travel safely and comfortably."
        },
        {
            name: "Private School Bus on Rent in Pimpri Chinchwad",
            description: "Looking for a private school bus on rent in Pimpri Chinchwad? We offer tailored services for schools in this area, with options to suit different group sizes and trip durations. With Shraddha Travels, you can ensure that your students have a safe, private, and reliable transportation solution."
        },
        {
            name: "Bus Rentals For School Trips in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Bus Rentals For School Trips in Pune. We ensure a smooth and enjoyable ride for all our customers."
        }
    ],    
    tableData: [
        ['- Bus hire for school trips', '- Bus rentals for School Trips'],
        ['- School Buses for field trips near me', '- Shraddha Travels Pune'],
        ['- Bus companies for school field trips', '- Bus rentals for school trips Pune'],
        ['- Bus Rent for picnic in Pune', '- Tourist Bus Rent Per Day'],
        ['- Mini Bus rent in Pune for picnic', '- Bus for rent in Pune School Picnic'],
        ['- Buses for college picnic in Pune', '- Rental Buses for Trips in Pune'],
        ['- 50 seater bus on Rent in Pune', '- 45 seater bus for Rent in Pune'],
        ['- 32 seater bus on rent Pune for school trip', '- 35 seater bus on rent for school trip'],
        ['- Coach hire for school trips', '- Coach hire for school trips near me'],
        ['- Sleeper bus hire for school trips', '- Bus Hire for College Trip in Pune'],
        ['- School bus rental for field trip', '- Cheap school bus rental for field trip'],
        ['- School bus on rent in Pimpri Chinchwad', '- Private school bus on rent in Pimpri Chinchwad'],
        ['- Pune to Morachi Chincholi School Bus On Rent', '- Pune to Mahabaleshwar school bus on rent'],
        ['- Pune to Mumbai Darshan school bus on rent', '- Bus hire for school picnic in Pune']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Bus Rentals for School Trips in Pune?",
            WhyChoosedescription: "When it comes to bus rentals for school trips in Pune, Shraddha Travels is the trusted provider that prioritizes safety, comfort, and reliability. Our commitment to excellence ensures that every journey is not only enjoyable but also memorable. Here’s why you should choose us for your school trips:"
        },
        {
            WhyChooseheading: "Safety First:",
            WhyChoosedescription: "We prioritize the safety of students by maintaining a fleet of modern, well-maintained buses. Our experienced drivers are trained to follow all safety regulations, ensuring a secure journey for all passengers. Each vehicle is equipped with essential safety features like seat belts, first-aid kits, and fire extinguishers, giving parents and schools peace of mind."
        },
        {
            WhyChooseheading: "Comfortable Travel:",
            WhyChoosedescription: "Our buses feature comfortable seating arrangements with ample legroom, air conditioning, and clean interiors, making every trip enjoyable. Whether it's a short field trip or a longer excursion, students can relax and focus on having fun while traveling."
        },
        {
            WhyChooseheading: "Experienced Team:",
            WhyChoosedescription: "With years of experience in the travel industry, our dedicated team understands the unique needs of school trips. We provide excellent customer service, assisting with itinerary planning and ensuring smooth coordination on the day of the trip, allowing teachers to focus on their students."
        },
        {
            WhyChooseheading: "Flexible Packages:",
            WhyChoosedescription: "We offer customizable rental packages tailored to your trip duration, the number of students, and budget constraints. Whether you need a single bus for a class trip or multiple buses for larger groups, we have flexible solutions to meet your needs."
        },
        {
            WhyChooseheading: "Punctuality Guaranteed:",
            WhyChoosedescription: "We value your time and understand the importance of sticking to schedules. Our commitment to punctuality ensures that buses arrive on time for pickups and drop-offs, allowing schools to maintain their planned itineraries without any hassle or delays."
        },
        {
            WhyChooseheading: "Local Expertise:",
            WhyChoosedescription: "As a Pune-based service, we have extensive knowledge of local attractions, safe routes, and traffic patterns. This expertise enables us to provide recommendations for interesting destinations and ensures that your school trip is both educational and enjoyable."
        },
        {
            WhyChooseheading: "Affordable Rates:",
            WhyChoosedescription: "We provide competitive pricing without compromising on quality. Our transparent pricing structure means no hidden fees, making school trips accessible for all. We work with schools to find budget-friendly options that meet their needs."
        },
        {
            WhyChooseheading: "24/7 Support:",
            WhyChoosedescription: "Our customer support team is available around the clock to assist with inquiries, booking adjustments, or any last-minute changes. This level of support provides peace of mind throughout the trip, ensuring that schools have a reliable partner every step of the way."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What types of buses do you offer for school trips?",
        answer: "We offer a range of buses, including mini-buses, standard coaches, and luxury buses, to accommodate different group sizes and preferences, ensuring that every school trip is catered to your specific needs."
    },
    {
        question: "How do I book a bus for a school trip?",
        answer: "You can easily book a bus by contacting us through our website or phone. Our team will guide you through the process and help customize your rental package to fit your trip requirements."
    },
    {
        question: "Are the drivers experienced and trained?",
        answer: "Yes, all our drivers are highly experienced, licensed, and trained specifically to handle school trips, ensuring a safe and smooth journey for all students."
    },
    {
        question: "Can we request a specific itinerary?",
        answer: "Absolutely! We encourage you to provide your desired itinerary, and we’ll do our best to accommodate your requests, making your trip more enjoyable and aligned with your plans."
    },
    {
        question: "What safety measures do you have in place?",
        answer: "Our buses undergo regular maintenance checks, and we adhere to all safety regulations. Additionally, we ensure that drivers follow safe driving practices to keep everyone on board safe."
    },
    {
        question: "Is there a minimum rental duration?",
        answer: "Yes, we typically have a minimum rental duration, but please contact us for specific details, as it may vary depending on the bus type and destination."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods, including cash, bank transfers, and online payments for your convenience, making the booking process hassle-free."
    },
    {
        question: "What should we do in case of an emergency during the trip?",
        answer: "Our drivers are trained to handle emergencies effectively, and we also provide a 24/7 support line for immediate assistance, ensuring you are never alone in case of an unexpected situation."
    },
    {
        question: "Are there any hidden charges?",
        answer: "No, we believe in transparent pricing. All charges will be clearly outlined in your rental agreement, so you won’t face any surprises during or after your trip."
    },
    {
        question: "Can we hire the bus for multiple days?",
        answer: "Yes, we offer multi-day rental options for longer school trips. Please contact us for tailored pricing and arrangements that suit your needs."
    }
];



const testimonials = [
    {
        name: "Mrs. Desai",
        text: "We recently organized a school trip for our students, and we chose Shraddha Travels for our bus rentals. The entire experience was fantastic! The bus was clean and comfortable, and the driver was not only punctual but also very friendly. Our students had a great time, and we felt safe throughout the journey. I highly recommend Shraddha Travels for any school outing!"
    },
    {
        name: "Mr. Sharma",
        text: "Shraddha Travels made our field trip a memorable experience! The booking process was seamless, and the staff was incredibly helpful in planning our itinerary. The bus was spacious, and our kids loved the journey. The driver was attentive and ensured we arrived at each destination on time. We will definitely be using their services again for future trips!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Bus Rentals For School Trips in Pune Contact Number: ",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus Rentals For School Trips in Pune | Affordable School Bus Rentals</title>
    <meta name="description" content="Reliable bus rental services for school trips in Pune, offering various sizes and comfortable transportation for field trips and picnics." />
    <meta name="keywords" content="Bus Rentals For School Trips in Pune, School Bus Hire, Field Trip Bus Rental, Affordable School Buses, Picnic Bus Rentals in Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus Rentals For School Trips in Pune | Affordable School Bus Rentals" />
    <meta property="og:description" content="Hire comfortable buses for school trips and field trips in Pune. Services include rentals for picnics and excursions." />
    <meta property="og:url" content="https://www.amuratatravels.com/bus-rentals-for-school-trips-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/school-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Rentals For School Trips",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/bus-rentals-for-school-trips-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "School Bus Rental in Pune",
                    "description": "Affordable and reliable bus rental services for school trips, picnics, and field trips in Pune."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-07.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Bus Rentals for School Trips in Pune</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Busrentalforschooltripinpune;