
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Seater45onrentinpune() {



  const cardData =
  {
    keyword: '45-Seater Bus Rental in Pune',
    heading: 'Shraddha Travels: 45-Seater Bus Rental in Pune',
    headingDescription: 'When organizing group travel in Pune, whether for a corporate event, wedding, school trip, or extended tour, renting a 45-seater bus from Shraddha Travels is the ideal choice. Our fleet of well-maintained, spacious buses ensures a travel experience marked by comfort, safety, and efficiency. Here is why our 45-seater bus rental service is the perfect solution for your group travel needs:',
    top: 'Top Reasons to Rent a 45-Seater Bus from Shraddha Travels in Pune',

    topPlaces: [
        {
          title: "1. Capacity for Large Groups",
          description: "Our 45-seater bus is perfect for accommodating big groups, making it ideal for corporate outings, school trips, family reunions, and large events. With ample space, everyone can travel together comfortably, fostering connections and enhancing the overall experience. Whether you're attending a conference or celebrating a special occasion, you can count on us to provide a seamless travel experience."
        },
        {
          title: "2. Cost-Effective Group Travel",
          description: "Renting a single 45-seater bus is often more economical than hiring multiple smaller vehicles. This not only saves on fuel, tolls, and parking fees but also simplifies logistics, allowing you to allocate more of your budget towards activities and experiences. By choosing group travel, you reduce your environmental footprint while maximizing the enjoyment of your trip."
        },
        {
          title: "3. Ultimate Comfort",
          description: "Designed with spacious seating and ample legroom, our buses ensure a comfortable journey for all passengers. Each seat is thoughtfully designed to provide support during both short and long-distance trips. With air conditioning and climate control, you can relax and enjoy the ride without feeling cramped or overheated, making your journey a pleasure from start to finish."
        },
        {
          title: "4. Experienced Drivers",
          description: "Our professional drivers are not only trained in safe driving practices but also possess extensive knowledge of local routes and traffic patterns. They prioritize passenger safety and comfort, ensuring a smooth travel experience. Friendly and approachable, our drivers are happy to assist with any questions and share insights about the destinations you’ll visit."
        },
        {
          title: "5. Flexible Itineraries",
          description: "We understand that every group has unique needs. Our rental service allows you to customize your itinerary, including planned stops and activities. This flexibility ensures that your travel experience aligns perfectly with your group’s preferences and schedule, allowing for spontaneous detours or extended visits at points of interest, making your trip truly memorable."
        },
        {
          title: "6. Safety First",
          description: "The safety of our passengers is our top priority. Our buses undergo regular maintenance and rigorous safety checks to ensure they are in excellent condition for your journey. We also equip our vehicles with safety features, including seat belts, emergency exits, and first aid kits, providing peace of mind as you travel with us."
        },
        {
          title: "7. Modern Amenities",
          description: "Our 45-seater buses come equipped with essential amenities, including air conditioning, entertainment systems, and charging ports. Whether you want to listen to music, watch a movie, or charge your devices, our buses are designed to keep you comfortable and entertained throughout your journey, making long trips enjoyable."
        },
        {
          title: "8. 24/7 Customer Support",
          description: "Our dedicated customer service team is available around the clock to assist you with any inquiries or changes regarding your booking. We strive to ensure a seamless rental experience, addressing any concerns promptly to enhance your travel experience. Whether you need assistance with last-minute changes or have questions during your journey, we are here to help."
        },
        {
          title: "9. Positive Reputation",
          description: "Shraddha Travels has built a strong reputation for reliability and customer satisfaction. Many of our clients return for their travel needs, highlighting our commitment to exceptional service and positive travel experiences. Our clients’ testimonials reflect the high standards we uphold and the joy we bring to group travel, ensuring that every journey is memorable."
        },
        {
          title: "10. Easy Booking Process",
          description: "Our user-friendly online booking system allows you to reserve your 45-seater bus quickly and efficiently. With straightforward navigation and clear information, you can focus on planning your itinerary and enjoying your trip without the stress of complicated booking procedures. Our transparent pricing ensures you know exactly what to expect, making the entire process hassle-free."
        }
      ],

    services: [
  {
    name: "45 Seater Bus for Hire for Outstation",
    description: "When planning an outstation trip with a large group, Shraddha Travels offers a fleet of 45-seater buses that are perfect for extended journeys. Our buses are well-maintained, providing ample space and comfort for all passengers. Whether you’re heading to a distant destination for leisure or business, our outstation bus rentals ensure a smooth and enjoyable ride."
  },
  {
    name: "45 Seater Bus for Wedding for Outstation Pune",
    description: "For couples planning a destination wedding or transporting guests from Pune to an outstation wedding venue, our 45-seater buses are an excellent choice. These buses offer luxurious seating and air-conditioning, making the journey comfortable for all attendees. We handle the logistics so you can focus on your special day without any travel concerns."
  },
  {
    name: "45 Seater AC Bus for Hire",
    description: "Our 45-seater AC buses are designed to keep you cool and comfortable, even during the hottest months. With powerful air-conditioning systems, you can enjoy a pleasant ride no matter the weather. Ideal for long trips, corporate events, or group outings, our AC buses ensure a refreshing travel experience."
  },
  {
    name: "45 Seater Non-AC Bus for Hire",
    description: "For those looking for a more budget-friendly option, we offer 45-seater non-AC buses. These buses provide spacious seating and ample room for luggage, making them suitable for short to medium-distance travel. Perfect for cooler weather or for those looking to economize, our non-AC buses offer comfort at a lower cost."
  },
  {
    name: "45 Seater AC Bus for Marriage Function",
    description: "Make your wedding function special by hiring a 45-seater AC bus from Shraddha Travels. Our buses are equipped with comfortable seating and climate control to ensure your guests travel in style and comfort. Whether it’s transporting guests to the wedding venue or the reception, our AC buses provide a premium travel experience."
  },
  {
    name: "45 Seater Bus for Corporate Events",
    description: "When it comes to corporate events, punctuality and comfort are key. Our 45-seater buses are perfect for transporting employees or clients to conferences, seminars, or team-building activities. With comfortable seating and ample space, our buses ensure that everyone arrives at the event refreshed and ready."
  },
  {
    name: "45 Seater Bus on Rent in Pimpri Chinchwad",
    description: "Residents and businesses in Pimpri Chinchwad can now benefit from our 45-seater bus rentals. Whether you’re planning a group outing, a corporate event, or a family gathering, our local service provides reliable and comfortable transportation. We offer competitive rates and excellent customer service to meet all your group travel needs."
  },
  {
    name: "45 Seater Bus Hire for School Picnic",
    description: "Organizing a school picnic? Our 45-seater buses are ideal for transporting students and staff. With ample seating and space for backpacks and picnic supplies, our buses ensure a smooth and enjoyable trip. Safety and comfort are our priorities, making sure your school outing is a success."
  },
  {
    name: "45 Seater Bus Rental for Marriage Function",
    description: "For large marriage functions, our 45-seater bus rentals offer a convenient and comfortable solution for guest transportation. We provide well-maintained, spacious buses equipped with the necessary amenities to ensure that everyone arrives at the wedding venue relaxed and ready to celebrate."
  },
  {
    name: "45 Seater Bus Rent for Corporate Picnic",
    description: "Organizing a corporate picnic requires seamless transportation for your team. Our 45-seater buses are ideal for such events, offering ample space and comfort. Whether you’re heading to a nearby park or a distant resort, our buses ensure that your employees travel comfortably, arriving at the picnic spot ready to enjoy the day."
  },
  {
    name: "45 Seater Bus for Outstation from Pune",
    description: "Planning an outstation trip from Pune? Our 45-seater buses are perfect for group travel to destinations beyond the city. Equipped with comfortable seating, air-conditioning, and ample luggage space, our buses provide a smooth and enjoyable ride for any long-distance journey. Whether it's a family vacation, a corporate retreat, or a religious tour, we ensure a comfortable travel experience."
  },
  {
    name: "45 Seater Bus Rental Services in Pune",
    description: "Shraddha Travels offers comprehensive 45-seater bus rental services in Pune. Our fleet of well-maintained buses is perfect for various group travel needs, including corporate events, weddings, school excursions, and more. With a focus on comfort, safety, and reliability, we provide tailored solutions to meet your specific requirements."
  },
  {
    name: "45 Seater Bus Rental Services in Hadapsar",
    description: "For residents and businesses in Hadapsar, Shraddha Travels provides reliable 45-seater bus rental services. Whether you need transportation for a wedding, corporate event, or group outing, our buses offer comfort and convenience. Our local service ensures that you receive timely and professional transport solutions tailored to your needs."
  },
  {
    name: "45 Seater Bus Rental for Wedding in Pune",
    description: "Make your wedding day special with our 45-seater bus rental services. We offer luxurious and comfortable buses to transport guests to and from the wedding venue in Pune. Our buses come equipped with modern amenities, ensuring a pleasant journey for everyone involved in your special day."
  },
  {
    name: "45 Seater Bus for Company Picnic",
    description: "For a company picnic, our 45-seater buses offer the perfect solution for transporting employees. With spacious interiors and comfortable seating, our buses are designed to accommodate large groups and ensure a smooth, enjoyable ride to your picnic destination. We handle the logistics so you can focus on enjoying the event."
  },
  {
    name: "45 Seater Bus Rent in Pune Contact Number",
    description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient 45 Seater Bus Rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
  }
],
tableData: [
    ['- 45 Seater Bus for Hire for Outstation', '- 45 Seater Bus for Wedding for Outstation Pune'],
    ['- 45 Seater AC Bus for Hire', '- 45 Seater Non-AC Bus for Hire'],
    ['- 45 Seater AC Bus for Marriage Function', '- 45 Seater Bus for Corporate Events'],
    ['- 45 Seater Bus on Rent in Pimpri Chinchwad', '- 45 Seater Bus Hire for School Picnic'],
    ['- 45 Seater Bus Rental for Marriage Function', '- 45 Seater Bus Rent for Corporate Picnic'],
    ['- 45 Seater Bus for Outstation from Pune', '- 45 Seater Bus Rental Services in Pune'],
    ['- 45 Seater Bus Rental Services in Hadapsar', '']
  ],  
  whychoose: [
    {
      WhyChooseheading: "Why Choose Shraddha Travels for 45-Seater Bus Rental in Pune?",
      WhyChoosedescription: "At Shraddha Travels, we pride ourselves on offering exceptional 45-seater bus rental services in Pune. Our commitment to providing a superior travel experience, coupled with our attention to detail and customer satisfaction, sets us apart. Here’s why you should choose us for your next group travel:"
    },
    {
      WhyChooseheading: "Spacious Comfort:",
      WhyChoosedescription: "Our 45-seater buses are designed to accommodate large groups with ample space for each passenger. The generous seating arrangement, combined with ergonomic design, ensures that everyone enjoys a comfortable journey, whether it's a short local trip or a long-distance excursion. Each seat is crafted to provide maximum comfort, making your travel experience enjoyable."
    },
    {
      WhyChooseheading: "Climate-Controlled Environment:",
      WhyChoosedescription: "Travel in comfort regardless of external weather conditions. Our buses are equipped with state-of-the-art air-conditioning systems that effectively regulate the temperature, maintaining a pleasant and cool atmosphere throughout your journey. This feature is particularly beneficial during hot summer months, ensuring all passengers remain comfortable and relaxed."
    },
    {
      WhyChooseheading: "Enhanced Relaxation:",
      WhyChoosedescription: "With reclining seats that allow for personalized comfort adjustments, our buses ensure a relaxing ride. Whether you're traveling for a few hours or a whole day, you can find your ideal seating position and enjoy the journey. The spacious legroom further enhances comfort, allowing you to stretch out and unwind during your trip."
    },
    {
      WhyChooseheading: "Modern Entertainment:",
      WhyChoosedescription: "Long trips are made enjoyable with our advanced entertainment systems. Each bus features high-definition LED TVs and quality sound systems, providing a variety of entertainment options such as movies, music, and games to keep everyone engaged throughout the ride. This ensures that time flies by and makes the journey part of the adventure."
    },
    {
      WhyChooseheading: "Ample Luggage Space:",
      WhyChoosedescription: "Our 45-seater buses come with extensive luggage compartments designed to handle the storage needs of large groups. Whether you have large bags, sports equipment, or multiple pieces of luggage, there's ample space to store everything safely and conveniently. This feature is ideal for family vacations, corporate outings, or group trips where extra gear is needed."
    },
    {
      WhyChooseheading: "Top-Notch Safety:",
      WhyChoosedescription: "Safety is our utmost priority. Our buses are equipped with essential safety features such as seat belts, emergency exits, fire extinguishers, and first-aid kits to ensure the well-being of all passengers. Additionally, GPS tracking allows for real-time monitoring, providing peace of mind that your journey is secure and well-managed."
    },
    {
      WhyChooseheading: "Professional Drivers:",
      WhyChoosedescription: "Our experienced and professional drivers are adept at navigating both city and outstation routes. They are trained in safe driving practices and customer service, ensuring a smooth and timely travel experience. Their local knowledge enhances your journey, allowing for optimal route planning and quick adjustments if needed, ensuring you arrive on time."
    },
    {
      WhyChooseheading: "Impeccable Maintenance:",
      WhyChoosedescription: "We maintain our fleet to the highest standards, conducting regular servicing and thorough inspections to ensure each 45-seater bus is in excellent working condition. Cleanliness and hygiene are prioritized, with buses meticulously cleaned after each trip to ensure a pleasant environment for every journey."
    },
    {
      WhyChooseheading: "Transparent Pricing:",
      WhyChoosedescription: "We offer competitive and transparent pricing with no hidden costs, allowing you to plan your budget with confidence. Our clear pricing structure ensures that you understand exactly what you're paying for, and our commitment to value means you receive the best service without breaking the bank."
    },
    {
      WhyChooseheading: "Customizable Solutions:",
      WhyChoosedescription: "We offer flexible rental solutions tailored to your specific needs. Whether you require a bus for a day, a week, or a special event, we can customize our services to match your itinerary and budget. Our team works closely with you to ensure all your travel requirements are met, providing a personalized experience."
    }
  ]
  
  }



  const faqData = [
    {
      question: "What is included in the rental of a 45-seater bus?",
      answer: "The rental includes the bus, driver, fuel, and maintenance. Additional amenities like air-conditioning, entertainment systems, and luggage compartments are also included. We offer transparent pricing with no hidden fees."
    },
    {
      question: "Can I customize the bus rental service?",
      answer: "Yes, we provide customizable solutions to fit your specific needs. Whether you need the bus for a corporate event, wedding, or any other occasion, we can tailor the service to your requirements."
    },
    {
      question: "Are there any extra charges for long-distance travel?",
      answer: "Long-distance travel might incur additional charges based on the distance covered. However, we offer competitive and transparent pricing, and any extra costs will be communicated upfront."
    },
    {
      question: "How do I book a 45-seater bus?",
      answer: "You can book a 45-seater bus by contacting us directly via phone or email. Our customer service team will assist you in confirming availability, discussing your requirements, and finalizing the booking."
    },
    {
      question: "What safety measures are in place on the bus?",
      answer: "Our buses are equipped with seat belts, emergency exits, fire extinguishers, first-aid kits, and GPS tracking for real-time monitoring. We prioritize passenger safety and ensure all safety features are in place."
    },
    {
      question: "Are the buses well-maintained?",
      answer: "Yes, our buses are regularly serviced and thoroughly inspected to ensure they are in excellent working condition. We also maintain high standards of cleanliness and hygiene."
    },
    {
      question: "What types of events can I use the 45-seater bus for?",
      answer: "Our 45-seater buses are suitable for various events including corporate functions, weddings, school trips, family outings, and long-distance tours."
    },
    {
      question: "Can I request a specific type of bus?",
      answer: "We offer both air-conditioned and non-air-conditioned buses. You can specify your preference when making a reservation, and we will do our best to accommodate your request."
    },
    {
      question: "How can I contact Shraddha Travels for more information?",
      answer: "For more information, you can reach out to us via phone, email, or through our website contact form. Our team is available to answer any questions and assist with your booking needs."
    },
    {
      question: "What is the cancellation policy?",
      answer: "Our cancellation policy varies based on the booking terms. Please contact us directly to discuss cancellation terms and any applicable fees."
    }
  ];
  


  const testimonials = [
    {
      name: "Mr. Sanjay Patel",
      text: "We recently hired a 45-seater bus from Shraddha Travels for our company's annual picnic, and the experience was fantastic! The bus was immaculate and equipped with all the modern amenities we needed for a long trip. The driver was highly professional, punctual, and ensured a smooth and enjoyable ride for everyone. Shraddha Travels made our event hassle-free and memorable. Highly recommended for anyone in need of reliable and comfortable group transportation in Pune!"
    },
    {
      name: "Ms. Anita Deshmukh",
      text: "Shraddha Travels provided us with a 45-seater bus for a wedding function, and we couldn’t be happier with their service. The bus was spacious, air-conditioned, and impeccably clean. It comfortably accommodated all our guests, and the journey to the wedding venue was seamless. The driver was courteous and well-organized, making sure everyone was taken care of. Shraddha Travels truly exceeded our expectations, and I would definitely use their services again for future events."
    }
  ];
  


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " 45-Seater Bus Rent in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | 45 Seater Bus on Rent in Pune | Affordable Bus Rentals</title>
    <meta name="description" content="Rent 45-seater buses in Pune for weddings, corporate events, and outstation trips. Competitive rates and reliable service!" />
    <meta name="keywords" content="45 seater bus on rent in Pune, 45 seater bus for weddings, corporate events, outstation bus rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | 45 Seater Bus on Rent in Pune | Affordable Bus Rentals" />
    <meta property="og:description" content="Discover 45-seater bus rental options in Pune for weddings, corporate events, and outstation trips. Book now!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/45-seater-bus-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/45-seater-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "45 Seater Bus Rental in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/45-seater-bus-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "45 Seater Bus",
                    "description": "Rent a comfortable 45-seater bus for weddings, corporate events, and outstation trips in Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-13.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs: 45-Seater Bus on Rent in Pune - Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Seater45onrentinpune;