
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Busserviceforcorporate() {



  const cardData =
  {
    keyword: ' Bus Services for Corporate Events in Pune',
    heading: 'Shraddha Travels: Bus Services for Corporate Events in Pune',
    headingDescription: 'When organizing corporate events, dependable transportation for your team, clients, or business partners is essential for ensuring everything runs smoothly. Shraddha Travels offers premium bus services specifically tailored for corporate events in Pune. Whether it is a conference, team-building exercise, or business retreat, our extensive fleet and professional service guarantee that all your corporate transportation needs are met. Here are the top bus services offered by Shraddha Travels for corporate functions in Pune:',
    // top: 'Top Places to Visit in Pune with Shraddha Travels:',

    topPlaces: [
        {
            title: "1. Luxury Executive Buses",
            description: "Our luxury executive buses are perfect for prestigious corporate events where comfort and elegance are key. With plush seating, Wi-Fi, and entertainment systems, these buses offer a high-end travel experience for executives and clients alike."
        },
        {
            title: "2. Standard Corporate Coaches",
            description: "For traditional corporate events, our standard corporate coaches feature spacious seating, air conditioning, and ample legroom, making them ideal for transporting large groups to conferences, seminars, or workshops with ease."
        },
        {
            title: "3. Shuttle Services",
            description: "Shraddha Travels offers efficient shuttle services for hassle-free transportation within the city. Whether it’s moving employees between office locations or transporting guests from hotels to venues, our shuttles ensure timely and comfortable travel."
        },
        {
            title: "4. Mini Buses",
            description: "Our mini buses are perfect for smaller corporate groups, providing the flexibility and convenience needed for local trips or short outings. With comfortable seating, these buses are suited for various business events."
        },
        {
            title: "5. Executive Mini Coaches",
            description: "For medium-sized corporate groups, our executive mini coaches offer both comfort and practicality. Featuring adjustable seating, climate control, and modern interiors, these buses are ideal for business trips and transporting clients."
        },
        {
            title: "6. VIP Charter Buses",
            description: "When you want to impress, our VIP charter buses provide the pinnacle of luxury and sophistication. With top-notch interiors, personalized services, and advanced amenities, these buses are the perfect choice for high-profile meetings and corporate functions."
        },
        {
            title: "7. Conference Buses",
            description: "Our conference buses are designed to allow meetings on the go, equipped with AV technology, spacious work areas, and comfortable seating. These buses ensure productivity continues even while traveling between events."
        },
        {
            title: "8. Employee Transport Buses",
            description: "For regular employee transportation, our cost-effective employee transport buses are the solution. These buses are reliable for daily commutes, ensuring employees arrive at the office or event on time and ready to go."
        },
        {
            title: "9. Event Shuttle Buses",
            description: "Designed for large-scale corporate events, our event shuttle buses easily handle high passenger volumes. Whether it’s a trade show, convention, or gathering, these buses transport attendees efficiently and comfortably."
        },
        {
            title: "10. Customizable Corporate Packages",
            description: "Shraddha Travels offers customizable transportation packages tailored to your specific corporate needs. From flexible schedules to premium amenities, we design our services to fit your event requirements and budget."
        }
    ],    
    services: [
        {
            name: "Corporate Cab Services in Pune",
            description: "For businesses in Pune, having reliable and efficient transportation for employees, clients, and partners is essential. Shraddha Travels provides top-notch corporate cab services across various localities in Pune to ensure seamless travel for your corporate needs."
        },
        {
            name: "Corporate Cab Services in Hadapsar",
            description: "Hadapsar is a bustling hub in Pune, and our corporate cab services here are designed to meet the needs of businesses in the area. Whether you require regular employee transport or need to arrange client pick-ups, our fleet of well-maintained cabs ensures timely and comfortable travel for business meetings and special events."
        },
        {
            name: "Corporate Cab Services in Viman Nagar",
            description: "Viman Nagar is a key business district, and we offer tailored corporate cab services to cater to its demands. Our services include punctual pick-ups and drop-offs, high-end vehicles for executive transport, and flexible scheduling to accommodate your corporate requirements."
        },
        {
            name: "Corporate Cab Services in Hinjewadi",
            description: "As a major IT and business hub, Hinjewadi requires reliable transportation solutions. Our corporate cab services in Hinjewadi are designed to provide efficient and comfortable travel for your business needs."
        },
        {
            name: "Corporate Cab Services in Baner and Aundh",
            description: "Baner and Aundh are prominent business areas in Pune, and we offer specialized corporate cab services for these localities. Our services include executive cabs for high-profile meetings, shuttle services for large groups, and flexible options to suit your scheduling needs."
        },
        {
            name: "Bus Service for Corporate in Pimpri Chinchwad",
            description: "Looking for bus services for corporate events in Pimpri Chinchwad? Shraddha Travels provides efficient and reliable transportation solutions tailored to corporate needs. Whether you need to transport employees to an event, seminar, or daily commute, our buses are well-equipped to ensure comfort and punctuality."
        },
        {
            name: "Tempo Traveller on Rent in Pune for Corporate Events",
            description: "Shraddha Travels offers tempo travellers on rent in Pune for corporate events, providing the perfect solution for group transportation. Our tempo travellers are spacious and comfortable, ideal for corporate outings, team-building events, and client shuttles."
        },
        {
            name: "Mini Bus on Rent for Corporate Events",
            description: "Looking for a mini bus on rent for corporate events? Shraddha Travels provides mini buses that are ideal for corporate gatherings, team outings, and special events. Our mini buses are well-maintained, comfortable, and equipped with amenities to ensure a pleasant journey."
        },
        {
            name: "Bus Service for Corporate Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Bus Service for Corporate Pune. We ensure a smooth and enjoyable ride for all our customers."
        }
    ],
    tableData: [
        ['- Bus Service for Corporate Office Pune', '- Bus Service for Corporate Office Picnic'],
        ['- Bus Service for Corporate Outing Pune', '- Mini Bus for Corporate Outing'],
        ['- AC / Non AC Bus for Corporate Events', '- 17, 26, 32, 35, 45 Seater Bus for Corporate Outing'],
        ['- Employee Transportation Services in Pune', '- Bus on Rent for Corporate in Pune'],
        ['- Corporate Cab Services in Hadapsar', '- Corporate Cab Services in Viman Nagar'],
        ['- Corporate Cab Services in Hinjewadi', '- Corporate Cab Services in Baner Aundh'],
        ['- Bus Rental for Corporate Events', '- Bus Service for Corporate in Pimpri Chinchwad'],
        ['- Bus Service for Corporate in Pimpri Chinchwad Contact Number', '- Tempo Traveller on Rent in Pune for Corporate Events']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Bus Service for Corporate Events in Pune?",
            WhyChoosedescription: "At Shraddha Travels, we understand the importance of time and reliability for corporate events. Our service is designed to meet the specific needs of businesses, ensuring your team and clients travel comfortably and punctually."
        },
        {
            WhyChooseheading: "Professional and Punctual Service:",
            WhyChoosedescription: "Our well-trained drivers prioritize punctuality, ensuring that your team, clients, or business partners reach their destinations on time, every time."
        },
        {
            WhyChooseheading: "Diverse Fleet for Corporate Needs:",
            WhyChoosedescription: "We offer a wide range of buses, from luxury options for executives to mini-buses for small teams, catering to various group sizes and requirements."
        },
        {
            WhyChooseheading: "Comfort and Convenience:",
            WhyChoosedcription: "Our buses are equipped with modern amenities like air conditioning, comfortable seating, and Wi-Fi, making long journeys smooth and stress-free."
        },
        {
            WhyChooseheading: "Tailored Packages for Corporate Events:",
            WhyChoosedescription: "We provide customized packages with flexible scheduling and pricing options to suit your specific corporate transportation needs."
        },
        {
            WhyChooseheading: "Safety First:",
            WhyChoosedescription: "Safety is our top priority. Our buses are regularly maintained and inspected, and our drivers follow strict safety protocols for secure journeys."
        },
        {
            WhyChooseheading: "Experienced in Corporate Transport:",
            WhyChoosedescription: "With years of experience in corporate transportation, we understand the unique needs of businesses, ensuring expert handling of all transportation requirements."
        }
    ]
    
  }



  const faqData = [
    {
        question: "What types of buses do you offer for corporate events?",
        answer: "Shraddha Travels offers a variety of buses, including luxury executive buses, mini-buses, standard coaches, and VIP charter buses. Our fleet is tailored to accommodate different group sizes and corporate event needs."
    },
    {
        question: "How can I book a bus for a corporate event?",
        answer: "You can book a bus for your corporate event by contacting us at +91 8087847191 or visiting our website. We offer easy booking options and flexible payment methods to suit your convenience."
    },
    {
        question: "Do you offer transportation packages for multiple-day corporate events?",
        answer: "Yes, we provide customizable packages for multi-day corporate events. Whether it’s a conference, seminar, or business retreat, we can design a transportation plan that fits your schedule."
    },
    {
        question: "Is it possible to book buses for employee transportation on a regular basis?",
        answer: "Absolutely! We offer long-term contracts for regular employee transportation services. Our buses are perfect for daily commutes, ensuring your team arrives at the office comfortably and on time."
    },
    {
        question: "Can we customize the amenities inside the buses for special corporate events?",
        answer: "Yes, we offer customizable amenities such as Wi-Fi, AV equipment, and other features to make your corporate journey productive and comfortable. Let us know your specific needs during the booking process."
    },
    {
        question: "How far in advance should I book a bus for my corporate event?",
        answer: "We recommend booking at least 1-2 weeks in advance to ensure availability, especially during peak event seasons. However, we also accommodate last-minute bookings based on availability."
    },
    {
        question: "Do you offer bus services outside Pune for corporate events?",
        answer: "Yes, Shraddha Travels provides inter-city transportation services for corporate events. Whether your event is within Pune or in nearby cities, we can provide a reliable transportation solution."
    },
    {
        question: "Are your drivers experienced in handling corporate transportation?",
        answer: "Yes, our drivers are experienced professionals who are familiar with corporate environments. They are trained to prioritize punctuality, safety, and provide exceptional service for corporate clients."
    },
    {
        question: "What safety measures are in place for corporate bus transportation?",
        answer: "Our buses undergo regular maintenance checks, and we follow strict safety protocols. All our drivers are well-trained in safe driving practices to ensure a secure and comfortable journey for your team."
    },
    {
        question: "Can we book buses for large-scale corporate events like conferences or trade shows?",
        answer: "Yes, we have the capacity to manage transportation for large-scale events such as conferences and trade shows. Our fleet can accommodate groups of various sizes, and we provide tailored solutions for large gatherings."
    }
];



const testimonials = [
    {
        name: "Mr. Rajesh Singh",
        text: "We recently hired Shraddha Travels for our corporate event in Pune, and the experience was flawless! From the booking process to the punctual pick-up and drop-off, everything was managed with professionalism. The bus was spacious, clean, and equipped with all the necessary amenities, making the journey comfortable for our entire team. The driver was courteous and ensured timely travel, which is crucial for corporate events. I would highly recommend Shraddha Travels for any corporate transportation needs!"
    },
    {
        name: "Ms. Priya M",
        text: "Shraddha Travels made our corporate outing a great success! We booked their bus service for a team-building event, and they exceeded our expectations. The bus was well-maintained, and the driver was incredibly professional and punctual. Our employees enjoyed a smooth and comfortable ride, and we appreciated the personalized service we received. Shraddha Travels is now our go-to for corporate transportation in Pune!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Bus Service for Corporate Pune Contact Number: ",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus Service for Corporate in Pune | Reliable Corporate Transportation</title>
    <meta name="description" content="Efficient bus services for corporate events, outings, and employee transportation in Pune." />
    <meta name="keywords" content="Bus Service for Corporate Pune, Corporate Bus Hire, Employee Transportation, Mini Bus for Corporate Events, Corporate Cab Services" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus Service for Corporate in Pune | Reliable Corporate Transportation" />
    <meta property="og:description" content="Specialized bus services for corporate outings, events, and employee transport in Pune." />
    <meta property="og:url" content="https://www.amuratatravels.com/bus-service-for-corporate-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/corporate-bus-service.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Service for Corporate",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/bus-service-for-corporate-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Corporate Bus Rental in Pune",
                    "description": "Reliable bus services for corporate outings, events, and employee transportation in Pune."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-10.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs - Bus Service for Corporate Events in Pune for Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Busserviceforcorporate;