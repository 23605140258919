
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetoshirditempo() {



  const cardData =
  {
    keyword: 'Pune to Shirdi Tempo Traveller',
    heading: 'Pune to Shirdi Tempo Traveller: Shraddha Travels',
    headingDescription: 'When it comes to planning a spiritual journey to Shirdi, comfort and convenience are paramount. Shraddha Travels offers top-notch Pune to Shirdi Tempo Traveller services designed for a smooth and enjoyable travel experience. Our fleet of well-maintained Tempo Travellers provides ample space, comfortable seating, and air conditioning, making your trip not just a journey but a delightful experience.',
    top: 'Top Places to Visit in Shirdi with Shraddha Travels',

    topPlaces: [
        {
            title: "1. Sai Baba Samadhi Mandir",
            description: "The centerpiece of Shirdi, the Sai Baba Samadhi Mandir is a beautiful temple where devotees come to pay their respects. The temple features a stunning idol of Sai Baba and is adorned with flowers and offerings. The atmosphere is serene, especially during the evening aarti (prayer ceremony), drawing large crowds of devotees."
        },
        {
            title: "2. Dwarkamai Masjid",
            description: "This mosque was the place where Sai Baba spent most of his life. It holds deep spiritual significance as it symbolizes Baba's teachings. Inside, visitors can find the dhuni (sacred fire) that Baba maintained throughout his life, along with an impressive collection of sacred items that belong to him."
        },
        {
            title: "3. Chavadi",
            description: "Chavadi is a significant place where Sai Baba would rest every alternate night. This small structure houses a beautiful wooden bed and other artifacts associated with Baba. The peaceful ambiance here allows visitors to reflect and meditate."
        },
        {
            title: "4. Shri Saibaba Sansthan Trust",
            description: "This organization oversees the administration of the Sai Baba temple complex. The trust manages the facilities for devotees, including accommodation, meals, and other services. It plays a vital role in organizing events and festivals related to Sai Baba."
        },
        {
            title: "5. Lendi Garden",
            description: "A picturesque garden filled with lush greenery and vibrant flowers, Lendi Garden offers a tranquil setting for relaxation and reflection. It features benches and walking paths, making it a popular spot for pilgrims to unwind and connect with nature."
        },
        {
            title: "6. Nandankanan Garden",
            description: "This family-friendly garden is ideal for outings and picnics. It has well-maintained walking trails, a lake, and play areas for children. The serene environment encourages visitors to enjoy the natural beauty and take a break from the busy pilgrimage atmosphere."
        },
        {
            title: "7. Gurusthan",
            description: "This sacred site is believed to be where Sai Baba first manifested himself in Shirdi. It features a neem tree and a small shrine, and is often visited for prayer and meditation. The site holds a special place in the hearts of Baba’s devotees."
        },
        {
            title: "8. Khandoba Mandir",
            description: "Situated on a hilltop, this ancient temple is dedicated to Lord Khandoba, a local deity worshipped by Sai Baba. The temple offers breathtaking views of the surrounding area and is a great spot for spiritual reflection."
        },
        {
            title: "9. Shri Saibaba Museum",
            description: "Located near the main temple, the museum showcases a rich collection of artifacts, photographs, and personal belongings of Sai Baba. It provides an insightful look into his life, teachings, and the history of Shirdi, making it a must-visit for those wanting to learn more about the saint."
        },
        {
            title: "10. Akkalkot Swami Samarth Mandir",
            description: "A short distance from Shirdi, this temple is dedicated to Swami Samarth, another revered saint. Many devotees visit this temple after their pilgrimage to Shirdi, seeking blessings and spiritual guidance from Swami Samarth."
        }
    ],    
    "services": [
      {
        "name": "Pune to Shirdi Tempo Traveller on Rent",
        "description": "Looking for a comfortable Pune to Shirdi Tempo Traveller on Rent? Shraddha Travels offers a range of well-maintained Tempo Travellers perfect for your spiritual journey to Shirdi. Enjoy a hassle-free ride with ample luggage space and comfortable seating."
      },
      {
        "name": "Pune Airport to Shirdi Tempo Traveller on Rent",
        "description": "If you're flying into Pune, our Pune Airport to Shirdi Tempo Traveller on Rent service ensures easy pickup and drop-off. Start your pilgrimage smoothly and conveniently without worrying about transportation."
      },
      {
        "name": "Pune to Shirdi Tempo Traveller AC 13- & 17-Seater",
        "description": "Choose between our Pune to Shirdi Tempo Traveller AC 13- & 17-Seater options to suit your group size. Whether you’re traveling with family or friends, our air-conditioned vehicles guarantee a comfortable ride."
      },
      {
        "name": "Pune to Shirdi Mini Bus Rent",
        "description": "For larger groups, we provide Pune to Shirdi Mini Bus Rent services. Enjoy spacious seating and facilities to make your journey enjoyable and stress-free."
      },
      {
        "name": "Bus Hire for Pune to Shirdi",
        "description": "Our Bus Hire for Pune to Shirdi service caters to families and groups. Enjoy the convenience of traveling together while experiencing the divine ambiance of Shirdi."
      },
      {
        "name": "Pune Airport to Shirdi Tempo Traveller",
        "description": "Utilize our Pune Airport to Shirdi Tempo Traveller services for an easy transition from your flight to the temple. We ensure timely arrivals and comfortable rides."
      },
      {
        "name": "Pune to Shani Shingnapur Tempo Traveller",
        "description": "In addition to Shirdi, consider our Pune to Shani Shingnapur Tempo Traveller service. Visit both spiritual sites in one trip with ease."
      },
      {
        "name": "Pune to Shirdi 14-Seater Bus on Rent",
        "description": "Travel in comfort with our Pune to Shirdi 14-Seater Bus on Rent option. Ideal for small groups wanting a private travel experience."
      },
      {
        "name": "Pune to Shirdi-17-Seater on Rent",
        "description": "For slightly larger groups, our Pune to Shirdi 17-Seater on Rent is perfect. Enjoy a spacious interior, air conditioning, and comfortable seating throughout your journey."
      },
      {
        "name": "Pune to Ahmednagar Tempo Traveller on Rent",
        "description": "Explore nearby attractions with our Pune to Ahmednagar Tempo Traveller on Rent services. Visit historical sites and temples while enjoying comfortable travel."
      },
      {
        "name": "Pune to Ajanta Ellora Tempo Traveller on Rent",
        "description": "Experience the beauty of the Ajanta and Ellora caves with our Pune to Ajanta Ellora Tempo Traveller on Rent service. Enjoy a convenient travel experience to these UNESCO World Heritage sites."
      },
      {
        "name": "Pune to Shirdi Tempo Traveller Package",
        "description": "Consider our Pune to Shirdi Tempo Traveller Package for an all-inclusive journey. Packages typically include round-trip transportation and itinerary planning."
      },
      {
        "name": "Pune to Shani Shingnapur Tempo Traveller on Rent",
        "description": "Plan a spiritual journey with our Pune to Shani Shingnapur Tempo Traveller on Rent service, allowing for seamless visits to multiple destinations."
      },
      {
        "name": "Pune to Shirdi Tempo Traveller Contact Number",
        "description": "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Pune to Shirdi Tempo Traveller. We ensure a smooth and enjoyable ride for all our customers."
      }
    ],      
      tableData: [
        ['- Pune to Shirdi Tempo Traveller on Rent', '- Pune Airport to Shirdi Tempo Traveller on Rent'],
        ['- Pune to Shirdi Tempo Traveller AC 13 & 17 Seater', '- Pune to Shirdi Mini Bus Rent'],
        ['- Bus Hire for Pune to Shirdi', '- Pune Airport to Shirdi Tempo Traveller'],
        ['- Pune to Shani Shingnapur Tempo Traveller', '- Pune to Shani Shingnapur Bus on Rent'],
        ['- Pune to Shirdi 14 Seater Bus on Rent', '- Pune to Shirdi 17 Seater on Rent'],
        ['- Pune to Ahmednagar Tempo Traveller on Rent', '- Pune to Ajanta Ellora Tempo Traveller on Rent'],
        ['- 17 Seater Tempo Traveller on Rent in Pune', '- Pune to Shirdi Tempo Traveller Package'],
        ['- Pune to Shani Shingnapur Tempo Traveller On Rent', '']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Your Pune to Shirdi Tempo Traveller Journey?",
            WhyChoosedescription: "When planning your journey from Pune to Shirdi, Shraddha Travels stands out as your trusted partner for a seamless travel experience. We prioritize your comfort and convenience, ensuring that every aspect of your trip is taken care of. With our extensive experience in providing quality transport services, we guarantee a journey that meets your expectations."
        },
        {
            WhyChooseheading: "Comfortable Travel Experience:",
            WhyChoosedescription: "Our tempo travellers are designed with spacious interiors and comfortable seating, ensuring a pleasant ride for you and your group. With ample legroom and well-cushioned seats, you can relax and enjoy the journey without feeling cramped."
        },
        {
            WhyChooseheading: "Affordable Rates:",
            WhyChoosedescription: "We believe that quality travel shouldn't break the bank. Our competitive pricing structure is designed to provide you with the best value for your money without compromising on the quality of service. Enjoy an economical travel experience without hidden charges."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedescription: "Safety is our top priority. Our professional drivers are not only well-trained but also familiar with the Pune to Shirdi route. They prioritize your safety and comfort, ensuring a smooth and efficient journey. Their expertise allows them to navigate effectively, avoiding traffic delays."
        },
        {
            WhyChooseheading: "Punctual Service:",
            WhyChoosedescription: "We understand that timely arrivals are crucial for your travel plans. Our team ensures that your vehicle arrives on schedule, allowing you to adhere to your itinerary. You can count on us for reliable and timely service."
        },
        {
            WhyChooseheading: "Customizable Itineraries:",
            WhyChoosedescription: "Your journey should reflect your preferences. We offer flexible packages that can be tailored to fit your specific needs, whether you wish to make additional stops for sightseeing or have a planned itinerary. Our team works with you to create the perfect travel experience."
        },
        {
            WhyChooseheading: "Safety First:",
            WhyChoosedescription: "Our tempo travellers undergo regular maintenance and thorough safety checks, ensuring a reliable and secure journey for all passengers. We adhere to the highest safety standards, so you can travel with peace of mind."
        },
        {
            WhyChooseheading: "Entertainment Options:",
            WhyChoosedescription: "Long trips can be more enjoyable with onboard entertainment options. We provide various amenities to keep you and your group entertained, ensuring that your travel experience is as enjoyable as the destination itself."
        },
        {
            WhyChooseheading: "24/7 Customer Support:",
            WhyChoosedescription: "Our dedicated support team is available around the clock to assist with any inquiries or changes to your booking. Whether you have questions before your trip or need assistance during your journey, we’re here for you, providing peace of mind throughout your trip."
        },
        {
            WhyChooseheading: "Positive Customer Feedback:",
            WhyChoosedescription: "Our commitment to excellent service has earned us numerous positive reviews from satisfied customers. Many of our clients return to us for their travel needs, highlighting our reliability and quality. We take pride in our reputation and continuously strive to exceed expectations."
        },
        {
            WhyChooseheading: "Convenient Booking Process:",
            WhyChoosedescription: "Reserving your tempo traveller is quick and hassle-free with our user-friendly online booking system. Just a few clicks are all it takes to secure your booking, allowing you to focus on planning the rest of your trip."
        }
    ]
    
  }



  const faqData = [
    {
        question: "What is the seating capacity of the tempo traveller?",
        answer: "Our tempo travellers comfortably accommodate up to 12-15 passengers, making them ideal for family trips, corporate outings, or group travel."
    },
    {
        question: "Are there any additional charges?",
        answer: "We believe in transparency. Our pricing includes all standard charges, and any additional fees (like extra mileage or tolls) will be communicated clearly before booking."
    },
    {
        question: "Can we customize our itinerary?",
        answer: "Yes! We offer flexible itineraries that can be tailored to fit your travel plans, including stops for sightseeing or meals along the way."
    },
    {
        question: "Are the drivers experienced?",
        answer: "Absolutely! Our drivers are professional and experienced, ensuring a safe and smooth journey from Pune to Shirdi."
    },
    {
        question: "What amenities are included in the tempo traveller?",
        answer: "Our vehicles come equipped with comfortable seating, air conditioning, and often entertainment options to enhance your travel experience."
    },
    {
        question: "How do I book a tempo traveller?",
        answer: "You can easily book online through our website or by contacting our customer support team for assistance."
    },
    {
        question: "What is your cancellation policy?",
        answer: "We offer a flexible cancellation policy. Please refer to our website or contact us for specific details regarding cancellations and refunds."
    },
    {
        question: "Is the tempo traveller well-maintained?",
        answer: "Yes, all our vehicles undergo regular maintenance checks to ensure they are in top condition for your journey."
    },
    {
        question: "Can we take the tempo traveller for sightseeing in Shirdi?",
        answer: "Yes, you can use the tempo traveller for local sightseeing in Shirdi after your arrival. Just let us know your itinerary, and we can assist you."
    },
    {
        question: "What should we do in case of an emergency during the trip?",
        answer: "Our drivers are trained to handle emergencies, and you can reach out to our support team 24/7 for immediate assistance."
    }
];


const testimonials = [
    {
        name: "Ms. Akanksha Nalawade",
        text: "I recently traveled to Shirdi with Shraddha Travels, and I couldn’t have asked for a better experience. The Tempo Traveller was spacious and comfortable, and our driver was very professional. Highly recommend their services!"
    },
    {
        name: "Ms. Nilam Jadhav",
        text: "Shraddha Travels made our pilgrimage to Shirdi enjoyable. The ride was smooth, and we reached our destination on time. The team was very accommodating and helpful. I would definitely use them again!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Pune to Shirdi Tempo Traveller Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Pune to Shirdi Tempo Traveller | Shraddha Travels | Affordable Travel | AC 13 & 17 Seater</title>
    <meta name="description" content="Rent a comfortable Tempo Traveller from Pune to Shirdi. Options for AC 13 & 17 Seater available at competitive rates for a pleasant journey!" />
    <meta name="keywords" content="Pune to Shirdi Tempo Traveller, Pune Shirdi AC Tempo Traveller, Pune to Shirdi 13 Seater, Pune to Shirdi 17 Seater, Tempo Traveller Hire Pune to Shirdi, Rent Tempo Traveller Pune to Shirdi, Affordable Tempo Traveller Pune to Shirdi" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Pune to Shirdi Tempo Traveller | Shraddha Travels | Affordable Travel | AC 13 & 17 Seater" />
    <meta property="og:description" content="Book a comfortable Tempo Traveller from Pune to Shirdi. AC options available for a smooth and enjoyable journey!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-to-shirdi-tempo-traveller" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/pune-to-shirdi-tempo-traveller.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pune to Shirdi Tempo Traveller Rental",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-to-shirdi-tempo-traveller",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller",
                    "description": "Comfortable AC Tempo Traveller from Pune to Shirdi for a relaxed and safe journey."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "8000"  // Replace with actual rating count
            }
        }
        `} 
    </script>
</Helmet>


      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-07.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs Pune to Shirdi Tempo Traveller Rental for Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./" className='px-3'>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <h1 className='text-center darkcolor'>{cardData.keyword + " Rates"}</h1>
            <BusRatesTable />
            </div>
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetoshirditempo;